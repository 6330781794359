import React, { useContext, useState, useEffect } from 'react'
import queryString from 'query-string';
import { logger } from 'services/CloudLogger'
import {
    APPLICATION_READY_TIMEOUT_MILISECONDS,
    BILLING_PLANS,
    CART_PAGE_CART_BUTTON_IMAGE_KEYS,
    CART_PAGE_CART_BUTTON_IMAGE_OPTIONS,
    CART_PAGE_CHECKOUT_BUTTON_SHAPE_KEYS,
    CART_PAGE_CHECKOUT_BUTTON_SHAPE_OPTIONS,
    DEFAULT_COUNTRY_CODE,
    DEFAULT_CURRENCY_CODE,
    DEFAULT_LANGUAGE_CODE,
    GOOGLE_ANALYTICS_EVENT,
    MAX_ITEMS_IN_CART,
    META_PIXEL_EVENTS,
    OPTION_TYPES,
    PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS,
    PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS,
    SESSION_START_TIME_MS,
    SHOPBOARD_POST_LIST_API_COUNT
} from 'constants/general.constants'
import {
    CART_ID_KEY_LOCAL_STORAGE,
    AUTH_TOKEN_KEY_LOCAL_STORAGE,
    COUNTRY_CODE_LOCAL_STORAGE_KEY,
    LANG_KEY
} from 'constants/storage.constant'
import {
    StartShoppingAPI,
    fetchSocialPostProducts,
    fetchSocialPostSections,
    fetchSocialPosts,
    notifyProductViewAPI,
    notifySessionStartAPI
} from 'api/shopping/shopping'
import { LocalSharedPreferences } from 'services/SharedPreferences'
import { AddItemAPI, RemoveItemAPI } from 'api/cart/cart'
import i18next from 'i18next'
import { getPathParamsList } from 'services/SystemService'
import { getGeolocationData } from 'services/GeolocationDetector'
import {
    LocalStorageGetItem,
    LocalStorageSetItem
} from 'services/LocalStorage'
import {
    getLinkSections,
    getProductPageSections,
    getRelatedProducts
} from 'api/shop/shop'
import {
    extractCollectionData,
    extractLookData,
    extractPostData,
    extractProductData,
    extractSectionData
} from 'utils/dataUtils'
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import { getRouteParam, hashCode, parseAsIntegerElseString } from 'utils/utils'
import { getOptionsMap } from 'api/utils/optionsMap'
import stc from 'string-to-color'

export function getUsername() {
    return window.username
}

export function getCartID() {
    return LocalSharedPreferences.get_key(CART_ID_KEY_LOCAL_STORAGE);
}

const initialState = {
    isVerified: false,
    isReady: false,
    linkDetails: {
        linkId: "",
        images: [],
        promocode: {
            code: '',
            description: ''
        },
        description: '',
        products: [],
        relatedProducts: [],
        sections: [],
        utmParams: {},
    },
    cartDetails: {
        cartId: "",
        cartItems: [],
        totalAmount: 0,
        totalItems: 0,
    },
    businessProperties: {
        domain: '',
        businessName: '',
        logoUrl: '',
        currency: 'USD',
        isBusinessValid: false,
        language: "en",
        theme: {
            checkoutButtonBackgroundColor: 'black',
            checkoutButtonFontColor: 'white',
            checkoutButtonBorderColor: null,
            checkoutButtonBorderRadius: CART_PAGE_CHECKOUT_BUTTON_SHAPE_OPTIONS.find(option => option?.key === CART_PAGE_CHECKOUT_BUTTON_SHAPE_KEYS.OPTION1).value,
            cartButtonIcon: CART_PAGE_CART_BUTTON_IMAGE_OPTIONS.find(option => option?.key === CART_PAGE_CART_BUTTON_IMAGE_KEYS.OPTION1).value,
            isDiscountStripShown: true,
            discountBackgroundColor: 'black',
            discountFontColor: 'white',
            isSalesAndDiscountStripShown: false,
            salesAndDiscountStripBackgroundColor: 'black',
            salesAndDiscountStripFontColor: 'white',
            salesAndDiscountStripSentence1: '',
            salesAndDiscountStripSentence2: '',
            imageBorderRadius: PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS.find(option => option?.key === PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS.OPTION1).value,
            mainLinkItemsImageRatio: null,
            useFeminineLanguage: false,
            discountTagBackgroungColor: '#313131',
            discountTagFontColor: '#f2f2f2',
            addToCartButtonPlacedBottom: true,
            isPreorderTextShown: false,
            preorderText: '',
            productDiscountTagBorderRadius: 0,
            productDiscountTagBorderColor: 'firebrick',
            productDiscountTagBackgroundColor: 'white',
            productDiscountTagFontColor: 'firebrick',
            productDiscountFinalPriceFontColor: "firebrick",
        },
        optionsMap: {}
    },
    related: {
        data: [],
        products: [],
        collections: [],
        looks: []
    },
    sections: {
        data: [],
        products: [],
        collections: [],
        looks: []
    },
    shopping: {
        products: [],
        productsReplenishments: [],
        linkLookId: '',
        isProductAdded: false,
        loadedLooks: {},
        loadedCollections: {},
        isCollectionProductAdded: false,
        lastLinkLookProducts: [],
    },
    productSections: {
        loadedProductsSections: {},
        lastProductSectionsData: {},
    },
    shopboard: {
        currentSocial: "instagram",
        socialsPosts: {
            instagram: [],
            tiktok: [],
            pinterest: []
        },
        loadedPostProducts: {},
        loadedPostSections: {},
        lastPostProducts: [],
        lastPostSelectedOptions: {},
        lastPostSections: [],
        lastPostSectionsData: {},
    },
    googleAnalyticsId: null,
    metaPixelId: null,
    loadedCollections: {},
}

// State Data
const isVerifiedContext = React.createContext()
const isReadyContext = React.createContext()
const businessPropertiesContext = React.createContext()
const linkDetailsContext = React.createContext()
const cartDetailsContext = React.createContext()
const generalContext = React.createContext()

// Update function to state
const addItemContext = React.createContext();
const removeItemContext = React.createContext();


/**
 * Can only be used inside of a functional component.
 * Return "isVerified" - a contex variable indicating if the user is verified.
 */
export function useIsVerified() {
    return useContext(isVerifiedContext);
}

/**
 * Can only be used inside of a functional component.
 * Return "isReady" - a contex variable indicating if the app is ready to start.
 */
export function useIsReady() {
    return useContext(isReadyContext);
}

/**
 * Can only be used inside of a functional component.
 * Return "businessProperties" - a contex object holding all of the store properties:
 *      brandName - default value is getQueryVariable("bid")
 *      logoUrl: default value is ''
 *      logoColor: default value is 'white'
 *      currency: default value is 'ILS'
 *      isBusinessValid: default value is false
 *      defaultCountryCode: "US",
 *      language: "en",
*/
export function useBusinessPropertiesContext() {
    return useContext(businessPropertiesContext);
}

/**
 * Can only be used inside of a functional component.
 * Return "linkDetails" - a contex object holding all of the cart current details:
 *      images: default value is []
 *      inventory_ids: default value is []
 *      promocode: default value is null
 *      promocode_description: default value is ""
*/
export function useLinkDetailsContext() {
    return useContext(linkDetailsContext);
}


/**
 * Can only be used inside of a functional component.
 * Return "cartDetails" - a contex object holding all of the cart current details:
 *      cartId: default value is ''
 *      cartItems: default value is []
 *      totalAmount: default value is 0
 *      totalItems: default value is 0
*/
export function useCartDetailsContext() {
    return useContext(cartDetailsContext);
}


/**
 * Can only be used inside of a functional component.
 * Return "addItem" - a contex function, when called with a new item code and "onSuccess" function, add the item to the cart and calles "onSuccess" on success.
 */
export function useAddItemContext() {
    return useContext(addItemContext);
}

/**
 * Can only be used inside of a functional component.
 * Return "removeItem" - a contex function, when called with a new item code, removes the item.
 */
export function useRemoveItemContext() {
    return useContext(removeItemContext);
}

export function useGeneralContext() {
    return useContext(generalContext)
}

/**
 * Providing an authentication context to all children components.
 */
export default function ShoppingProvider({ children, onVerified, onReady, onRecovery }) {
    const [isVerified, setIsVerified] = useState(initialState.isVerified) // allow to shop for guest and user
    const [isReady, setIsReady] = useState(initialState.isReady) // allow to shop for guest and user
    const [businessProperties, setBusinessProperties] = useState(initialState.businessProperties)
    const [cartDetails, setCartDetails] = useState(initialState.cartDetails)
    const [linkDetails, setLinkDetails] = useState(initialState.linkDetails)
    const [relatedData, setRelatedData] = useState(initialState.related)
    const [sectionsData, setSectionsData] = useState(initialState.sections)
    const [shoppingData, setShoppingData] = useState(initialState.shopping)
    const [shopboardData, setShopboardData] = useState(initialState.shopboard)
    const [productsSectionsData, setProductsSectionsData] = useState(initialState.productSections)
    const [forwardQueries, setForwardQueries] = useState({})

    useEffect(() => {
        loadShoppingData()
    }, [])

    useEffect(() => {
        const queryStringParams = queryString.parse(window.location.search)
        if (Object.keys(queryStringParams).length > 0) {
            setForwardQueries(queryStringParams)
        }
    }, [])

    function getLinkId() {
        const pathParams = getPathParamsList()

        let linkIdPathLocation = 2
        if (window.basePath) {
            linkIdPathLocation = 5
        }
    
        var link_id = pathParams.length > linkIdPathLocation ? pathParams[linkIdPathLocation] : null
        if (!link_id)
            return linkDetails?.linkId
    
        return link_id
    }

    function hasLinkId() {
        return linkDetails?.linkId && linkDetails?.linkId !== 'shopboard'
    }

    function fetchProductSections(productId, onSuccess = () => { }, onFailure = () => { }) {
        getProductPageSections(getUsername(), getLinkId(), productId)
            .then(data => {
                const sections = data?.sections.map(section => extractSectionData(section))
                const products = data?.products.map(product => extractProductData(product, cartDetails?.currency))
                const collections = data?.collections?.map(collection => extractCollectionData(collection))
                setProductsSectionsData(prev => ({
                    ...prev,
                    loadedProductsSections: {
                        ...prev.loadedProductsSections,
                        [productId]: {
                            sections,
                            products,
                            collections,
                        }
                    },
                    lastProductSectionsData: {
                        sections,
                        products,
                        collections,
                    }
                }))
                onSuccess(sections)
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    function fetchPostProducts(social, postId, onSuccess = () => { }, onSections = () => {}, onFailure = () => { }) {
        fetchSocialPostProducts(social, postId)
            .then(data => {
                const postProducts = data?.products?.map(product => extractProductData(product, cartDetails?.currency))
                const postSelectedOptions = data?.selected_options ?? {}
                savePostProducts(social, postId, postProducts, postSelectedOptions)
                fetchSocialPostSections(social, postId).then(sectionsData => {
                    const sections = sectionsData?.sections.map(section => extractSectionData(section))
                    const sectionsProducts = sectionsData?.products.map(product => extractProductData(product, cartDetails?.currency))
                    const sectionsCollections = sectionsData?.collections?.map(collection => extractCollectionData(collection))
                    savePostSections(social, postId, sectionsProducts, sectionsCollections, sections)
                    onSections(sections)
                })
                onSuccess(postProducts, postSelectedOptions)
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    function savePostProducts(social, postId, products, selectedOptions) {
        setShopboardData(prev => ({
            ...prev,
            loadedPostProducts: {
                ...prev.loadedPostProducts,
                [postId]: {
                    data: products,
                    itemsCount: products?.length,
                    selectedOptions: selectedOptions,
                }
            },
            lastPostId: postId,
            lastPostSocial: social,
            lastPostProducts: products,
            lastPostSelectedOptions: selectedOptions
        }))
    }


    
    function savePostSections(social, postId, products, collections, sections) {
        setShopboardData(prev => ({
            ...prev,
            loadedPostSections: {
                ...prev.loadedPostSections,
                [postId]: {
                    sections,
                    products,
                    collections,
                }
            },
            lastPostSectionsData: {
                sections,
                products,
                collections,
            }
        }))
    }

    function fetchPosts(social, skip = 0, shouldOverridePosts = true, onSuccess = () => { }, onFailure = () => { }) {
        fetchSocialPosts(social, SHOPBOARD_POST_LIST_API_COUNT, skip)
            .then(posts => {
                const newPosts = posts ? posts.map(post => extractPostData(post)) : []
                setShopboardData(prev => ({
                    ...prev,
                    socialsPosts: {
                        ...prev.socialsPosts,
                        [social]: posts ? (shouldOverridePosts ? newPosts : [...prev.socialsPosts[social], ...newPosts]) : []
                    },
                }))
                onSuccess(posts)
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    function setCurrentSocial(social) {
        setShopboardData(prev => ({
            ...prev,
            currentSocial: social,
        }))
    }

    function startSession(username, metaPixelId, googleAnalyticsId, linkId, cartId) {
        if (googleAnalyticsId) {
            ReactGA.initialize(googleAnalyticsId)
        }
        if (metaPixelId) {
            ReactPixel.init(metaPixelId, {}, {
                autoConfig: true,
                debug: false,
            });
            ReactPixel.pageView()
        }
        setTimeout(() => {
            notifySessionStartAPI(username, linkId, cartId)
        }, SESSION_START_TIME_MS)
        // No need for these, I think it is automatically done on initialization
        // ReactGA.send({ hitType: "pageview", page: `/${getUsername()}`, title: "Visit" });
    }

    function notifyProductView(linkId, inventoryId, title, price) {
        notifyProductViewAPI(getUsername(), inventoryId, linkId)
        if (businessProperties?.metaPixelId) {
            // We would think that the content_type would be 'product' but we saw on pixel we connected to our shopify that it is always 'product_group' for when viewing a product.
            ReactPixel.track(META_PIXEL_EVENTS.VIEW_CONTENT, {
                content_name: title, 
                content_type: 'product_group', 
                content_ids: [parseAsIntegerElseString(inventoryId)], 
                currency: cartDetails.currency, 
                value: price
            });
        }
        if (businessProperties?.googleAnalyticsId) {
            ReactGA.event({category: GOOGLE_ANALYTICS_EVENT.PRODUCT_VIEW.CATEGORY, action: GOOGLE_ANALYTICS_EVENT.PRODUCT_VIEW.ACTION, label: title})
        }
    }

    function notifyProductAdd(inventoryId, title, price, quantity) {
        if (businessProperties?.metaPixelId) {
            ReactPixel.track(META_PIXEL_EVENTS.ADD_TO_CART, {
                content_name: title, 
                content_type: 'product_group', 
                content_ids: [parseAsIntegerElseString(inventoryId)], 
                currency: cartDetails.currency, 
                value: price, 
                num_items: quantity
            });
        }
        if (businessProperties?.googleAnalyticsId) {
            ReactGA.event({category: GOOGLE_ANALYTICS_EVENT.ADD_TO_CART.CATEGORY, action: GOOGLE_ANALYTICS_EVENT.ADD_TO_CART.ACTION, label: title})
        }
    }
    
    function notifyCheckoutRequest() {
        if (businessProperties?.metaPixelId) {
            ReactPixel.track(META_PIXEL_EVENTS.INITIATE_CHECKOUT, {
                content_type: 'product_group', 
                content_ids: cartDetails?.cartItems.map(cartItem => parseAsIntegerElseString(cartItem?.product_inventory_id)), 
                currency: cartDetails?.currency, 
                value: cartDetails?.totalAmount, 
                num_items: cartDetails?.totalItems
            });
        }
        if (businessProperties?.googleAnalyticsId) {
            ReactGA.event({category: GOOGLE_ANALYTICS_EVENT.INITIATE_CHECKOUT.CATEGORY, action: GOOGLE_ANALYTICS_EVENT.INITIATE_CHECKOUT.ACTION, label: GOOGLE_ANALYTICS_EVENT.INITIATE_CHECKOUT.LABEL})
        }
    }

    function setCartDetailsState(cartId, cartItems, totalAmount, totalItems, currency) {
        setCartDetails(prevCartProperties => {
            return {
                ...prevCartProperties,
                cartId,
                cartItems,
                totalAmount,
                totalItems,
                currency
            }
        });
    }

    function findProductInContext(inventoryId) {
        let foundProduct = shoppingData?.products?.find(product => product?.inventoryId === inventoryId)
        if (!foundProduct) {
            foundProduct = relatedData?.products?.find(product => product?.inventoryId === inventoryId)
        }
        if (!foundProduct) {
            foundProduct = sectionsData?.products?.find(product => product?.inventoryId === inventoryId)
        }
        if (!foundProduct) {
            foundProduct = shoppingData?.lastLinkLookProducts?.find(product => product?.inventoryId === inventoryId)
        }
        if (!foundProduct) {
            foundProduct = shopboardData?.lastPostProducts?.find(product => product?.inventoryId === inventoryId)
        }
        if (!foundProduct) {
            foundProduct = shopboardData?.lastPostSectionsData?.products?.find(product => product?.inventoryId === inventoryId)
        }
        if (!foundProduct) {
            foundProduct = productsSectionsData?.lastProductSectionsData?.products?.find(product => product?.inventoryId === inventoryId)
        }
        return foundProduct
    }

    function findSectionCollectionById(collectionId) {
        let foundCollection = null
        foundCollection = sectionsData?.collections?.find(collection => collection?.collectionId === collectionId)
        if (!foundCollection) { 
            foundCollection = shopboardData?.lastPostSectionsData?.collections?.find(collection => collection?.collectionId === collectionId)
        }
        if (!foundCollection) { 
            foundCollection = productsSectionsData?.lastProductSectionsData?.collections?.find(collection => collection?.collectionId === collectionId)
        }

        return foundCollection
    }

    function findSectionLooksById(lookId) {
        let foundLook = null
        foundLook = sectionsData?.looks?.find(look => look?.linkId === lookId)

        return foundLook
    }

    function addItem(inventoryId, variantId, quantity, title, onItemAdded) {
        logger.log("Add item start");
        if (isVerified) {
            if (cartDetails.totalItems >= MAX_ITEMS_IN_CART) {
                onItemAdded(false)
                return
            }
            AddItemAPI(getUsername(), getLinkId(), variantId, quantity)
                .then((cartData) => {
                    logger.log("Add item done")
                    setCartDetailsState(cartData.cartId, cartData.cartItems, cartData.totalAmount, cartData.totalItems, cartData.currency)
                    onItemAdded(true, "Item added")
                    notifyProductAdd(inventoryId, title, cartData?.cartItems.find(cartItem => cartItem?.product_inventory_id === inventoryId)?.price, quantity)
                })
                .catch(error => {
                    logger.error(`Error - addItem ${getCartID()} ${variantId} - ${error}`)
                    onItemAdded(false, error)
                });
        }
    }

    function removeItem(variant_id, quantity, onItemRemoved) {
        logger.log("Remove item start")
        if (isVerified) {
            RemoveItemAPI(getUsername(), getLinkId(), variant_id, quantity)
                .then((cartData) => {
                    logger.log("Remove item done")
                    setCartDetailsState(cartData.cartId, cartData.cartItems, cartData.totalAmount, cartData.totalItems, cartData.currency)
                    onItemRemoved(true, "")
                })
                .catch(error => {
                    logger.error(`Error - removeItem ${getCartID()} ${variant_id}- ${error}`)
                    onItemRemoved(false, error)
                });
        }
    }

    function isPro() {
        return businessProperties?.billingPlan === BILLING_PLANS.PRO
    }

    function hasOptionsMap() {
        return Object.keys(businessProperties?.optionsMap).length > 0
    }

    function getOptionType(optionName) {
        if (!hasOptionsMap() || !businessProperties?.optionsMap[optionName]) {
            return OPTION_TYPES.TEXT
        }
        return businessProperties?.optionsMap[optionName]?.type
    }

    function isOptionColor(optionName) {
        return getOptionType(optionName) === OPTION_TYPES.COLOR
    }


    function hasHexColor(optionName, optionValueName) {
        // if there's no options map json or the requested option doesn't exist or the requested option value doesn't exist
        if (!hasOptionsMap() || !businessProperties?.optionsMap[optionName] || !businessProperties?.optionsMap[optionName]?.values[optionValueName]) {
            return null
        }

        const optionValueObject = businessProperties?.optionsMap[optionName]?.values[optionValueName]
        if (isOptionColor(optionName) && optionValueObject?.hex_value) {
            return true
        }

        return false
    }

    function getOptionValue(optionName, optionValueName, shouldReturnTextValue = false) {
        // if there's no options map json or the requested option doesn't exist or the requested option value doesn't exist
        if (!hasOptionsMap() || !businessProperties?.optionsMap[optionName] || !businessProperties?.optionsMap[optionName]?.values[optionValueName]) {
            return null
        }

        const optionValueObject = businessProperties?.optionsMap[optionName]?.values[optionValueName]
        if (!shouldReturnTextValue && isOptionColor(optionName)) {
            if (!optionValueObject?.hex_value) {
                const noWhiteSpaceColor = optionValueObject?.text_value?.toLowerCase().replaceAll(/\s/g, '')
                return stc(noWhiteSpaceColor)
            }

            return optionValueObject?.hex_value
        } else {
            if (!optionValueObject?.text_value) {
                return optionValueName
            }

            return optionValueObject?.text_value
        }
    }

    function getRequestedLanguage(countryCode, businessLanguage) {
        return businessLanguage ?? DEFAULT_LANGUAGE_CODE
    }

    function getCountryCode(countryCode) {
        return countryCode ?? DEFAULT_COUNTRY_CODE
    }

    function getCurrencyCode(currencyCode) {
        return currencyCode ?? DEFAULT_CURRENCY_CODE
    }

    function extractThemeValue(initialValue, optionsArray, inputValue) {
        if (![null, undefined].includes(inputValue)) {
            if (typeof inputValue === 'string') {
                return optionsArray.find(option => option?.key === inputValue).value ?? initialValue
            }

            if (typeof inputValue === 'number') {
                return optionsArray[inputValue].value ?? initialValue
            }
        }

        return initialValue
    }

    function getThemeObject(data) {
        return data?.business_properties?.theme ? {
            checkoutButtonBackgroundColor: data?.business_properties?.theme?.checkout_button_background_color ?? initialState.businessProperties.theme.checkoutButtonBackgroundColor,
            checkoutButtonFontColor: data?.business_properties?.theme?.checkout_button_font_color ?? initialState.businessProperties.theme.checkoutButtonFontColor,
            checkoutButtonBorderColor: data?.business_properties?.theme?.checkout_button_border_color ?? initialState.businessProperties.theme.checkoutButtonBorderColor,
            checkoutButtonBorderRadius: extractThemeValue(initialState.businessProperties.theme.checkoutButtonBorderRadius, CART_PAGE_CHECKOUT_BUTTON_SHAPE_OPTIONS, data?.business_properties?.theme?.checkout_button_shape),
            isDiscountStripShown: data?.business_properties?.theme?.is_discount_strip_shown ?? initialState.businessProperties.theme.discountBackgroundColor,
            discountBackgroundColor: data?.business_properties?.theme?.discount_background_color ?? initialState.businessProperties.theme.discountBackgroundColor,
            discountFontColor: data?.business_properties?.theme?.discount_font_color ?? initialState.businessProperties.theme.discountFontColor,
            cartButtonIcon: extractThemeValue(initialState.businessProperties.theme.cartButtonIcon, CART_PAGE_CART_BUTTON_IMAGE_OPTIONS, data?.business_properties?.theme?.cart_button_icon),
            isSalesAndDiscountStripShown: data?.business_properties?.theme?.is_shipping_and_sales_strip_shown ?? initialState.businessProperties.theme.isSalesAndDiscountStripShown,
            salesAndDiscountStripBackgroundColor: data?.business_properties?.theme?.shipping_and_sales_strip_background_color ?? initialState.businessProperties.theme.salesAndDiscountStripBackgroundColor,
            salesAndDiscountStripFontColor: data?.business_properties?.theme?.shipping_and_sales_strip_font_color ?? initialState.businessProperties.theme.salesAndDiscountStripFontColor,
            salesAndDiscountStripSentence1: data?.business_properties?.theme?.shipping_and_sales_strip_sentences?.length > 0 ? data?.business_properties?.theme?.shipping_and_sales_strip_sentences[0] : initialState.businessProperties.theme.salesAndDiscountStripSentence1,
            salesAndDiscountStripSentence2: data?.business_properties?.theme?.shipping_and_sales_strip_sentences?.length > 1 ? data?.business_properties?.theme?.shipping_and_sales_strip_sentences[1] : initialState.businessProperties.theme.salesAndDiscountStripSentence2,
            imageBorderRadius: extractThemeValue(initialState.businessProperties.theme.imageBorderRadius, PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS, data?.business_properties?.theme?.image_border_radius),
            mainLinkItemsImageRatio: data?.business_properties?.theme?.main_link_items_image_ratio ?? initialState.businessProperties.theme.mainLinkItemsImageRatio,
            useFeminineLanguage: data?.business_properties?.theme?.use_feminine_language ?? initialState.businessProperties.theme.useFeminineLanguage,
            discountTagBackgroungColor: data?.business_properties?.theme?.discount_tag_backgroung_color ?? initialState.businessProperties.theme.discountTagBackgroungColor,
            discountTagFontColor: data?.business_properties?.theme?.discount_tag_font_color ?? initialState.businessProperties.theme.discountTagFontColor,
            addToCartButtonPlacedBottom: data?.business_properties?.theme?.add_to_cart_button_placed_bottom ?? initialState.businessProperties.theme.addToCartButtonPlacedBottom,
            isPreorderTextShown: data?.business_properties?.theme?.is_preorder_text_shown ?? initialState.businessProperties.theme.isPreorderTextShown,
            preorderText: data?.business_properties?.theme?.preorder_text ?? initialState.businessProperties.theme.preorderText,
            productDiscountTagBorderRadius: data?.business_properties?.theme?.product_discount_tag_border_badius ?? initialState.businessProperties.theme.productDiscountTagBorderRadius,
            productDiscountTagBorderColor: data?.business_properties?.theme?.product_discount_Tag_border_color ?? initialState.businessProperties.theme.productDiscountTagBorderColor,
            productDiscountTagBackgroundColor: data?.business_properties?.theme?.product_discount_Tag_background_color ?? initialState.businessProperties.theme.productDiscountTagBackgroundColor,
            productDiscountTagFontColor: data?.business_properties?.theme?.product_discount_tag_font_color ?? initialState.businessProperties.theme.productDiscountTagFontColor,
            productDiscountFinalPriceFontColor: data?.business_properties?.theme?.product_discount_final_price_font_Color ?? initialState.businessProperties.theme.productDiscountFinalPriceFontColor,
        } : initialState.businessProperties.theme
    }

    function setLinkProducts(items) {
        setLinkDetails(prev => {
            const linkProductsSection = { ...prev.sections[0] }
            linkProductsSection.itemsIds = items.map(item => item?.inventoryId)
            return {
                ...prev,
                sections: [linkProductsSection]
            }
        })
    }

    async function loadShoppingData(callbackOnFinish = () => { }) {
        const linkId = getLinkId()
        const username = getUsername()

        if (!username && !linkId) {
            onRecovery(true)
            return
        }

        // moved the ready timeout to here for exposing the isReady value as context
        setTimeout(() => {
            onReady(true)
            setIsReady(true)
        }, APPLICATION_READY_TIMEOUT_MILISECONDS)

        const optionsMap = await getOptionsMap()
        setBusinessProperties(prev => ({
            ...prev,
            optionsMap
        }))
        const geo_location_data = await getGeolocationData()
        const countryCode = geo_location_data?.country_code
        const currencyCode = geo_location_data?.currency
        LocalStorageSetItem(COUNTRY_CODE_LOCAL_STORAGE_KEY, getCountryCode(countryCode))

        StartShoppingAPI(username, linkId, getCurrencyCode(currencyCode), getCountryCode(countryCode))
            .then(data => {
                LocalSharedPreferences.set_key(AUTH_TOKEN_KEY_LOCAL_STORAGE, data.token) //Update token in local
                LocalSharedPreferences.set_key(CART_ID_KEY_LOCAL_STORAGE, data?.cart_details?.cart_id) //Update cart_id in local

                if (linkId) {
                    setShoppingData(prev => ({
                        ...prev,
                        productsReplenishments: data?.products_replenishment ?? {}
                    }))
                    getRelatedProducts(username, linkId)
                        .then(response => {
                            setRelatedData(prev => ({
                                ...prev,
                                data: response?.sections ? response?.sections?.map(section => extractSectionData(section)) : [],
                                products: response?.products ? response?.products?.map(product => extractProductData(product, data?.cart_details?.currency)) : [],
                                collections: response?.collections ? response?.collections?.map(collection => extractCollectionData(collection)) : [],
                                looks: response?.looks ? response?.looks?.map(look => extractLookData(look)) : []
                            }))
                        }).catch(error => {
                            console.log(error)
                        })

                    getLinkSections(username, linkId)
                        .then(response => {
                            setSectionsData(prev => ({
                                ...prev,
                                data: response?.sections ? response?.sections?.map(section => extractSectionData(section)) : [],
                                products: response?.products ? response?.products?.map(product => extractProductData(product, data?.cart_details?.currency)) : [],
                                collections: response?.collections ? response?.collections?.map(collection => extractCollectionData(collection)) : [],
                                looks: response?.looks ? response?.looks?.map(look => extractLookData(look)) : []
                            }))
                        })
                        .catch(error => {
                            console.log(error)
                        })
                } else {
                    // shopboard
                    const socialPosts = data?.socialposts ? data?.socialposts.map(post => extractPostData(post)) : []
                    setShoppingData(prev => ({
                        ...prev,
                        shopboardSocialPosts: socialPosts
                    }))
                    setSectionsData(prev => ({
                        ...prev,
                        data: data?.sections ? data?.sections?.map(section => {
                            return {
                                ...extractSectionData(section),
                                itemsIds: {
                                    instagram: section?.items_ids?.instagram ?? false,
                                    tiktok: section?.items_ids?.tiktok ?? false,
                                    pinterest: section?.items_ids?.pinterest ?? false
                                }
                            }
                        }) : [],
                    }))
                }
                setIsVerified(true)

                if (LocalStorageGetItem(LANG_KEY)) {
                    i18next.changeLanguage(LocalStorageGetItem(LANG_KEY))
                } else if ('business_properties' in data) {
                    if (countryCode) {
                        const language = getRequestedLanguage(getCountryCode(countryCode), data.business_properties?.language)
                        i18next.changeLanguage(language)
                    } else {
                        i18next.changeLanguage(data.business_properties?.language ?? DEFAULT_LANGUAGE_CODE)
                    }
                }

                if ('business_properties' in data && 'link_details' in data && data && 'cart_details' in data && 'products' in data) {
                    setBusinessProperties(prev => {
                        return {
                            ...prev,
                            businessName: data?.business_properties?.business_name ?? '',
                            billingPlan: data?.business_properties?.billing_plan ?? '',
                            logoUrl: data?.business_properties?.logo_url ?? '',
                            isBusinessValid: true,
                            language: "en",
                            domain: data?.business_properties?.domain ?? '',
                            shippingAndReturnsUrl: data?.business_properties?.shipping_and_return_url ?? '',
                            theme: getThemeObject(data),
                            googleAnalyticsId: data?.business_properties?.google_analytics_id ?? '',
                            metaPixelId: data?.business_properties?.facebook_pixel_id ?? '',
                        }
                    })
                    if (data?.business_properties?.business_name) {
                        document.getElementById("main-title-name").innerHTML = data?.business_properties?.business_name
                    }

                    setLinkDetails(prev => {
                        return {
                            ...prev,
                            linkId: data?.link_details?.link_id ?? linkId,
                            images: data?.link_details?.images ?? [],
                            sections: data?.sections ? data?.sections.map(section => extractSectionData(section)) : [],
                            productInventoryIds: data?.link_details?.products_inventory_ids ?? [],
                            selectedOptions: data?.link_details?.selected_options ?? {},
                            promocode: {
                                code: data?.link_details?.promocode?.code ?? '',
                                description: data?.link_details?.promocode?.description ?? '',
                            },
                            description: data?.link_details?.description ?? '',
                            utmParams: data?.link_details?.utm_params ?? {},
                        }
                    })

                    setCartDetailsState(
                        data?.cart_details?.cart_id,
                        data?.cart_details?.cart_items,
                        data?.cart_details?.total_amount,
                        data?.cart_details?.total_items,
                        data?.cart_details?.currency
                    )

                    setShoppingData(prev => ({
                        ...prev,
                        products: data?.products?.map(product => extractProductData(product, data?.cart_details?.currency))
                    }))

                    // Add the pl_id to local storage for the case we use the app proxy and we are on the shops domain.
                    set_pl_id_item_in_local_storage(data?.cart_details?.cart_id, data?.business_properties?.domain)

                    startSession(username, data?.business_properties?.facebook_pixel_id, data?.business_properties?.google_analytics_id, data?.link_details?.link_id ?? linkId, data?.cart_details?.cart_id)
                    // Start Application
                    onVerified(true)
                    callbackOnFinish()
                }
                else {
                    setIsVerified(false)
                }
            }).catch((err) => {
                setIsVerified(false)
                logger.error(`Error - startShopping - ${err}`)
            })
    }

    function set_pl_id_item_in_local_storage(cart_id, domain) {
        var strful_hash = -1196101251

        let timestamp = Date.now()
        let expiration = timestamp
        if (hashCode(domain) === strful_hash) {
            expiration = timestamp + 1000*60*60*24 // 1 day in ms
        } else {
            expiration = timestamp + 1000*60*60*24*7 // 7 days in ms
        }

        LocalStorageSetItem('pl_id', JSON.stringify({
            initial: timestamp,
            expiresOn: expiration,
            value: cart_id
        }))
    }

    return (
        <isReadyContext.Provider value={isReady}>
            <isVerifiedContext.Provider value={isVerified}>
                <businessPropertiesContext.Provider value={{
                    businessProperties,
                    isOptionColor,
                    hasOptionsMap,
                    getOptionValue,
                    hasHexColor,
                    isPro,
                }}>
                    <linkDetailsContext.Provider value={{ linkDetails, setLinkDetails, setLinkProducts, getLinkId, hasLinkId }}>
                        <cartDetailsContext.Provider value={cartDetails}>
                            <addItemContext.Provider value={addItem}>
                                <removeItemContext.Provider value={removeItem}>
                                    <generalContext.Provider value={{
                                        shopboardData,
                                        productsSectionsData,
                                        setProductsSectionsData,
                                        setShopboardData,
                                        relatedData,
                                        setRelatedData,
                                        sectionsData,
                                        shoppingData,
                                        setShoppingData,
                                        findProductInContext,
                                        findSectionCollectionById,
                                        findSectionLooksById,
                                        fetchPosts,
                                        setCurrentSocial,
                                        savePostProducts,
                                        fetchPostProducts,
                                        notifyProductView,
                                        notifyCheckoutRequest,
                                        fetchProductSections,
                                        forwardQueries,
                                    }}>
                                        {children}
                                    </generalContext.Provider>
                                </removeItemContext.Provider>
                            </addItemContext.Provider>
                        </cartDetailsContext.Provider>
                    </linkDetailsContext.Provider>
                </businessPropertiesContext.Provider>
            </isVerifiedContext.Provider>
        </isReadyContext.Provider>
    );
}

