export const BASE_URL = "/"
export const PRODUCT_PAGE_URL = "/product"
export const SHIPPING_PAGE_URL = "/shipping"
export const PRIVACY_POLICY_PAGE_URL = '/privacy-policy'
export const LOOK_PRODUCTS_PAGE_LOOKS_ROUTE = '/looks'
export const COLLECTION_PAGE_COLLECTIONS_ROUTE = '/collections'

const BASE_ROUTE_URL = `${window.basePath}/:brandName/:linkId`
const SHOPBOARD_BASE_ROUTE_URL = `${window.basePath}/:brandName`
export const SHOPBOARD_PAGE_ROUTE_URL = SHOPBOARD_BASE_ROUTE_URL
export const CART_PAGE_ROUTE_URL = BASE_ROUTE_URL
export const HOME_PAGE_ROUTE_URL = BASE_ROUTE_URL + "/media"
export const COLLECTION_PAGE_ROUTE_URL = BASE_ROUTE_URL + COLLECTION_PAGE_COLLECTIONS_ROUTE + "/:collectionId"
export const LOOK_PRODUCTS_ROUTE_URL = BASE_ROUTE_URL + LOOK_PRODUCTS_PAGE_LOOKS_ROUTE + "/:lookId"
export const PRODUCT_PAGE_ROUTE_URL = BASE_ROUTE_URL + PRODUCT_PAGE_URL
export const COLLECTION_PAGE_PRODUCT_PAGE_ROUTE_URL = COLLECTION_PAGE_ROUTE_URL + PRODUCT_PAGE_URL
export const SHIPPING_PAGE_ROUTE_URL = BASE_ROUTE_URL + SHIPPING_PAGE_URL

export const HOME_PAGE_URL = "/media"
export const PAYMENT_PAGE_URL = "/payment"

export const INVENTORY_ID_KEY = 'inventory_id'
export const RECENTLY_ADDED_KEY = 'recently_added'
export const SOURCE_KEY = 'source'

export const LOOK_PRODUCT_SOURCE = 'link_look_product'

export const SELECTED_OPTIONS_OPTION_KEY = 'selected_options'
export const QUICK_PRODUCT_ADD_KEY = 'quick_add'
export const LOOK_ID_KEY = 'look_id'
export const LOOK_PRODUCT_COUNT_KEY = 'products_count'
export const SOCIAL_KEY = 'social'
export const POST_ID_KEY = 'post_id'