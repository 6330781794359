import React from 'react'
import './ProductOptionsPicker.css'
import ProductOptionSelect from 'components/ProductOptionSelect/ProductOptionSelect'

/**
 * Represents a product options' picker.
 * @param {array} options - The array of the product's options
 * @param {object} selectedOptions - An object of the user's selected options
 * @param {function} setSelectedOptions - A function for setting the user's selected options
 * @param {boolean} isDisabled - Determins whether the option picker is disabled (not functional) or not
 */
export default function ProductOptionsPicker({
    options,
    selectedOptions,
    setSelectedOptions,
    isDisabled = false
}) {
    function getOptionCounter(options, option) {
        return options?.find(option1 => option?.name === option1?.name)?.values
    }

    return (
        <div className='product-options-picker'>
            {
                options && options.map(option => 
                    <div key={option.name} className={`product-options-picker-option-container ${isDisabled ? 'disabled' : ''}`}>
                        <ProductOptionSelect
                            optionName={option.name}
                            selectedOption={selectedOptions[option?.name]}
                            setSelectedOption={value => setSelectedOptions(option, value)}
                            optionCounter={getOptionCounter(options, option)}
                        />
                    </div>
                )
            }
        </div>
    )
}