import { useTranslation } from 'react-i18next'
import './CartPageTopBar.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { useHistoryContext } from 'contexts/History'
import { GrClose, GrLanguage } from 'react-icons/gr'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
import { FiShare } from 'react-icons/fi'
import { MdOutlineStorefront } from 'react-icons/md'
import { useEffect, useState } from 'react'
import copy from "copy-text-to-clipboard"
import { isAndroid } from "utils/utils"
import {
    CART_PAGE_SCROLL_STATES,
    HOME_PAGE_COPY_TO_CLIPBOARD_ALERT_DISPLAY_DELAY_MILLISECONDS,
    HOME_PAGE_DRAWER_TRANSITION_ANIMATION_DURATION_MILLISECONDS,
    HOME_PAGE_LANGUAGES_DRAWER_TOP_BORDER_RADIUS_PX,
    HOME_PAGE_OTHER_OPTIONS_MENU_PADDING_TOP_PX,
    HOME_PAGE_OTHER_OPTIONS_MENU_TOP_BORDER_RADIUS_PX,
    PAYDIN_DRAWER_DIRECTION,
    SHOPEAKS_SITE_URL,
    SHOPEAKS_LOGO_URL,
    SUPPORTED_LANGUAGES
} from 'constants/general.constants'
import { getCartID, getUsername, useBusinessPropertiesContext, useCartDetailsContext, useGeneralContext, useLinkDetailsContext } from 'contexts/shopping'
import PaydinDrawer from 'components/PaydinDrawer/PaydinDrawer'
import { LANG_KEY } from 'constants/storage.constant'
import i18next from 'i18next'
import { LocalStorageSetItem } from 'services/LocalStorage'
import CartPageTopBarMenuImage from 'imageComponents/CartPageTopBarMenuImage'
import BackButton from 'components/backButton'
import { copyToClipboard } from 'services/SystemService'

/**
 * Represents a top bar for the right drawer of the product page.
 * @param {function} onCloseDrawer - A callback function to perform when clicking on the close button 
 */
export default function CartPageTopBar({
    pageScrollState,
    setIsCartDrawerOpen = () => { },
    isMainPageTopBar = true,
    onBackButtonClick = () => { },
    onCenterClick = null
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { businessProperties, isPro } = useBusinessPropertiesContext()
    const cartDetails = useCartDetailsContext()
    const { linkDetails } = useLinkDetailsContext()
    const { forwardQueries } = useGeneralContext()

    const [isOtherOptionsMenuOpen, setIsOtherOptionsMenuOpen] = useState(false)
    const [isLanguageDrawerOpen, setIsLanguageDrawerOpen] = useState(false)
    const [topbarBackgroundColor, setTopbarBackgroundColor] = useState('white')

    useEffect(() => {
        switch (pageScrollState) {
            case CART_PAGE_SCROLL_STATES.TOP:
                setTopbarBackgroundColor('#ffffffff')
                break
            case CART_PAGE_SCROLL_STATES.MIDDLE:
                setTopbarBackgroundColor('#fffffff2')
                break
            default: // CART_PAGE_SCROLL_STATES.SCROLLING
                setTopbarBackgroundColor('#ffffff00')
                break
        }
    }, [pageScrollState])

    function shareLink() {
        history.goBack()
        const shareData = {
            text: buildLinkUrl()
        }
        if (navigator.share && navigator.canShare(shareData)) {
            navigator.share(shareData)
        } else {
            setTimeout(async () => {
                await copyToClipboard(buildLinkUrl())
                alert('Copied link to clipboard')
            }, HOME_PAGE_COPY_TO_CLIPBOARD_ALERT_DISPLAY_DELAY_MILLISECONDS)
        }
    }

    function buildLinkUrl() {
        return `${window.location.host}${window.basePath}/${getUsername()}/${linkDetails?.linkId}`
    }

    function openLanguageDrawer() {
        closeOtherOptionsMenu()
        setTimeout(() => {
            setIsLanguageDrawerOpen(true)
        }, HOME_PAGE_DRAWER_TRANSITION_ANIMATION_DURATION_MILLISECONDS)
    }

    function closeLanguageDrawer() {
        setIsLanguageDrawerOpen(false)
    }

    function changeLanguage(languageCode) {
        i18next.changeLanguage(languageCode)
        LocalStorageSetItem(LANG_KEY, languageCode)
        closeLanguageDrawer()
    }

    function buildStoreLink() {
        let queries = []
        let utmQueries = []
        if (forwardQueries) {
            queries = Object.keys(forwardQueries).map(queryKey => forwardQueries[queryKey] ? `&${queryKey}=${encodeURIComponent(forwardQueries[queryKey])}` : '')
        }
        if (linkDetails?.utmParams) {
            utmQueries = Object.keys(linkDetails?.utmParams).map(utmKey => {
                if (!linkDetails?.utmParams[utmKey]) {
                    return ''
                }
                if (forwardQueries && utmKey in forwardQueries && forwardQueries[utmKey]) {
                    return ''
                }
                return `&${utmKey}=${encodeURIComponent(linkDetails?.utmParams[utmKey])}`
            })
        }
        let query = `${queries.join('')}${utmQueries.join('')}`

        // TODO - Change to update the cart with ajax before forwarding, so that the cart will follow you
        // if (cartDetails.totalItems > 0) {
        //     let variantQuantityPairString = ''
        //     for (let cartItem of cartDetails.cartItems) {
        //         variantQuantityPairString += `${cartItem?.variant_id}:${cartItem?.quantity},`
        //     }
        //     variantQuantityPairString = variantQuantityPairString.slice(0, -1) // remove the redundant comma at the end
        //     return `//${businessProperties?.domain}/cart/${variantQuantityPairString}?storefront=true${hasPromocode() ? `&discount=${encodeURIComponent(linkDetails?.promocode?.code)}` : ''}&attributes[paylink]=${cartDetails.cartId}&pl_id=${getCartID()}${query}`
        // }
        return `//${businessProperties?.domain}${hasPromocode() ? '/discount/' + encodeURIComponent(linkDetails?.promocode?.code) : ''}?pl_id=${getCartID()}${query}`
    }

    function navigateToStoreLink() {
        window.open(buildStoreLink(), '_blank').focus()
    }

    function hasPromocode() {
        return ![undefined, null, ''].includes(linkDetails?.promocode?.code)
    }

    function buildPrivacyPolicyUrl() {
        return `${SHOPEAKS_SITE_URL}/privacy`
    }

    function closeOtherOptionsMenu() {
        setIsOtherOptionsMenuOpen(false)
    }

    function openOtherOptionsMenu() {
        setIsOtherOptionsMenuOpen(true)
    }

    return (
        <div className='cart-page-top-bar' style={{ backgroundColor: topbarBackgroundColor, height: pageScrollState !== CART_PAGE_SCROLL_STATES.TOP ? '40px' : '50px' }}>
            {isMainPageTopBar ? <CartPageTopBarMenuImage className="cart-page-top-bar-options-button" onClick={openOtherOptionsMenu} /> : <BackButton
                disableHistoryPopOnClick={true}
                onBackIcon={onBackButtonClick}
            />}
            <div onClick={onCenterClick ?? navigateToStoreLink}>
                {
                    businessProperties?.logoUrl ? <ImageBox
                        className={`cart-page-top-bar-logo ${pageScrollState !== CART_PAGE_SCROLL_STATES.TOP && 'hide'}`}
                        image={businessProperties?.logoUrl}
                        alt="left button"
                    /> : <div className={`cart-page-top-bar-title ${pageScrollState !== CART_PAGE_SCROLL_STATES.TOP && 'hide'}`}>{businessProperties?.businessName}</div>
                }
            </div>
            <div className="cart-page-top-bar-cart-image-container" onClick={() => setIsCartDrawerOpen(true)}>
                {businessProperties?.theme?.cartButtonIcon}
                {cartDetails?.totalItems >= 10 ? <div className="cart-page-top-bar-cart-dot"></div> : <div className="cart-page-top-bar-cart-products-count">{cartDetails?.totalItems}</div>}
            </div>
            {
                isMainPageTopBar && <>
                    <PaydinDrawer
                        maxWidth={'calc(var(--max-width) * 0.8)'}
                        margin={'auto'}
                        direction={PAYDIN_DRAWER_DIRECTION.BOTTOM}
                        isOpen={isOtherOptionsMenuOpen}
                        onOpen={openOtherOptionsMenu}
                        onClose={closeOtherOptionsMenu}
                        topRightBorderRadiusPx={HOME_PAGE_OTHER_OPTIONS_MENU_TOP_BORDER_RADIUS_PX}
                        topLeftBorderRadiusPx={HOME_PAGE_OTHER_OPTIONS_MENU_TOP_BORDER_RADIUS_PX}
                        paddingTopPx={HOME_PAGE_OTHER_OPTIONS_MENU_PADDING_TOP_PX}
                        paddingBottomPx={0}
                        isContentLoaded={true}
                    >
                        <>
                            <div dir={t('direction.dir')} className="home-page-other-options-menu">
                                <div dir='ltr' className="home-page-other-options-menu-close-button-container" style={{ width: '100%' }}>
                                    <GrClose className="home-page-other-options-menu-close-button" onClick={closeOtherOptionsMenu} />
                                </div>
                                <div className="home-page-other-options-menu-item-line">
                                    <div className="home-page-other-options-menu-item-share-this-link other-options-menu-single-item" onClick={shareLink}>
                                        <FiShare className="home-page-other-options-menu-item-image" />
                                        <div className="home-page-other-options-menu-item-share-this-link-text">{t('HOME_PAGE_OTHER_OPTIONS_MENU_ITEM_SHARE_THIS_LINK_LABEL')}</div>
                                    </div>
                                    <a href={buildStoreLink()} className="home-page-other-options-menu-item-view-shop other-options-menu-single-item" target="_blank">
                                        <MdOutlineStorefront className="home-page-other-options-menu-item-image" />
                                        <div className="home-page-other-options-menu-item-view-shop">{t('HOME_PAGE_OTHER_OPTIONS_MENU_ITEM_VIEW_SHOP_LABEL')}</div>
                                    </a>
                                    <div className="home-page-other-options-menu-item-change-language other-options-menu-single-item" onClick={openLanguageDrawer}>
                                        <GrLanguage className="home-page-other-options-menu-item-image" />
                                        <div className="home-page-other-options-menu-item-change-language-text">{t('HOME_PAGE_OTHER_OPTIONS_MENU_ITEM_CHANGE_LANGUAGE_LABEL')}</div>
                                    </div>
                                </div>
                                <div className="home-page-other-options-menu-section">
                                    <a className="home-page-other-options-menu-item-shipping-and-returns home-page-other-options-menu-section-item" href={businessProperties?.shippingAndReturnsUrl} target="_blank">
                                        <div>{t('HOME_PAGE_OTHER_OPTIONS_MENU_ITEM_SHIPPING_AND_RETURNS_LABEL')}</div>
                                        {
                                            t('direction.dir') === 'ltr' ?
                                                <IoIosArrowForward className="home-page-other-options-menu-section-item-image" /> :
                                                <IoIosArrowBack className="home-page-other-options-menu-section-item-image" />
                                        }
                                    </a>
                                    <a href={buildPrivacyPolicyUrl()} target="_blank" className="home-page-other-options-menu-item-privacy-policy home-page-other-options-menu-section-item">
                                        <div>{t('HOME_PAGE_OTHER_OPTIONS_MENU_ITEM_PRIVACY_POLICY_LABEL')}</div>
                                        {
                                            t('direction.dir') === 'ltr' ?
                                                <IoIosArrowForward className="home-page-other-options-menu-section-item-image" /> :
                                                <IoIosArrowBack className="home-page-other-options-menu-section-item-image" />
                                        }
                                    </a>
                                </div>
                                {!isPro() && <div className="home-page-other-options-menu-create-your-own-link-section">
                                    <div className="home-page-other-options-menu-create-your-own-link-section-separator"></div>
                                    <div className="home-page-other-options-menu-create-your-own-link-section-titles-section">
                                        <div className="home-page-other-options-menu-create-your-own-link-section-title">{t('HOME_PAGE_OTHER_OPTIONS_MENU_CREATE_YOUR_OWN_LINK_SECTION_TITLE')}</div>
                                        <div className="home-page-other-options-menu-create-your-own-link-section-subtitle">{t('HOME_PAGE_OTHER_OPTIONS_MENU_CREATE_YOUR_OWN_LINK_SECTION_SUBTITLE')}</div>
                                    </div>
                                    <a href={SHOPEAKS_SITE_URL} target="_blank">
                                        <div className="home-page-other-options-menu-item-create-your-own-link-button">{t('HOME_PAGE_OTHER_OPTIONS_MENU_ITEM_CREATE_YOUR_OWN_LINK_LABEL')}</div>
                                    </a>
                                </div>}
                            </div>
                            <div className="cart-page-watermark-bar">
                                {!isPro() && <ImageBox
                                    image={SHOPEAKS_LOGO_URL}
                                    className='cart-page-watermark-image'
                                />}
                            </div>
                        </>
                    </PaydinDrawer>
                    <PaydinDrawer
                        direction={PAYDIN_DRAWER_DIRECTION.BOTTOM}
                        isOpen={isLanguageDrawerOpen}
                        onOpen={openLanguageDrawer}
                        onClose={closeLanguageDrawer}
                        topRightBorderRadiusPx={HOME_PAGE_LANGUAGES_DRAWER_TOP_BORDER_RADIUS_PX}
                        topLeftBorderRadiusPx={HOME_PAGE_LANGUAGES_DRAWER_TOP_BORDER_RADIUS_PX}
                        hasPadding={false}
                        maxWidth={'calc(var(--max-width) * 0.8)'}
                        margin={'auto'}
                        isContentLoaded={true}
                    >
                        {SUPPORTED_LANGUAGES.map(lang => <div key={lang.code + lang.name} className="home-page-languages-drawer-item" onClick={() => changeLanguage(lang?.code)}>{lang?.name}</div>)}
                    </PaydinDrawer>
                </>
            }
        </div>
    )
}