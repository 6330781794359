import { environment } from "conf";
import { request_auth } from 'services/RequestWrapper';
import { GET_LOOK_PRODUCTS_API_PRODUCTS_ROUTE, GET_POST_PRODUCTS_API_PRODUCTS_ROUTE, GET_POST_SECTIONS_API_PRODUCTS_ROUTE, NOTIFY_VIEW_PRODUCT_API_PRODUCT_ROUTE, NOTIFY_VIEW_PRODUCT_API_VIEW_ROUTE, POSTS_URL_ROUTE, SHOPBOARD_URL_ROUTE, SHOP_API_LINKS_ROUTE, SHOP_API_SENSE_ROUTE, SHOP_API_URL, START_SESSION_API_ROUTE, START_SHOPPING_URL } from 'constants/api.constant';
import { getCartID, getUsername } from "contexts/shopping";

export function StartShoppingAPI(username, linkId, currencyCode, countryCode) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username,
            },
            body: {
                currency_code: currencyCode,
                country_code: countryCode
            }
        }
        request_auth(environment.base_url + START_SHOPPING_URL + (linkId ? `/${linkId}` : ''), options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    if (response.error_code === 302)
                        window.location.replace(response.error)
                    else
                        reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

/*
    Notifies the server for session start (for now, 15 seconds after loading) a product page

    Method: [POST]
    URL: /shopping/<linkId>/session

    Headers:
    =========
    (*) username - The username of the current user

    Route params:
    ==============
        (*) linkId - The id of the given link
        (*) cartId - The id of the customer's cart in this session
*/
export function notifySessionStartAPI(username, linkId, cartId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username,
            },
            body: {
                cart_id: cartId,
            }
        }
        const url = `${environment.base_url}${START_SHOPPING_URL}/${linkId}${START_SESSION_API_ROUTE}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Notifies the server for visiting a product page

    Method: [POST]
    URL: /shopping/<linkId>/product/view

    Headers:
    =========
    (*) username - The username of the current user

    Route params:
    ==============
        (*) linkId - The id of the given link
*/
export function notifyProductViewAPI(username, inventoryId, linkId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username,
            },
            body: {
                cart_id: getCartID(),
                inventory_id: inventoryId
            }
        }
        const url = `${environment.base_url}${START_SHOPPING_URL}/${linkId}${NOTIFY_VIEW_PRODUCT_API_PRODUCT_ROUTE}${NOTIFY_VIEW_PRODUCT_API_VIEW_ROUTE}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Retrieves all the products of the link whose id is 'linkId'.

    Method: [POST]
    URL: /shopping/<linkId>/products/<lookId>

    Headers:
    =========
    (*) username - The username of the current user

    Route params:
    ==============
        (*) linkId - The id of the requested link
        (*) lookId - The id of the requested look
*/
export function getLinkLookProducts(username, linkId, lookId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username,
            },
            body: {
                cart_id: getCartID()
            }
        }
        const url = `${environment.base_url}${START_SHOPPING_URL}/${linkId}${GET_LOOK_PRODUCTS_API_PRODUCTS_ROUTE}/${lookId}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Fetches the posts of the given 'social'.

    Method: [POST]
    URL: /shopping/shopboard/<social>/posts

    Headers:
    =========
    (*) username - The username of the current user

    Query params:
    ==============
        (*) count
        (*) skip

    Route params:
    ==============
        (*) social
*/
export function fetchSocialPosts(social, count, skip) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username: getUsername(),
            },
            body: {
                cart_id: getCartID()
            }
        }
        const url = `${environment.base_url}${START_SHOPPING_URL}${SHOPBOARD_URL_ROUTE}/${social}${POSTS_URL_ROUTE}?count=${count}&skip=${skip}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Fetches the products of the given post in 'social' whose id is 'postId'.

    Method: [POST]
    URL: /shopping/shopboard/<social>/posts/<postId>/products

    Headers:
    =========
    (*) username - The username of the current user

    Route params:
    ==============
        (*) social
        (*) postId
*/
export function fetchSocialPostProducts(social, postId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username: getUsername(),
            },
            body: {
                cart_id: getCartID()
            }
        }
        const url = `${environment.base_url}${START_SHOPPING_URL}${SHOPBOARD_URL_ROUTE}/${social}${POSTS_URL_ROUTE}/${postId}${GET_POST_PRODUCTS_API_PRODUCTS_ROUTE}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}


export function fetchSocialPostSections(social, postId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username: getUsername(),
            },
            body: {
                cart_id: getCartID()
            }
        }
        const url = `${environment.base_url}${SHOP_API_URL}${SHOP_API_SENSE_ROUTE}${SHOPBOARD_URL_ROUTE}/${social}${POSTS_URL_ROUTE}/${postId}${GET_POST_SECTIONS_API_PRODUCTS_ROUTE}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}