import React from 'react'

export default function ShowHideSimilarButtonIcon(props) {
    return (
        <svg
            {...props}
            id="Layer_1"
            enableBackground="new 0 0 64 64"
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m62 31c0 9.9248047-8.0751953 18-18 18h-34.788147l6.1260376 5.5136719c.8212891.7382813.8876953 2.0029297.1489258 2.8242188-.3950195.4384765-.9404297.6621093-1.4873047.6621093-.4770508 0-.9550781-.1689453-1.3374023-.5136719l-10-9c-.4213867-.3789062-.6621094-.9189453-.6621094-1.4863281s.2407227-1.1074219.6621094-1.4863281l10-9c.8203125-.7402344 2.0859375-.6728516 2.824707.1484375s.6723633 2.0859375-.1489258 2.8242188l-6.1260376 5.5136718h34.788147c7.7197266 0 14-6.2802734 14-14 0-1.1044922.8955078-2 2-2s2 .8955078 2 2zm-58 4c1.1044922 0 2-.8955078 2-2 0-7.7197266 6.2802734-14 14-14h34.7883301l-6.1262207 5.5131836c-.8212891.7392578-.8876953 2.0039063-.1484375 2.824707.3945312.4389649.9394531.6621094 1.4873047.6621094.4765625 0 .9550781-.1694336 1.3369141-.5131836l10-9c.4218749-.3793945.6621093-.9199219.6621093-1.4868164s-.2402344-1.1074219-.6621094-1.4868164l-10-9c-.8212891-.7387695-2.0859375-.671875-2.8242188.1489258-.7392578.8208008-.6728516 2.0854492.1484375 2.824707l6.1262208 5.5131836h-34.7883301c-9.925293 0-18 8.074707-18 18 0 1.1044922.8955078 2 2 2z" />
        </svg>
    )
}