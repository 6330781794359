import Loader from 'components/Loader/Loader'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import QuickPinchZoom, {
    make2dTransformValue,
    make3dTransformValue,
    hasTranslate3DSupport
} from "react-quick-pinch-zoom"
import './ImageBox.css'
import { IMAGE_FITS, IMAGE_PLACEHOLDER_URL, PRODUCT_PAGE_IMAGE_CAROUSEL_COVER_IMAGE_RATIO_MAX, PRODUCT_PAGE_IMAGE_CAROUSEL_COVER_IMAGE_RATIO_MIN } from 'constants/general.constants'
import ImageDialog from 'dialog/ImageDialog/ImageDialog'
import LazyLoad from 'react-lazyload';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const use3DTransform = hasTranslate3DSupport() && !isSafari;

const makeTransformValue = use3DTransform
    ? make3dTransformValue
    : make2dTransformValue;

/**
 * Represents an image container with an optional loder to diaplay while the image is being loaded.
 * @param {Image/string} image - The image object or image url of the requested image
 * @param {string} className - A class name for the image container
 * @param {string} imageClassName - A class name for the image
 * @param {string} alt - An alternative text for the image
 * @param {boolean} showImageLoader - Determins whether to show the loader or not
 * @param {boolean} showGreyLoaderBackground - Determins whether to show grey background to the loader or not
 * @param {boolean} showGreyImageBackground - Determins whether to show grey background to the image or not
 * @param {boolean} showImageBorderRadius - Determins whether to show the image's border radius or not
 * @param {boolean} showImageMask - Determins whether to show the image's mask layer or not
 * @param {number} aspectRatio - The aspect ratio for this image
 * @param {string} borderRadius - The border radius of the image
 * @param {string} border - The border of the image
 * @param {boolean} isImageFitCover - Determins whether the image object fit css preperty is cover (if true) or not
 * @param {boolean} shouldChangeImageFitByDimensions - Determins whether the image's object fit property should be change according to the dimensions of the image or not. If "width" or "height" is falsy, then this prop is ignored.
 * @param {number} width - The width of the image
 * @param {number} height - The height of the image
 * @param {number} containerHeight - Overrides the image's original height, in pixels
 * @param {number} containerWidth - Overrides the image's original width, in pixels
 * @param {number} loaderWidth - The width of the loader in pixels
 * @param {number} loaderHeight - The height of the loader in pixels
 * @param {number} loaderBorderWidth - The width of the loader's circumference in pixels
 * @param {boolean} isWidthPercentage - Determins whether the width of the loader will be set as percentage or not
 * @param {boolean} isHeightPercentage - Determins whether the height of the loader will be set as percentage or not
 * @param {function} onImageClickCallback - A callback to perform when the image was clicked
 * @param {boolean} supportZoom - Determins whether you can zoom in/out the image or not
 * @param {boolean} enlargeImageOnClick - Determins whether a click on the image shows an image dialog that displays this image fullscreen or not
 * @param {function} onImageLoadSuccess - A function for performing additional operations when the image was loaded successfully
 * @param {function} onImageLoadError - A function for performing additional operations when the image failed to load
 */
export default function ImageBox({
    image,
    className = '',
    imageClassName = '',
    alt = '',
    showImageLoader = false,
    showGreyLoaderBackground = false,
    showGreyImageBackground = false,
    showImageBorderRadius = false,
    showImageMask = false,
    aspectRatio = null,
    borderRadius = '10px',
    border = null,
    isImageFitCover = false,
    shouldChangeImageFitByDimensions = false,
    width = null,
    height = null,
    imageFrameWidth = null,
    imageFrameHeight = null,
    containerHeight = null,
    containerWidth = null,
    loaderWidth = 30,
    loaderHeight = 30,
    loaderBorderWidth = 2,
    isWidthPercentage = false,
    isHeightPercentage = false,
    onImageClickCallback = () => { },
    supportZoom = false,
    enlargeImageOnClick = false,
    onImageLoadSuccess = () => { },
    onImageLoadError = () => { },
    roundImage = false,
    backgroundColor = null,
    lazyLoad = false
}) {

    const [imageFit, setImageFit] = useState(IMAGE_FITS.COVER)
    const [isImageLoading, setIsImageLoading] = useState(false)
    const [imageDialogState, setImageDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeImageDialog,
        imageUrl: ''
    })

    const imageRef = useRef(null)

    const containerStyles = {
        ...(containerHeight != null ? { height: containerHeight } : {}),
        ...(containerWidth != null ? { width: containerWidth } : {}),
        borderRadius: showImageBorderRadius ? borderRadius : 0,
        border: border ? border : "unset",
        ...(aspectRatio ? { aspectRatio: `${aspectRatio}` } : {})
    }

    const imageStyles = {
        display: isImageLoading ? 'none' : 'block',
        backgroundColor: showGreyImageBackground ? backgroundColor ?? '#fafafa' : 'transparent',
        borderRadius: showImageBorderRadius ? borderRadius : 0,
        objectFit: shouldChangeImageFitByDimensions ? imageFit : (isImageFitCover ? IMAGE_FITS.COVER : IMAGE_FITS.CONTAIN),
        borderRadius: roundImage ? '50%' : (showImageBorderRadius ? borderRadius : 0),
    }

    useEffect(() => {
        if (shouldChangeImageFitByDimensions && width && height && imageFrameWidth && imageFrameHeight) {
            const imageFrameRatio = imageFrameWidth / imageFrameHeight
            const imageRatio = width / height
            if (imageRatio >= PRODUCT_PAGE_IMAGE_CAROUSEL_COVER_IMAGE_RATIO_MIN * imageFrameRatio && imageRatio <= PRODUCT_PAGE_IMAGE_CAROUSEL_COVER_IMAGE_RATIO_MAX * imageFrameRatio) {
                setImageFit(IMAGE_FITS.COVER)
            } else {
                setImageFit(IMAGE_FITS.CONTAIN)
            }
        }
    }, [shouldChangeImageFitByDimensions, width, height, imageFrameWidth, imageFrameHeight])

    useEffect(() => {
        if (showImageLoader) {
            if ([null, undefined].includes(image) || imageRef?.current?.complete) {
                setIsImageLoading(false)
            } else {
                imageRef.current.setAttribute('src', null)
                imageRef.current.setAttribute('src', image)
                setIsImageLoading(true)
            }
        }
    }, [image])

    function onImageLoad() {
        setIsImageLoading(false)
        onImageLoadSuccess()
    }

    function onImageError() {
        setIsImageLoading(false)
        onImageLoadError()
    }

    function closeImageDialog() {
        setImageDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function showImageDialog() {
        setImageDialogState(prev => ({
            ...prev,
            isOpen: true,
            imageUrl: image
        }))
    }

    function onImageClick() {
        if (enlargeImageOnClick) {
            showImageDialog()
        } else {
            onImageClickCallback(image)
        }
    }

    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img } = imageRef

        if (img) {
            const value = makeTransformValue({ x, y, scale })

            img.style.setProperty("transform", value);
        }
    }, [])

    return (
        <div className={`image-box-container ${className}`} onClick={onImageClick} style={containerStyles}>
            {showImageMask && <div className="image-box-mask" style={{ borderRadius }}></div>}
            {
                (isImageLoading && showImageLoader) && <div className="image-box-loader-container" style={{
                    backgroundColor: showGreyLoaderBackground ? 'hsl(0, 0%, 90%)' : 'transparent',
                    borderRadius: showImageBorderRadius ? borderRadius : 0
                }}
                >
                    <Loader styles={{
                        width: `${loaderWidth}${isWidthPercentage ? '%' : 'px'}`,
                        height: `${loaderHeight}${isHeightPercentage ? '%' : 'px'}`,
                        borderWidth: `${loaderBorderWidth}px`
                    }} />
                </div>
            }
            {
                supportZoom ?
                    <div className={`image-box-image ${imageClassName}`}>
                        <QuickPinchZoom
                            onUpdate={onUpdate}
                            doubleTapToggleZoom={true}
                            centerContained={true}
                            enforceBoundsDuringZoom={true}
                            containerProps={{
                                style: {
                                    touchAction: "auto",
                                    height: "100%",
                                    width: "100%",
                                    overflow: "hidden"
                                }
                            }}
                        >
                            <img ref={imageRef}
                                src={image ? image : IMAGE_PLACEHOLDER_URL}
                                alt={alt}
                                style={imageStyles}
                                onLoad={onImageLoad}
                                onError={onImageError}
                            />
                        </QuickPinchZoom>
                    </div> :
                    //Lazy loading image component
                    lazyLoad ? <LazyLoad
                            className={`image-box-image ${imageClassName}`}
                            overflow={true}
                            once
                        >
                            <img ref={imageRef}
                                src={image ? image : IMAGE_PLACEHOLDER_URL}
                                alt={alt}
                                className={`image-box-image ${imageClassName}`}
                                style={imageStyles}
                                onLoad={onImageLoad}
                                onError={onImageError}
                                loading={lazyLoad ? "lazy" : "eager"}
                            />
                        </LazyLoad> :
                        <img ref={imageRef}
                                src={image ? image : IMAGE_PLACEHOLDER_URL}
                                alt={alt}
                                className={`image-box-image ${imageClassName}`}
                                style={imageStyles}
                                onLoad={onImageLoad}
                                onError={onImageError}
                                loading={lazyLoad ? "lazy" : "eager"}
                            />
            }
            {
                (enlargeImageOnClick && imageDialogState.isOpen) && <ImageDialog
                    isDialogOpen={imageDialogState.isOpen}
                    handleDialogClose={imageDialogState.handleDialogClose}
                    image={imageDialogState.imageUrl}
                />
            }
        </div>
    )
}
