import React, { useEffect, useState } from 'react'
import './ColorSelect.css'
import { calculateDistance3D, hexToRgb } from 'utils/utils'
import { BsCheck } from 'react-icons/bs'
import { useBusinessPropertiesContext } from 'contexts/shopping'
import { COLOR_SELECT_DEFAULT_DIMENSIONS, COLOR_SELECT_WARM_COLOR_THRESHOLD } from 'constants/general.constants'
import { useTranslation } from 'react-i18next'

/**
 * Represents a color option selector.
 * @param {string} selectedColor - The state of the selected color
 * @param {function} setSelectedColor - A function for setting the state of the selected color
 * @param {object} colorCounter - The color option values quantities object
 */
export default function ColorSelect({
    optionName,
    selectedColor,
    setSelectedColor = () => { },
    colorCounter = {},
    colorCircleDimensionsPx = COLOR_SELECT_DEFAULT_DIMENSIONS,
    onMoreColorsButtonClick = () => { },
    colorSelectDisplayedColorsCount = 0
}) {
    const { t } = useTranslation()
    const { 
        businessProperties,
        getOptionValue
    } = useBusinessPropertiesContext()

    const [colors, setColors] = useState([])

    useEffect(() => {

        let tempColors = Object.keys(colorCounter)

        if (tempColors?.length > colorSelectDisplayedColorsCount) {
            if (selectedColor && colorSelectDisplayedColorsCount > 0 && tempColors.includes(selectedColor) && !tempColors?.slice(0, colorSelectDisplayedColorsCount - 1).includes(selectedColor)) {
                setColors([...tempColors?.slice(0, colorSelectDisplayedColorsCount - 2), selectedColor])
            } else {
                setColors(tempColors?.slice(0, colorSelectDisplayedColorsCount - 1))
            }
        } else {
            setColors(tempColors?.slice(0, colorSelectDisplayedColorsCount))
        }

    }, [colorCounter, selectedColor, colorSelectDisplayedColorsCount])

    function onColorClick(color) {
        setSelectedColor(color)
    }

    function isColorSelected(color) {
        return selectedColor === color
    }

    function getSelectedColorBorderColor() {
        const whiteColorRGB = { r: 255, g: 255, b: 255 }
        const checkoutButtonBackgroundColorRGB = hexToRgb(businessProperties?.theme?.checkoutButtonBackgroundColor)
        const checkoutButtonBorderColorRGB = hexToRgb(businessProperties?.theme?.checkoutButtonBorderColor)

        if (!checkoutButtonBackgroundColorRGB || !checkoutButtonBorderColorRGB) {
            return 'black'
        }

        if (calculateDistance3D(whiteColorRGB, checkoutButtonBackgroundColorRGB) >= COLOR_SELECT_WARM_COLOR_THRESHOLD) {
            return businessProperties?.theme?.checkoutButtonBackgroundColor
        }

        if (calculateDistance3D(whiteColorRGB, checkoutButtonBorderColorRGB) >= COLOR_SELECT_WARM_COLOR_THRESHOLD) {
            return businessProperties?.theme?.checkoutButtonBorderColor
        }

        return 'black'
    }

    function renderColorCircle(color) {
        return <div key={color} className='color-select-item-frame'>
            <div
                className={`color-select-item ${isColorSelected(color) ? 'selected' : ''} ${colorCounter[color] === 0 ? 'out-of-stock' : ''}`}
                onClick={() => onColorClick(color)}
                style={{
                    backgroundColor: getOptionValue(optionName, color),
                    boxShadow: `0 0 0 2px white, 0 0 0 4px ${isColorSelected(color) ? getSelectedColorBorderColor() : '#cccccc'}`,
                    width: `${colorCircleDimensionsPx}px`,
                    height: `${colorCircleDimensionsPx}px`
                }}
            >
                {isColorSelected(color) && <BsCheck className='color-select-item-selected-v-mark' style={{
                    width: `${colorCircleDimensionsPx - 5}px`,
                    height: `${colorCircleDimensionsPx - 5}px`
                }} />}
            </div>
        </div>
    }

    return (
        <div dir={t('direction.dir')} className='color-select-container'>
            {
                (Object.keys(colorCounter)?.length >= 2 && Object.keys(colorCounter)?.length <= colorSelectDisplayedColorsCount) ?
                    colors?.map(color => renderColorCircle(color)) : <>
                        {colors?.map(color => renderColorCircle(color))}
                        <div className="color-select-item-more-colors" onClick={onMoreColorsButtonClick}>{t('COLOR_SELECT_MORE_COLORS_BUTTON_TEXT')}</div>
                    </>
            }
        </div>
    )
}