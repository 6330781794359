import { useHistoryContext } from "contexts/History"

import i18next from 'i18next'
import { useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { calculateComplementaryPercentage, formatNumber, generateRandomInteger, hasDiscount, isColorOption, isObjectEmpty } from 'utils/utils'
import { getCurrencySymbol } from 'services/Currency'
import './ProductPage.css'
import { useAddItemContext, useLinkDetailsContext, useCartDetailsContext, useBusinessPropertiesContext, useGeneralContext, getUsername } from 'contexts/shopping'
import {
    IMAGE_CAROUSEL_SCROLL_MOVEMENT_TOLLERANCE_PX,
    ADD_ITEM_ERROR_ALERT_MODAL_HIDE_DELAY_MILLISECONDS,
    PRODUCT_PAGE_IMAGE_CAROUSEL_INDICATORS_MAX_DISPLAYED_COUNT,
    PRODUCT_PAGE_IMAGE_CAROUSEL_TRANSITION_DURATION_MILLISECONDS,
    SWIPEABLE_DRAWER_RIGHT_AFTER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS,
    CART_MAX_WIDTH_DESKTOP_PX,
    TOP_BAR_HEIGHT_PX,
    PAYDIN_DRAWER_DIRECTION,
    PAYDIN_DRAWER_TRANSITION_DURATION,
    CART_LOOK_PRODUCTS_DRAWER_BORDER_RADIUS_PX,
} from 'constants/general.constants'
import ProductParser from 'parsers/ProductParser'
import { getQueryVariable } from 'services/SystemService'
import ImageBox from 'components/ImageBox/ImageBox'
import AlertModal from 'modal/AlertModal'
import { INVENTORY_ID_KEY, SELECTED_OPTIONS_OPTION_KEY } from "constants/routes.constants"
import ProductOptionsPicker from "components/ProductOptionsPicker/ProductOptionsPicker"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import LoaderButton from "components/LoaderButton/LoaderButton"
import { hasItemIdInStock, hasItemsInStock } from "utils/variantUtils"
import { getPrice } from 'utils/productUtils'
import ExpandHideButton from "components/ExpandHideButton/ExpandHideButton"
import CollapsingElement from "components/CollapsingElement/CollapsingElement"
import ItemsViewSection from "components/ItemsViewSection/ItemsViewSection"
import ProductItem from "components/ProductItem/ProductItem"
import PaydinDrawer from "components/PaydinDrawer/PaydinDrawer"

/**
 * Represents the product page to display a scanned product or a product from a recommendation.
 * @param {string} productInventoryId - The inventory id of the product. Used in cases that this page is loaded as a quick add display (inside a drawer)
 * @param {boolean} showOnlyOptionsPicker - Determins whether this page is loaded as a quick add display
 * @param {object} predefinedSelectedOptions - An object of predefined selected options selected by the user before this page was loaded
 * @param {boolean} isContentLoaded - Determins whether the content of this page is loaded or not
 * @param {boolean} isContainingDrawerOpen - Determins whether the containing drawer is open or not
 * @param {function} setIsContentLoaded - A function for setting the state if 'isContentLoaded' 
 * @param {function} onProductAdded - A function to perform whenever a product is added
 * @param {function} findProductByInventoryId - A function which accepts an inventory id and returns a product that corresponds to it
 */
export default function ProductPage({
    productInventoryId = '',
    showOnlyOptionsPicker = false,
    predefinedSelectedOptions = {},
    isContentLoaded = false,
    isContainingDrawerOpen = false,
    setIsContentLoaded = () => { },
    onProductAdded = () => { },
    findProductByInventoryId = () => { },
    showProductAddedMessage = () => {},
    productDrawerDirection = PAYDIN_DRAWER_DIRECTION.BOTTOM,
    isCartDrawerOpen = false,
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        notifyProductView,
        productsSectionsData,
        fetchProductSections,
        setProductsSectionsData,
        findProductInContext,
        shoppingData,
        forwardQueries
    } = useGeneralContext()
    const { businessProperties } = useBusinessPropertiesContext()
    const cartDetails = useCartDetailsContext()
    const addItem = useAddItemContext()
    const { linkDetails } = useLinkDetailsContext()

    const [productParser, setProductParser] = useState(null)
    const [parsedProduct, setParsedProduct] = useState(null)
    const [isUpdatingCart, setIsUpdatingCart] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [chosenOptions, setChosenOptions] = useState(initializeChosenOptions())
    const [lastChosenOption, setLastChosenOption] = useState({})
    const [optionsCounter, setOptionsCounter] = useState([])
    const [isAbleToAddToCart, setIsAbleToAddToCart] = useState(false)
    const [isPreorder, setisPreorder] = useState(false)
    const [isProductOutOfStock, setIsProductOutOfStock] = useState(false)
    const [selectedVariantId, setSelectedVariantId] = useState('')
    const [selectedCarouselItemIndex, setSelectedCarouselItemIndex] = useState(null)
    const [imageCarouselTransitionDuration, setImageCarouselTransitionDuration] = useState(0)
    const [imageCarouselFrameDimensions, setImageCarouselFrameDimensions] = useState({
        width: null,
        height: null
    })
    const [productPrice, setProductPrice] = useState({
        price: 0,
        originalPrice: 0
    })

    const [innerProductInventoryId, setInnerProductInventoryId] = useState('')
    const [innerProductPageShowOnlyOptionsPicker, setInnerProductPageShowOnlyOptionsPicker] = useState(false)
    const [innerProductSelectedOptions, setInnerProductSelectedOptions] = useState({})
    const [isInnerProductPageLoaded, setIsInnerProductPageLoaded] = useState(false)
    const [innerProductDrawerState, setInnerProductDrawerState] = useState({
        isOpen: false,
        height: 0,
        maxHeight: 0,
        onOpen: openInnerProductDrawer,
        onClose: closeInnerProductDrawer
    })

    const [isProductDetailsShown, setIsProductDetailsShown] = useState(false)
    const [productSections, setProductSections] = useState([])

    const productDescriptionRef = useRef(null)
    const imageFrameRef = useRef(null)
    const productContentRef = useRef(null)


    function getSectionTitle(section) {
        return section?.title[i18next.language] ?? ""
    }

    const productSectionsComp = useMemo(() => productSections?.map(section => {
        return section?.itemsIds && section?.itemsIds.length > 0 && <ItemsViewSection
            key={generateRandomInteger(1_000_000)}
            title={getSectionTitle(section)}
            data={section?.itemsIds?.map(itemId => findProductInContext(itemId))}
            type={section?.sectionType}
            itemWidth={section?.itemImageWidth}
            itemAspectRatio={section?.itemImageRatio}
            viewItemComponent={ProductItem}
            onItemClick={onInnerProductClick}
        />
    }), [productSections, t])


    function initializeChosenOptions() {
        if (isObjectEmpty(predefinedSelectedOptions)) {
            let selectedOptionsFromQuery = {}
            const selectedOptions = getQueryVariable(SELECTED_OPTIONS_OPTION_KEY, "")
            if (selectedOptions) {
                const decodedSelectedOptions = decodeURIComponent(selectedOptions)
                const queryChosenOptions = decodedSelectedOptions.split(',')
                selectedOptionsFromQuery = {}
                for (const option of queryChosenOptions) {
                    const optionEntry = option.split(':')
                    selectedOptionsFromQuery = {
                        ...selectedOptionsFromQuery,
                        [optionEntry[0]]: optionEntry[1]
                    }
                }
                return selectedOptionsFromQuery
            }
            return predefinedSelectedOptions
        }
        return predefinedSelectedOptions
    }

    useEffect(() => {
        if (!isContentLoaded) {
            setChosenOptions({})
            setImageCarouselTransitionDuration(0)
            setSelectedCarouselItemIndex(null)
        }
    }, [isContentLoaded])

    useEffect(() => {
        if (productInventoryId) {
            loadProductSections(productInventoryId)
        }
    }, [productInventoryId])

    useEffect(() => {
        let inventoryId = ''
        if (productInventoryId) {
            inventoryId = productInventoryId
        }

        if (!inventoryId) {
            return
        }

        setSelectedCarouselItemIndex(0)
        let currentProduct = null
        currentProduct = findProductByInventoryId(inventoryId)
        if (!currentProduct) {
            history.goBack()
            setIsContentLoaded(false)
            return
        }
        
        setChosenOptions(initializeChosenOptions())
        const productParser = new ProductParser(currentProduct, cartDetails)
        setProductParser(productParser)
        setParsedProduct(productParser.parseObject())
    }, [productInventoryId, predefinedSelectedOptions])

    useEffect(() => {
        if (!isContainingDrawerOpen || !parsedProduct) {
            setOptionsCounter([])
            return
        }

        if (Object.keys(chosenOptions).length === 0 || !isObjectEmpty(initializeChosenOptions(parsedProduct))) { // the initial state, where the user didn't choose any option yet
            if (parsedProduct) {
                const productOptionsCounter = []
                for (const option of parsedProduct?.options) {
                    let newOption = { name: option?.name }
                    const optionCounter = productParser?.getOptionCounter(option?.name, option?.values, {}, null, chosenOptions)
                    newOption.values = optionCounter
                    productOptionsCounter.push(newOption)
                }
                setOptionsCounter(productOptionsCounter)
            }
        } else { // the user has some chosen options
            const productOptionsCounter = []
            for (const option of optionsCounter) {
                let newOption = { name: option?.name }
                const optionCounter = productParser?.getOptionCounter(option?.name, Object.keys(option?.values), option?.values, lastChosenOption, chosenOptions)
                newOption.values = optionCounter
                productOptionsCounter.push(newOption)
            }
            setOptionsCounter(productOptionsCounter)
        }

        // find the variant which contains all the selected options
        let selectedVariantIdTemp = null
        if (Object.keys(chosenOptions).length === parsedProduct?.options?.length) {
            for (const variant of parsedProduct?.variants) {
                let chosenOptionsInVariantCount = 0
                for (const chosenOptionName of Object.keys(chosenOptions)) {
                    const chosenOptionNameInVariant = Object.keys(variant?.options)?.find(optionName => optionName === chosenOptionName) ?? ''
                    if (variant?.options[chosenOptionNameInVariant] === chosenOptions[chosenOptionNameInVariant]) {
                        chosenOptionsInVariantCount++
                    }
                }

                if (chosenOptionsInVariantCount === Object.keys(chosenOptions).length) {
                    selectedVariantIdTemp = variant?.variant_id
                    setSelectedVariantId(variant?.variant_id)
                    break
                }
            }
        }

        for (const optionName of Object.keys(chosenOptions)) {
            if (isColorOption(optionName)) {
                parsedProduct?.variants?.forEach(variant => {
                    if (variant?.options[optionName] === chosenOptions[optionName]) {
                        setSelectedCarouselItemIndex(variant?.image_position)
                    }
                })
            }
        }
        setProductPrice(getPrice(parsedProduct?.variants, chosenOptions))
        // this lets the image carousel react to the change of the selected item's index
        setTimeout(() => {
            setImageCarouselTransitionDuration(PRODUCT_PAGE_IMAGE_CAROUSEL_TRANSITION_DURATION_MILLISECONDS)
        }, PRODUCT_PAGE_IMAGE_CAROUSEL_TRANSITION_DURATION_MILLISECONDS)

        setIsAbleToAddToCart(Object.keys(chosenOptions).length === parsedProduct?.options?.length && hasItemIdInStock(parsedProduct?.variants, selectedVariantIdTemp))
        setisPreorder(Object.keys(chosenOptions).length === parsedProduct?.options?.length && !hasItemIdInStock(parsedProduct?.variants, selectedVariantIdTemp, true))
        setIsProductOutOfStock(!hasItemsInStock(parsedProduct?.variants))
        setIsContentLoaded(true)
    }, [chosenOptions, parsedProduct])

    useEffect(() => {
        if (isObjectEmpty(parsedProduct))
            return

        if (imageFrameRef?.current) {
            setImageCarouselFrameDimensions({
                width: imageFrameRef?.current?.clientWidth,
                height: imageFrameRef?.current?.clientHeight
            })
        }

        if (Object.keys(parsedProduct).length > 1 && !showOnlyOptionsPicker) {
            productDescriptionRef.current.innerHTML = parsedProduct?.description
            notifyProductView(linkDetails?.linkId, productInventoryId, parsedProduct?.title, getPrice(parsedProduct?.variants, chosenOptions).price)
        }
    }, [parsedProduct])

    useEffect(() => {
        if (!innerProductDrawerState.isOpen && shoppingData?.isProductAdded) {
            setTimeout(() => {
                showProductAddedMessage()
            }, PAYDIN_DRAWER_TRANSITION_DURATION)
        }
    }, [innerProductDrawerState])

    function chooseProductOption(option, value) {
        if (isColorOption(option?.name)) {
            parsedProduct?.variants?.forEach(variant => {
                if (variant?.options[option?.name] === value) {
                    setSelectedCarouselItemIndex(variant?.image_position)
                }
            })
        }
        setLastChosenOption(option)
        setChosenOptions(prev => ({
            ...prev,
            [option?.name]: value
        }))
    }

    function onAddToCartButtonClick() {
        addItemToCart(productInventoryId, selectedVariantId, 1, parsedProduct?.title)
    }

    function addItemToCart(inventory_id, variantId, quantity, title) {
        setIsUpdatingCart(true)
        addItem(inventory_id, variantId, quantity, title, (isAdded, error = "") => {
            if (isAdded) {
                onProductAdded()
                setIsUpdatingCart(false)
                history.goBack()
            } else {
                alert(ADD_ITEM_ERROR_ALERT_MODAL_HIDE_DELAY_MILLISECONDS, t('PRODUCT_PAGE_ALERT_ADD_ITEM_ERROR_MESSAGE'))
                setIsUpdatingCart(false)
            }
        })
    }

    function alert(timeout, msg, onAlertDismiss = () => { }) {
        setAlertMessage(msg)
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
            onAlertDismiss();
        }, timeout)
    }

    function buildProductRedirectUrl() {
        let queries = []
        let utmQueries = []
        if (forwardQueries) {
            queries = Object.keys(forwardQueries).map(queryKey => forwardQueries[queryKey] ? `&${queryKey}=${encodeURIComponent(forwardQueries[queryKey])}` : '')
        }
        if (linkDetails?.utmParams) {
            utmQueries = Object.keys(linkDetails?.utmParams).map(utmKey => {
                if (!linkDetails?.utmParams[utmKey]) {
                    return ''
                }
                if (forwardQueries && utmKey in forwardQueries && forwardQueries[utmKey]) {
                    return ''
                }
                return `&${utmKey}=${encodeURIComponent(linkDetails?.utmParams[utmKey])}`
            })
        }
        let query = `${queries.join('')}${utmQueries.join('')}`

        return `//${businessProperties?.domain}/products/${parsedProduct?.handle}?pl_id=${cartDetails?.cartId}${query}`
    }

    function getAddToCartButtonText() {
        if (isProductOutOfStock || (selectedVariantId && !isAbleToAddToCart)) {
            return t('PRODUCT_PAGE_SOLD_OUT_BUTTON_TEXT')
        }
        if (isAbleToAddToCart && isPreorder && businessProperties?.theme?.isPreorderTextShown) {
            return t('PRODUCT_PAGE_PREORDER_BUTTON_TEXT')
        }
        return t('PRODUCT_PAGE_ADD_TO_BAG_BUTTON_TEXT')
    }

    const discountStyles = {
        borderRadius: businessProperties?.theme?.productDiscountTagBorderRadius ?? 0,
        borderColor: businessProperties?.theme?.productDiscountTagBorderColor ?? 'firebrick',
        backgroundColor: businessProperties?.theme?.productDiscountTagBackgroundColor ?? 'white',
        color: businessProperties?.theme?.productDiscountTagFontColor ?? 'firebrick',
    }

    function renderQuickAddContent() {
        return <>
            <div dir={t('direction.dir')} className="product-summary-container">
                <div className="product-summary-title-container">
                    <div className="product-summary-title product-summary-text-long">{parsedProduct?.title}</div>
                </div>
                <div className="product-summary-prices-button-container">
                    <div className="product-summary-prices-container">
                        <div className="product-summary-price-percentage-row">
                            {hasDiscount(productPrice?.price, productPrice?.originalPrice) && <div className="product-summary-original-price line-through-text">{getCurrencySymbol(cartDetails?.currency)}{formatNumber(productPrice.originalPrice)}</div>}
                            <div 
                                className="product-summary-price"
                                style={hasDiscount(productPrice?.price, productPrice?.originalPrice) ? {color: businessProperties?.theme?.productDiscountFinalPriceFontColor} : {}}
                            >
                                {getCurrencySymbol(cartDetails?.currency)}{formatNumber(productPrice?.price)}
                            </div>
                            {
                                hasDiscount(productPrice?.price, productPrice?.originalPrice) && <div dir={"ltr"} className="product-summary-discount-percentage-tag" style={discountStyles}>{t('CART_CONTINUE_SHOPPING_SECTION_DISCOUNT_PERCENTAGE', { discountPercentage: calculateComplementaryPercentage(productPrice?.price, productPrice?.originalPrice) })}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ProductOptionsPicker
                options={optionsCounter}
                selectedOptions={chosenOptions}
                setSelectedOptions={chooseProductOption}
                isDisabled={isUpdatingCart}
            />
            {
                businessProperties?.theme?.isPreorderTextShown && businessProperties?.theme?.preorderText && <CollapsingElement
                    expanded={isAbleToAddToCart && isPreorder}
                >
                    <div className="product-summary-preorder-container">
                        <div className="product-summary-preorder-text product-summary-text-long">{businessProperties?.theme?.preorderText}</div>
                    </div>
                </CollapsingElement>
            }
            <LoaderButton
                className='product-summary-add-to-bag-button standard-button'
                buttonText={getAddToCartButtonText()}
                isLoading={isUpdatingCart}
                renderAsButton={false}
                isDisabled={!isAbleToAddToCart}
                loaderCircleColor={businessProperties?.theme?.checkoutButtonFontColor}
                styles={{
                    backgroundColor: businessProperties?.theme?.checkoutButtonBackgroundColor,
                    borderColor: businessProperties?.theme?.checkoutButtonBorderColor,
                    color: businessProperties?.theme?.checkoutButtonFontColor,
                    marginBottom: showOnlyOptionsPicker ? '50px' : 0,
                    marginTop: showOnlyOptionsPicker ? '15px' : 0
                }}
                onClick={onAddToCartButtonClick}
            />
            {showAlert && <AlertModal msg={alertMessage}></AlertModal>}
        </>
    }

    function toggleProductDetailsShown() {
        setIsProductDetailsShown(prev => !prev)
    }

    function loadProductSections(productId) {
        if (productId in productsSectionsData?.loadedProductsSections) {
            const cachedProductSectionsData = productsSectionsData?.loadedProductsSections[productId]
            setProductsSectionsData(prev => ({
                ...prev,
                lastProductSectionsData: cachedProductSectionsData,
            }))
            setProductSections(cachedProductSectionsData?.sections)
        } else {
            setProductSections([])
            fetchProductSections(productId, sections => {
                    setProductSections(sections)
                },
                error => {
                    console.log(error)
                })

        }
    }

    function onInnerProductClick(inventoryId) {
        if (inventoryId) {
            setInnerProductInventoryId(inventoryId)
            if (inventoryId && !innerProductDrawerState.isOpen) {
                openInnerProductDrawer()
            }
        }
    }

    function openInnerProductDrawer() {
        setInnerProductDrawerState(prev => ({
            ...prev,
            isOpen: true,
            height: `calc(100% - ${window.innerWidth > CART_MAX_WIDTH_DESKTOP_PX ? 0 : (TOP_BAR_HEIGHT_PX / 2)}px)`,
        }))
    }

    function closeInnerProductDrawer() {
        setInnerProductDrawerState(prev => ({
            ...prev,
            isOpen: false
        }))
        // Return the last sections data to this
        setProductsSectionsData(prev => {
            return  {
                ...prev,
                lastProductSectionsData: prev?.loadedProductsSections[productInventoryId],
            }
        })
        setTimeout(() => {
            resetProductDrawerProperties()
        }, PAYDIN_DRAWER_TRANSITION_DURATION)
    }

    function resetProductDrawerProperties() {
        setInnerProductInventoryId('')
        setIsInnerProductPageLoaded(false)
        setInnerProductSelectedOptions({})
    }

    return (
        <div className='product-page-container'>
            {!showOnlyOptionsPicker && <div className="product-page-hide-button" onClick={() => history.goBack()}>{t('CART_PRODUCT_DRAWER_HIDE_BUTTON_TEXT')}</div>}
            {
                showOnlyOptionsPicker ? <div dir={t('direction.dir')} className="product-page-details picker-only">
                    <div className="product-page-swiper-line"></div>
                    {renderQuickAddContent()}
                </div> : <div className="product-page-content" ref={productContentRef}>
                    <div ref={imageFrameRef}>
                        <Carousel
                            showStatus={false}
                            showArrows={false}
                            showThumbs={false}
                            showIndicators={parsedProduct?.images?.length >= 2 && parsedProduct?.images?.length <= PRODUCT_PAGE_IMAGE_CAROUSEL_INDICATORS_MAX_DISPLAYED_COUNT}
                            preventMovementUntilSwipeScrollTolerance={true}
                            swipeScrollTolerance={IMAGE_CAROUSEL_SCROLL_MOVEMENT_TOLLERANCE_PX}
                            selectedItem={selectedCarouselItemIndex}
                            transitionTime={imageCarouselTransitionDuration}
                        >
                            {
                                parsedProduct?.images?.map(image => <ImageBox
                                    key={image?.medium?.src}
                                    image={image?.medium?.src}
                                    className='product-image'
                                    showImageLoader={true}
                                    shouldChangeImageFitByDimensions={true}
                                    width={image?.medium?.width}
                                    height={image?.medium?.height}
                                    imageFrameWidth={imageCarouselFrameDimensions.width}
                                    imageFrameHeight={imageCarouselFrameDimensions.height}
                                    showGreyImageBackground={true}
                                    enlargeImageOnClick={true}
                                />)
                            }
                        </Carousel>
                    </div>
                    <div dir={t('direction.dir')} className="product-page-details">
                        <div className="product-page-padded-element">
                            <div className="product-page-swiper-line"></div>
                            {renderQuickAddContent()}
                        </div>
                        <div className='product-page-seperation-line' />
                        <div className="product-page-description product-page-padded-element centered">
                            <a dir={t('direction.dir')} className="product-page-view-this-product-in-store-button" href={buildProductRedirectUrl()} target="_blank">{t('PRODUCT_PAGE_VIEW_THIS_PRODUCT_IN_STORE')}</a>
                            <div className="product-detailed-information-container">
                                <ExpandHideButton
                                    onShowText={t("SHOW_PRODUCT_DETAILS_TEXT")}
                                    onHideText={t("HIDE_PRODUCT_DETAILS_TEXT")}
                                    isExpanded={isProductDetailsShown}
                                    onClick={toggleProductDetailsShown}
                                />
                                <CollapsingElement
                                    expanded={isProductDetailsShown}
                                >
                                    <div ref={productDescriptionRef} className="product-description product-summary-text-long"></div>
                                </CollapsingElement>
                            </div>
                        </div>
                        <div className='product-page-seperation-line' />
                        {productSectionsComp}
                    </div>
                </div>
            }
            <PaydinDrawer
                direction={productDrawerDirection} // if only picker always from bottom
                isOpen={innerProductDrawerState.isOpen}
                onOpen={innerProductDrawerState.onOpen}
                onClose={innerProductDrawerState.onClose}
                topRightBorderRadiusPx={CART_LOOK_PRODUCTS_DRAWER_BORDER_RADIUS_PX}
                topLeftBorderRadiusPx={CART_LOOK_PRODUCTS_DRAWER_BORDER_RADIUS_PX}
                hasPadding={false}
                maxWidth={window.innerWidth > CART_MAX_WIDTH_DESKTOP_PX ? `${CART_MAX_WIDTH_DESKTOP_PX * 0.7}px` : '100%'}
                margin={(window.innerWidth > CART_MAX_WIDTH_DESKTOP_PX && innerProductPageShowOnlyOptionsPicker) && (isCartDrawerOpen ? '0 0 0 auto' : '0 auto')}
                height={innerProductDrawerState.height}
                maxHeight={innerProductDrawerState.maxHeight}
                openedInNewRoute={false}
                isContentLoaded={isInnerProductPageLoaded}
            >
                <ProductPage
                    productInventoryId={innerProductInventoryId}
                    showOnlyOptionsPicker={innerProductPageShowOnlyOptionsPicker}
                    predefinedSelectedOptions={innerProductSelectedOptions}
                    isContainingDrawerOpen={innerProductDrawerState.isOpen}
                    isContentLoaded={isInnerProductPageLoaded}
                    setIsContentLoaded={setIsInnerProductPageLoaded}
                    onProductAdded={onProductAdded}
                    findProductByInventoryId={findProductInContext}
                    showProductAddedMessage={showProductAddedMessage}
                    productDrawerDirection={innerProductPageShowOnlyOptionsPicker ? PAYDIN_DRAWER_DIRECTION.BOTTOM : productDrawerDirection}
                />
            </PaydinDrawer>
        </div>
    )
}