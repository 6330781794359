import { environment } from "conf"
import ShoppingBagImage1 from "imageComponents/ShoppingBagImage1"
import ShoppingBagImage2 from "imageComponents/ShoppingBagImage2"
import ShoppingBagImage3 from "imageComponents/ShoppingBagImage3"

export const OTP_INPUT_LENGTH = 4
export const TIMEOUT_BETWEEN_SMS_SECONDS = 30
export const MAX_ITEMS_IN_CART = 100
export const SEND_AGAIN_COUNTER_1_SEC = 1000
export const DIALOG_CLOSING_DELAY_TIME_MILLISECONDS = 500
export const UPDATE_CART_ITEM_DELAY_MILLISECONDS = 300
export const ACCOUNT_PAGE_UPDATE_ADDRESS_DELAY_MILLISECONDS = 300
export const PRODUCT_NOT_FOUND_COUNT = 1
export const REQUEST_TIMEOUT_MILLISECONDS = 10000
export const REQUEST_PAYMENT_TIMEOUT_MILLISECONDS = 60000 // For payment request only, wait a long time before timeout, maybe it should be never??
export const TIME_BETWEEN_SCANS_MILLISECONDS = 3000
export const PRODUCT_NOT_EXIST_POPUP_DURATION_MILLISECONDS = 800
export const CHROME_BROWSER_NAME = "Chrome"
export const SAFARI_BROWSER_NAME = "Safari"
export const FORMATTED_ITEM_NAME_SEGMENT_LENGTH = 8

export const SWIPEABLE_DRAWER_SWIPER_AREA_HEIGHT_PX = 35
export const SWIPEABLE_DRAWER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS = 250
export const SWIPEABLE_DRAWER_RIGHT_AFTER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS = SWIPEABLE_DRAWER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS + 50
export const SWIPEABLE_DRAWER_BOUNCE_DELAY = 200

export const PRODUCT_PAGE_OPTIONS_LIST_ITEM_PADDING_PX = 15
export const PRODUCT_PAGE_PRODUCT_SUMMARY_BOTTOM_MARGIN_PX = 10
export const PRODUCT_PAGE_DIALOG_HEIGHT_PX = 315
export const PRODUCT_PAGE_IMAGE_CAROUSEL_TRANSITION_DURATION_MILLISECONDS = 350

export const PAYMENT_PAGE_INFORMATION_DIALOG_HEIGHT_PX = 230
export const PAYMENT_PAGE_CANCELING_LOADER_DURATION_MILISECONDS = 1000

export const PAYDIN_DIALOG_CONTENT_PADDING_PX = 15;
export const OTP_DIALOG_HEIGHT_PX = 350
export const POPUP_DIALOG_MIN_HEIGHT_PX = 200

export const SWIPEABLE_PAGE_SECONDARY_DRAWER_MIN_HEIGHT_EXTENSTION_PX = 80

export const TOP_BAR_HEIGHT_PX = 50
export const HOME_PAGE_TOP_BAR_HEIGHT_PX = 60

export const CHECKBOX_DEFAULT_BORDER_COLOR = '#ccc'
export const CHECKBOX_DEFAULT_SQUARE_DIMENS_PX = 25

export const APPLICATION_READY_TIMEOUT_MILISECONDS = 200
export const APPLICATION_RECOVERY_TIMEOUT_MILISECONDS = 20000

export const ANIMATIED_STRING_LOADER_ANIMATION_DURATION_SECONDS = 0.25;

export const PAYDIN_FIELD_BORDER_COLOR = '#B7BCD2'
export const PAYDIN_FIELD_BORDER_RADIUS_PX = 5

export const ADD_ITEM_ERROR_ALERT_MODAL_HIDE_DELAY_MILLISECONDS = 1500

export const SUMMARY_PAGE_TOP_BAR_HEIGHT_PX = 65;

export const PAYDIN_ACCORDION_NAMES = {
    ORDER_SUMMARY: 'order-summary',
    CREDIT_CARD: 'credit-card',
    PAYLINK: 'paylink'
}

export const PAYDIN_RADIO_BUTTON_NAMES = {
    SAVED_PAYMENT_CARD: 'saved-payment-card-option',
    CREDIT_CARD: '' // In the payment page there's no radio button for the credit card option, but this value "unchecks" the SAVED_PAYMENT_CARD radio button, so it improves readability
}

export const PAYDIN_PROMO_CODE_INDICATOR_STATES = {
    HIDDEN: 'hidden',
    VALID: 'valid',
    INVALID: 'invalid'
}

export const CART_ITEM_IMAGE_DEFAULT_RATIO = 0.77

export const PAYMENT_PAGE_CHECKBOX_SQUARE_DIMENS_PX = 20
export const PAYMENT_PAGE_INSTALLMENTS_SELECT_FIELD_WIDTH_PX_RTL = 70
export const PAYMENT_PAGE_INSTALLMENTS_SELECT_FIELD_WIDTH_PX_LTR = 90
export const PAYMENT_PAGE_ACCORDIONS_HEADER_BACKGROUND_COLOR = '#F6F7FA'
export const PAYMENT_PAGE_ACCORDIONS_CONTENT_BACKGROUND_COLOR = 'white'
export const PAYMENT_PAGE_ACCORDIONS_CONTENT_BORDER_COLOR = '#e2e2e2'
export const PAYMENT_PAGE_HIDING_PAYMENT_FIELDS_DELAY_MILLISECONDS = 500
export const PAYMENT_PAGE_HIDING_VALID_PROMO_CODE_INDICATOR_DELAY_MILLISECONDS = 1500
export const PAYMENT_PAGE_INVALID_PROMO_CODE_INPUT_FIELD_BORDER_COLOR = 'red'

export const ORDER_SUMMARY_HEADER_BACKGROUND_COLOR = '#F6F7FA'
export const ORDER_SUMMARY_CONTENT_BACKGROUND_COLOR = 'hsl(225, 29%, 93%)'

export const HOME_PAGE_DIALOG_HEIGHT_PX = 200
export const HOME_PAGE_OTHER_OPTIONS_MENU_TOP_BORDER_RADIUS_PX = 15
export const HOME_PAGE_LANGUAGES_DRAWER_TOP_BORDER_RADIUS_PX = 15
export const HOME_PAGE_LOADER_TIME_INTERVAL = 300
export const HOME_PAGE_OTHER_OPTIONS_MENU_PADDING_TOP_PX = 15
export const HOME_PAGE_DRAWER_TRANSITION_ANIMATION_DURATION_MILLISECONDS = 250
export const HOME_PAGE_COPY_TO_CLIPBOARD_ALERT_DISPLAY_DELAY_MILLISECONDS = 250

export const INVALID_CVV_VERIFICATION_ERROR_CODE = 21008
export const SHOPEAKS_SITE_URL = environment.site_url

export const PAYDIN_DIALOG_TRANSITION_DURATION = 250
export const PAYDIN_DRAWER_TRANSITION_DURATION = 250
export const PAYDIN_DRAWER_DIRECTION = {
    TOP: 'top',
    RIGHT: 'right',
    LEFT: 'left',
    BOTTOM: 'bottom',
}


export const SUPPORTED_LANGUAGES = [
    {
        code: 'en',
        name: 'english'
    },
    {
        code: 'es',
        name: 'español'
    },
    {
        code: 'he',
        name: 'עברית'
    }
]

export const DEFAULT_LANGUAGE_CODE = SUPPORTED_LANGUAGES.filter(lang => lang.code === 'en')[0].code
export const DEFAULT_CURRENCY_CODE = '' //'USD' -> Backend will decide on default from the business
export const DEFAULT_COUNTRY_CODE = '' //COUNTRY_CODES.USA -> Backend will decide on default from the business

export const CLOUD_APPLICATION_RESOURCES_BASE_URL = 'https://cdn.paylink.cloudfront.payli.in'
const CLOUD_APPLICATION_RESOURCES_ROUTE = '/application_resources'
export const CLOUD_IMAGES_ROUTE = '/images'

export const IMAGE_PLACEHOLDER_URL = CLOUD_APPLICATION_RESOURCES_BASE_URL + CLOUD_APPLICATION_RESOURCES_ROUTE + "/no_image.webp"
export const SHOPEAKS_LOGO_URL = CLOUD_APPLICATION_RESOURCES_BASE_URL + CLOUD_APPLICATION_RESOURCES_ROUTE + "/shopeaks_logo.png"

export const INSTAGRAM_LOGO_URL = CLOUD_APPLICATION_RESOURCES_BASE_URL + CLOUD_APPLICATION_RESOURCES_ROUTE + '/instagram_color.svg'
export const TIKTOK_LOGO_URL = CLOUD_APPLICATION_RESOURCES_BASE_URL + CLOUD_APPLICATION_RESOURCES_ROUTE + '/tik-tok_color.svg'
export const PINTEREST_LOGO_URL = CLOUD_APPLICATION_RESOURCES_BASE_URL + CLOUD_APPLICATION_RESOURCES_ROUTE + '/pinterest_color.svg'

export const INSTAGRAM_BLACK_WHITE_LOGO_URL = CLOUD_APPLICATION_RESOURCES_BASE_URL + CLOUD_APPLICATION_RESOURCES_ROUTE + '/instagram_black.svg'
export const TIKTOK_BLACK_WHITE_LOGO_URL = CLOUD_APPLICATION_RESOURCES_BASE_URL + CLOUD_APPLICATION_RESOURCES_ROUTE + '/tik-tok_black.svg'
export const PINTEREST_BLACK_WHITE_LOGO_URL = CLOUD_APPLICATION_RESOURCES_BASE_URL + CLOUD_APPLICATION_RESOURCES_ROUTE + '/pinterest_black.svg'

export const OPTIONS_DRAWER_OPTION_HEIGHT_PX = 54
export const OPTIONS_DRAWER_MAX_COUNT = 8
export const OPTIONS_DRAWER_BORDER_RADIUS_PX = 15

export const SPLASH_PAGE_DEFAULT_ANIMATED_LOADER_STRING = '...'

export const CART_HIDE_LOADER_AFTER_CHECKOUT_REQUEST_DONE_DELAY_MILLISECONDS = 2000
export const CART_OPEN_WIDTH_FROM_THE_SCREEN = 0.95

export const CART_PAGE_CHECKOUT_BUTTON_SHAPE_KEYS = {
    OPTION1: 'shape1',
    OPTION2: 'shape2',
    OPTION3: 'shape3',
}
export const CART_PAGE_CHECKOUT_BUTTON_SHAPE_OPTIONS = [
    {
        key: CART_PAGE_CHECKOUT_BUTTON_SHAPE_KEYS.OPTION1,
        value: '0px'
    },
    {
        key: CART_PAGE_CHECKOUT_BUTTON_SHAPE_KEYS.OPTION2,
        value: '10px'
    },
    {
        key: CART_PAGE_CHECKOUT_BUTTON_SHAPE_KEYS.OPTION3,
        value: '25px'
    }
]

export const CART_PAGE_CART_BUTTON_IMAGE_KEYS = {
    OPTION1: 'cart1',
    OPTION2: 'cart2',
    OPTION3: 'cart3',
}

export const CART_PAGE_CART_BUTTON_IMAGE_OPTIONS = [
    {
        key: CART_PAGE_CART_BUTTON_IMAGE_KEYS.OPTION1,
        value: <ShoppingBagImage1 className='topbar-right-button-image topbar-element cart-image' />
    },
    {
        key: CART_PAGE_CART_BUTTON_IMAGE_KEYS.OPTION2,
        value: <ShoppingBagImage2 className='topbar-right-button-image topbar-element cart-image' />
    },
    {
        key: CART_PAGE_CART_BUTTON_IMAGE_KEYS.OPTION3,
        value: <ShoppingBagImage3 className='topbar-right-button-image topbar-element cart-image' />
    }
]

export const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS = {
    OPTION1: 'shape1',
    OPTION2: 'shape2',
    OPTION3: 'shape3',
}
export const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS = [
    {
        key: PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS.OPTION1,
        value: 0,
        text: 'Sharp'
    },
    {
        key: PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS.OPTION2,
        value: 6,
        text: 'Semi-rounded'
    },
    {
        key: PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS.OPTION3,
        value: 10,
        text: 'Rounded'
    }
]

export const RUNNING_MESSAGES_STRIP_HEIGHT_PX = 30
export const RUNNING_MESSAGES_STRIP_TEXT_SWAP_DELAY_MILLISECONDS = 5000
export const RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_DURATION = 500
export const RUNNING_MESSAGES_STRIP_TEXT_NO_ANIMATION_NAME = 'none'
export const RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_IN_NAME = 'in-message-anim'
export const RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_OUT_NAME = 'out-message-anim'

export const COLOR_OPTION_NAMES = [
    'color',
    'colors',
    'colour',
    'colours',
    'צבע', 
    'צבעים'
]

export const SIZE_OPTION_NAMES = [
    'size',
    'sizes',
    'מידה',
    'מידות'
]

export const SHADE_OPTION_NAMES = [
    'shade',
    'גוון'
]

export const INVENTORY_POLICY_OPTIONS = {
    CONTINUE: "continue",
    DENY: "deny",
}

export const CART_LOOK_PRODUCTS_DRAWER_BORDER_RADIUS_PX = 10
export const CART_LINK_LOOK_PRODUCTS_DRAWER_PRODUCT_ADDED_STRIP_SHOW_DELAY_MILLISECONDS = 400
export const CART_LINK_LOOK_PRODUCTS_DRAWER_PRODUCT_ADDED_STRIP_HIDE_DELAY_MILLISECONDS = 2000

export const LINK_LOOK_PRODUCT_DETAILS_CONTAINER_HEIGHT_PX = 50

export const CART_LINK_LOOK_PRODUCTS_DRAWER_PRODUCT_ADDED_STRIP_ANIMATION_DURATION = 200

export const IMAGE_FITS = {
    COVER: 'cover',
    CONTAIN: 'contain'
}

export const IMAGE_CAROUSEL_SCROLL_MOVEMENT_TOLLERANCE_PX = 20

export const COLOR_SELECT_CIRCLE_DIMENSIONS = 24
export const COLOR_SELECT_CIRCLE_CONTAINER_GAP = 15
export const COLOR_SELECT_DEFAULT_DIMENSIONS = 32
export const COLOR_SELECT_WARM_COLOR_THRESHOLD = 130

export const PRODUCT_PAGE_IMAGE_CAROUSEL_COVER_IMAGE_RATIO_MAX = 1.2
export const PRODUCT_PAGE_IMAGE_CAROUSEL_COVER_IMAGE_RATIO_MIN = 0.85

export const COLOR_SELECT_MORE_COLORS_BUTTON_PADDING_START_PX = 5

export const PRODUCT_PAGE_IMAGE_CAROUSEL_INDICATORS_MAX_DISPLAYED_COUNT = 15

export const SESSION_START_TIME_MS = 15000

export const APPROVAL_MESSAGE_HIDDING_DELAY_MILLISECONDS = 2000
export const APPROVAL_MESSAGE_DEFAULT_TRANSLATION_DISTANCE = 80

export const CART_APPROVAL_MESSAGE_TRANSLATION_DISTANCE = 145

export const CART_BUTTON_ELEMENT_HORIZONTAL_PADDING = 20

export const PRODUCTS_VIEW_ITEM_DEFAULT_WIDTH_PX = 150
export const CONTINUE_SHOPPING_SECTION_ITEM_PRODUCT_PAGE_WIDTH_PX = 210

export const CART_ITEM_IMAGE_BORDER_WIDTH_PX = 1

export const PRODUCT_PAGE_REPLENISHMENT_PRODUCT_WIDTH_PX = 210

export const LOOKS_VIEW_ITEM_WIDTH_PX = 180
export const PRODUCTS_VIEW_ITEM_WIDTH_PX = 130
export const REPLENISHMENT_PRODUCT_VIEW_ITEM_WIDTH = '60vw'
export const COLLECTION_ITEM_WIDTH_PX = 100
export const COLLECTION_ITEM_IMAGE_ASPECT_RATIO = 0.8

export const SECTION_TYPES = {
    SINGLE: 'SINGLE',
    STRIP: 'STRIP',
    GRID: 'GRID',
    LIST: 'LIST',
    BOARD: 'BOARD'
}

export const COLLECTION_PAGE_PRODUCTS_PAGINATION_COUNT = 10
export const COLLECTION_PAGE_PRODUCT_ITEM_HEIGHT_PX = 215

export const ITEM_TYPES = {
    COLLECTION: 'COLLECTION',
    PRODUCT: 'PRODUCTS',
    LOOK: 'LOOKS'
}

export const ITEM_DESIGN_PATTERNS = {
    INSIDE: 'INSIDE',
    OUTSIDE: 'OUTSIDE'
}

export const ITEM_DETAILS_POSITION_GAP_PX = 15

export const CART_REQUESTED_LOOK_DEFAULT_PRODUCT_COUNT = 2

export const ITEMS_VIEW_SECTION_PAGINATION_THRESHOLD_HEIGHT_PX = 150
export const ITEMS_VIEW_SECTION_MORE_ITEMS_LOADER_HEIGHT_PX = 60

export const PRODUCT_ADDED_STRIP_DISPLAY_DELAY = 50

export const ITEM_DEFAULT_IMAGE_ASPECT_RATIO = 1

export const ON_CLOSE_DRAWER_HANDLER_HISTORY_BACK_DELAY_MILLISECONDS = 50

export const OPTION_TYPES = {
    COLOR: 'COLOR',
    TEXT: 'TEXT'
}

export const CART_PAGE_SCROLL_STATES = {
    TOP: 'TOP',
    SCROLLING: 'SCROLLING',
    MIDDLE: 'MIDDLE'
}

export const TOPBAR_ANIMATION_THRESHOLD_PX = 40
export const CART_PAGE_SCROLL_STOP_DELAY_MILLISECONDS = 500

export const SHOPBOARD_SOCIALS = {
    INSTAGRAM: 'instagram',
    TIKTOK: 'tiktok',
    PINTEREST: 'pinterest'
}

export const SHOPBOARD_POST_LIST_API_COUNT = 21
export const SHOPBOARD_POST_NUMBER_OF_FIRST_POSTS_TO_AUTOPLAY = 12

export const MEDIA_TYPES = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    ALBUM: 'ALBUM'
}

export const CART_MAX_WIDTH_DESKTOP_PX = 700

export const META_PIXEL_EVENTS = {
    VIEW_CONTENT: 'ViewContent',
    ADD_TO_CART: 'AddToCart',
    INITIATE_CHECKOUT: 'InitiateCheckout'
}

export const GOOGLE_ANALYTICS_EVENT = {
    PRODUCT_VIEW: {
        CATEGORY: 'page_view',
        ACTION: 'productview'
    },
    ADD_TO_CART: {
        CATEGORY: 'add_to_cart',
        ACTION: 'addtocart'
    },
    INITIATE_CHECKOUT: {
        CATEGORY: 'checkout',
        ACTION: 'requestcheckout',
        LABEL: 'checkout requested'
    },
}

export const BILLING_PLANS = {
    FREE: "FREE",
    STARTER: "STARTER",
    BUSINESS: "BUSINESS",
    PRO: "PRO",
}