import React, { useEffect } from 'react'
import './ProductOptionSelect.css'
import { calculateDistance3D, hexToRgb } from 'utils/utils'
import { COLOR_SELECT_WARM_COLOR_THRESHOLD } from 'constants/general.constants'
import { useBusinessPropertiesContext } from 'contexts/shopping'
import { BsCheck } from 'react-icons/bs'

/**
 * Represents a general option selector
 * @param {string} selectedOption - The state of the selected option
 * @param {function} setSelectedOption - A function for setting the state of the selected option
 * @param {object} optionCounter - The option values quantities object
 */
export default function ProductOptionSelect({
    optionName,
    selectedOption,
    setSelectedOption = () => { },
    optionCounter = {}
}) {
    const { 
        businessProperties,
        isOptionColor,
        getOptionValue
    } = useBusinessPropertiesContext()

    useEffect(() => {
        if (isOneValueOption()) { // meaning this option has only 1 value
            onOptionValueClick(Object.keys(optionCounter)[0])
        }
    }, [optionName, selectedOption])

    function isOneValueOption() {
        return Object.keys(optionCounter)?.length === 1
    }

    function onOptionValueClick(value) {
        setSelectedOption(value)
    }

    function isOptionValueSelected(value) {
        return selectedOption === value
    }

    function getSelectedColorBorderColor() {
        const whiteColorRGB = { r: 255, g: 255, b: 255 }
        const checkoutButtonBackgroundColorRGB = hexToRgb(businessProperties?.theme?.checkoutButtonBackgroundColor)
        const checkoutButtonBorderColorRGB = hexToRgb(businessProperties?.theme?.checkoutButtonBorderColor)

        if (!checkoutButtonBackgroundColorRGB || !checkoutButtonBorderColorRGB) {
            return 'black'
        }

        if (calculateDistance3D(whiteColorRGB, checkoutButtonBackgroundColorRGB) >= COLOR_SELECT_WARM_COLOR_THRESHOLD) {
            return businessProperties?.theme?.checkoutButtonBackgroundColor
        }

        if (calculateDistance3D(whiteColorRGB, checkoutButtonBorderColorRGB) >= COLOR_SELECT_WARM_COLOR_THRESHOLD) {
            return businessProperties?.theme?.checkoutButtonBorderColor
        }

        return 'black'
    }

    return (
        <div className={`product-option-select-container ${isOneValueOption() ? 'one-value' : ''}`}>
            {!isOneValueOption() && <div className='product-option-select-option-title'>{`${optionName}${selectedOption ? `: ${selectedOption}` : ''}`}</div>}
            <div className={`product-option-select-values ${isOptionColor(optionName) ? 'color' : ''}`}>
                {
                    isOptionColor(optionName) ?
                        Object.keys(optionCounter).length > 1 && Object.keys(optionCounter)?.map(optionValueName => <div
                            key={optionValueName}
                            className={`color-option-item ${isOptionValueSelected(optionValueName) ? 'selected' : ''} ${optionCounter[optionValueName] === 0 ? 'out-of-stock' : ''}`}
                            onClick={() => onOptionValueClick(optionValueName)}
                            style={{
                                backgroundColor: getOptionValue(optionName, optionValueName),
                                boxShadow: `0 0 0 2px white, 0 0 0 4px ${isOptionValueSelected(optionValueName) ? getSelectedColorBorderColor() : '#cccccc'}`
                            }}
                        >
                            {isOptionValueSelected(optionValueName) && <BsCheck className='color-option-item-selected-v-mark' />}
                        </div>) :
                        Object.keys(optionCounter).length > 1 && Object.keys(optionCounter)?.map(optionValueName => <div
                            key={optionValueName}
                            className={`product-option-select-item ${isOptionValueSelected(optionValueName) ? 'selected' : ''} ${optionCounter[optionValueName] === 0 ? 'out-of-stock' : ''}`}
                            onClick={() => onOptionValueClick(optionValueName)}
                            style={{
                                border: `${isOptionValueSelected(optionValueName) ? '2' : '1'}px solid ${isOptionValueSelected(optionValueName) ? getSelectedColorBorderColor() : '#cccccc'}`
                            }}
                        >{getOptionValue(optionName, optionValueName) ?? optionValueName}</div>)
                }
            </div>
        </div>
    )
}