import { getPrice } from 'utils/productUtils'
import { generateRandomInteger } from './utils'

/**
 * Extracts the base data of a product entity.
 * @param {object} product - The raw product given from the server
 * @param {string} currency - The currency of the product prices
 * @returns the parsed product
 */
export function extractProductData(product, currency) {

    return {
        key: product?.inventory_id,
        inventoryId: product?.inventory_id,
        imageUrl: product?.images && product?.images.length > 0 ? product?.images[0]?.medium?.src : null,
        images: product?.images ?? [],
        title: product?.title,
        ...getPrice(product?.variants, {}),
        options: product?.options,
        currency,
        quantity: null,
        variants: product?.variants,
        description: product?.description,
        handle: product?.handle
    }
}

/**
 * Extracts the base data of a collection entity.
 * @param {object} collection - The raw collection given from the server
 * @returns the parsed collection
 */
export function extractCollectionData(collection) {
    return {
        collectionId: collection?.collection_id ?? '',
        imageUrl: collection?.image ?? '',
        title: collection?.title ?? null
    }
}

/**
 * Extracts the base data of a look entity.
 * @param {object} look - The raw look given from the server
 * @returns the parsed look
 */
export function extractLookData(look) {
    return {
        linkId: look?.link_id ?? '',
        imageUrl: look?.link_image ?? '',
        createdAt: look?.created_at ?? null,
        productsCount: look?.products_count ?? 0
    }
}

/**
 * Extracts the base data of a section entity.
 * @param {object} section - The raw look given from the server
 * @returns the parsed section
 */
export function extractSectionData(section) {
    return {
        title: section?.title ?? '',
        sectionType: section?.section_type ?? '',
        itemsIds: section?.items_ids ?? [],
        itemType: section?.item_type ?? '',
        itemImageWidth: section?.item_image_width ?? '',
        itemImageRatio: section?.item_image_ratio ?? 1
    }
}

/**
 * Extracts the data of a link product which is not added to the cart.
 * @param {object} linkProduct - The given product's object
 * @returns a data object of the given product
 */
export function extractLinkProductDetails(linkProduct) {
    return {
        key: linkProduct.inventoryId,
        ...linkProduct,
        productOptions: linkProduct?.options,
        variantOptions: null,
        variantId: null,
        increaseQuantity: () => { },
        decreaseQuantity: () => { },
        removeItem: () => { },
    }
}

/**
 * Extracts the data of a link product which is not added to the cart.
 * @param {object} cartItem - The given product's object
 * @returns a data object of the given product
 */
export function extractCartItemDetails(cartItem, currency, product, decreaseQuantity = () => { }, addToCartClicked = () => { }) {
    return {
        key: generateRandomInteger(1_000_000),
        variantId: cartItem.variant_id,
        inventoryId: cartItem.product_inventory_id,
        imageUrl: cartItem.main_image?.src,
        images: [cartItem.main_image],
        title: cartItem.title,
        price: cartItem.price,
        productOptions: null,
        variantOptions: cartItem.options,
        originalPrice: cartItem.original_price,
        currency: currency,
        quantity: cartItem.quantity,
        removeItem: () => decreaseQuantity(cartItem?.variant_id, cartItem?.quantity, cartItem?.quantity),
        variants: product?.variants,
        onAddToCartButtonClick: addToCartClicked
    }
}

export function extractPostData(post) {
    return {
        id: post?.post_id ?? '',
        createAt: post?.posted_at ?? '',
        embedHtml: post?.embed_html ?? '',
        numberOfProducts: post?.number_of_products ?? 0,
        thumbnailImageUrl: post?.thumbnail_url ?? '',
        mediaUrls: post?.media_urls ?? [],
        mediaType: post?.media_type ?? '',
        iframeUrl: post?.iframe_url ?? '',
        mediaHeight: post?.height ?? null,
        mediaWidth: post?.width ?? null
    }
}