const debug = {
    terminal_name: "paydin",
    response_language: "english",
    base_url: "http://127.0.0.1:5001/",
    site_url: `https://dev.shopeaks.com`,
}

const dev = {
    terminal_name: "paydin",
    response_language: "english",
    base_url: `https://api-dev.payli.in/`,
    site_url: `https://dev.shopeaks.com`,
}

const staging = {
    terminal_name: "paydin",
    response_language: "english",
    base_url: `https://api-staging.payli.in/`,
    site_url: `https://staging.shopeaks.com`,
}

const prod = {
    terminal_name: "paydin",
    response_language: "english",
    base_url: `https://api.payli.in/`,
    site_url: `https://shopeaks.com`,
}

export const environment = (() => {
    switch (process.env.REACT_APP_STAGE) {
        case "debug":
            return debug;
        case "dev":
            return dev;
        case "staging":
            return staging;
        case "prod":
            return prod;
        default:
            return null;
    }
})();