import React from 'react'
import './ImageDialog.css'
import PaydinDialog from 'dialog/PaydinDialog/PaydinDialog'
import ImageBox from 'components/ImageBox/ImageBox'
import { useHistoryContext } from 'contexts/History'
import { useTranslation } from 'react-i18next'

/**
 * Represents a dialog which shows an image.
 * @param {boolean} isDialogOpen - Determins whether the dialog is open or not 
 * @param {function} handleDialogClose -A function for closing the dialog
 * @param {string} image - The image url of the image
 */
export default function ImageDialog({
    isDialogOpen,
    handleDialogClose,
    image
}) {
    const { t } = useTranslation()

    const history = useHistoryContext()

    function closeDialog() {
        if (handleDialogClose)
            handleDialogClose()
        history.goBack()
    }

    return (
        <PaydinDialog
            isDialogOpen={isDialogOpen}
            handleDialogClose={handleDialogClose}
            borderRadiusPx={10}
            maxHeight='100vh'
            maxWidth='100vw'
            hasNoHeightLimit={true}
            backgroundColor='black'
            paddingPx={0}
            margin={0}
            borderRadius='0'
            styles={{ overflow: 'hidden' }}
            isFullscreen={true}
            dialogLayout={<>
                <div className='image-dialog-close-button' onClick={closeDialog}>{t('IMAGE_DIALOG_CLOSE_BUTTON_TEXT')}</div>
                <ImageBox
                    image={image}
                    className='image-dialog-image-container'
                    supportZoom={true}
                    showImageLoader={true}
                    enlargeImageOnClick={false}
                />
            </>}
        />
    )
}