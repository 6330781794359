import React from 'react';
import './AlertModal.css';
import { useTranslation } from "react-i18next";
import { Modal } from '@mui/material';

export default function AlertModal({ msg }) {
    const { t } = useTranslation();
    return (
        <>
            <Modal open={true}>
                <div className='alert-modal-content'>
                    <div dir={t("direction.dir")} className='modal-body-message'>{msg}</div>
                </div>
            </Modal>
        </>
    )
}