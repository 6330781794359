export const REQUEST_HEADER_AUTH_KEY = 'Authorization'
export const AUTH_REQUEST_URL = 'authentication/request'
export const OTP_VERIFY_URL = 'authentication/verify/otp'
export const APPLY_PROMOCODE_REQUEST_URL = '/shop/cart/promocode'
export const CHECK_CART_URL = 'shop/cart/check'
export const FETCH_CART_URL = 'shop/cart/fetch'
export const FETCH_SALE_SUMMARY_URL = 'sales/summary'
export const CHECK_PRODUCT_URL = 'product/check'
export const GET_RELATED_PRODUCT_DETAILS_URL = 'product/related'
export const GET_PRODUCTS_DETAILS_URL = 'product/fetch-batch'
export const NOTIFY_VIEW_PRODUCT_API_PRODUCT_ROUTE = '/product'
export const GET_LOOK_PRODUCTS_API_PRODUCTS_ROUTE = '/products'
export const GET_POST_PRODUCTS_API_PRODUCTS_ROUTE = '/products'
export const GET_POST_SECTIONS_API_PRODUCTS_ROUTE = '/sections'
export const NOTIFY_VIEW_PRODUCT_API_VIEW_ROUTE = '/view'
export const START_SESSION_API_ROUTE = '/session'
export const SHOPBOARD_URL_ROUTE = '/shopboard'
export const POSTS_URL_ROUTE = '/posts'

export const START_SHOPPING_URL = 'shopping'
export const ADD_PRODUCT_CART_URL_SUFFIX = '/add'
export const REMOVE_PRODUCT_URL_SUFFIX = '/remove'
export const FETCH_CUSTOMER_INFO_URL = 'customer/details'
export const UPDATE_CUSTOMER_ADDRESS_INFO_URL = 'customer/update-address'
export const FETCH_CUSTOMER_FAVORITES_URL = 'customer/favorites/fetch'
export const ADD_CUSTOMER_FAVORITE_URL = 'customer/favorites/add'
export const REMOVE_CUSTOMER_FAVORITE_URL = 'customer/favorites/remove'
export const PAYMENT_REQUEST_URL = 'payment/request'
export const INSPIREME_REQUEST_URL = 'stories'
export const COMMIT_LOGS_URL = 'api/logs/commit'
export const FETCH_STORE_SALESMEN_URL = '/salesmen/fatch-salesmen'
export const UPDATE_SALESMAN_IN_CART_URL = '/salesmen/update-in-cart'
export const GET_SHOES_URL = 'shoe/fetch-shoe'
export const IMAGE_GET_SHOES_URL = 'shoe/image-fetch-shoe'
export const UPLOAD_SHOE_IMAGE_URL = 'shoe/upload-shoe-image'
export const GET_LONG_URL_FROM_SHORT_REQUEST_URL = 'short-url/get-long-url'

export const SHOP_API_URL = 'shop'
export const SHOP_API_COLLECTIONS_ROUTE = '/collections'
export const SHOP_API_SENSE_ROUTE = '/sense'
export const SHOP_API_LINKS_ROUTE = '/links'
export const SHOP_API_LINK_ROUTE = '/link'
export const SHOP_API_SECTIONS_ROUTE = '/sections'
export const SHOP_API_PRODUCTS_SUFFIX = '/products'