import React from 'react'
import './ReplenishmentProductItem.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { formatNumber, hasDiscount } from 'utils/utils'
import { getCurrencySymbol } from 'services/Currency'
import { useHistoryContext } from 'contexts/History'
import { getUsername, useGeneralContext, useLinkDetailsContext } from 'contexts/shopping'
import { INVENTORY_ID_KEY, PRODUCT_PAGE_URL } from 'constants/routes.constants'

/**
 * Represents a substitue product for sold out products.
 * @param {string} imageUrl - The image url of this product
 * @param {string} title - The title of this product
 * @param {number} price - The price of this product
 * @param {string} currency - The currency of this product
 */
export default function ReplenishmentProductItem({
    item,
    onItemClick = () => { }
}) {
    const history = useHistoryContext()
    const { linkDetails } = useLinkDetailsContext()
    const { setShoppingData } = useGeneralContext()

    function onReplenishmentProductClick() {
        openProductPage()
        onItemClick()
    }

    function openProductPage() {
        if (item?.inventoryId) {
            history.push(`${window.basePath}/${getUsername()}/${linkDetails?.linkId}?${INVENTORY_ID_KEY}=${item?.inventoryId}`);
        }
    }

    return (
        <div className='replenishment-product-item' onClick={onReplenishmentProductClick}>
            <div className="replenishment-product-item-image-container">
                <ImageBox
                    image={item?.imageUrl}
                    className="replenishment-product-item-image"
                    isImageFitCover={true}
                    showImageBorderRadius={true}
                />
            </div>
            <div className="replenishment-product-item-details">
                <div className="replenishment-product-item-title allow-2-lines">{item?.title}</div>
                <div className="replenishment-product-item-price-container">
                    <div className="replenishment-product-item-price">{getCurrencySymbol(item?.currency)}{formatNumber(item?.price)}</div>
                    {hasDiscount(item?.price, item?.originalPrice) && <div className="replenishment-product-item-original-price line-through-text">{getCurrencySymbol(item?.currency)}{formatNumber(item?.originalPrice)}</div>}
                </div>
            </div>
        </div>
    )
}