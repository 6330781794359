import React from 'react'
import './ItemsView.css'
import { isMobile } from 'react-device-detect'

/**
 * Represents an item container view.
 * It can display a list of items in a sliding row or a grid.
 * @param {array} items - The array of items to display
 * @param {string} width - The width for each rendered item
 * @param {string} gap - The space between each item
 * @param {boolean} hasPadding - If true, adds horizontal padding at both sides of the item container view
 * @param {boolean} displayAsGrid - If true, displays the items in a grid, otherwise, displays them in a sliding row
 * @param {number} columnsCount - Defines the number of columns for this view, only when displayAsGrid = true, otherwise, ignores this prop
 */
export default function ItemsView({
    items,
    width = '0px',
    gap = '10px',
    displayAsGrid = false,
    hasPadding = true,
    verticalPadding = '10px',
    columnsCount = 'auto-fit'
}) {
    return (
        <div className={`${displayAsGrid ? 'items-view-container-grid' : 'items-view-container-slider'} ${!isMobile ? 'show-scrollbar' : ''}`} style={{
            ...(!displayAsGrid ? { gap } : {}),
            ...(displayAsGrid ? { gridTemplateColumns: `repeat(${columnsCount}, minmax(${width}, 1fr))` } : {}),
            paddingInline: hasPadding ? '20px' : '0px',
            paddingTop: verticalPadding,
            paddingBottom: verticalPadding
        }}>
            {items}
        </div>
    )
}