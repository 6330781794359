import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { calculateComplementaryPercentage, formatNumber, hasDiscount, isObjectEmpty } from 'utils/utils'
import './CartItem.css'
import { useHistoryContext } from 'contexts/History'
import { INVENTORY_ID_KEY, SELECTED_OPTIONS_OPTION_KEY } from 'constants/routes.constants'
import parse from 'html-react-parser';
import ImageBox from 'components/ImageBox/ImageBox'
import {
    getUsername,
    useBusinessPropertiesContext,
    useGeneralContext,
    useLinkDetailsContext
} from 'contexts/shopping'
import ColorSelect from 'components/ColorSelect/ColorSelect'
import { getCurrencySymbol } from 'services/Currency'
import {
    CART_ITEM_IMAGE_BORDER_WIDTH_PX,
    COLOR_SELECT_CIRCLE_CONTAINER_GAP,
    COLOR_SELECT_CIRCLE_DIMENSIONS,
    COLOR_SELECT_MORE_COLORS_BUTTON_PADDING_START_PX,
    SECTION_TYPES,
    REPLENISHMENT_PRODUCT_VIEW_ITEM_WIDTH,
    CART_ITEM_IMAGE_DEFAULT_RATIO
} from 'constants/general.constants'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { getVariantQuantity, hasItemIdInStock, hasItemsInStock } from 'utils/variantUtils'
import CollapsingElement from 'components/CollapsingElement/CollapsingElement'
import ShowHideSimilarButtonIcon from 'imageComponents/ShowHideSimilarButtonIcon'
import ItemsViewSection from 'components/ItemsViewSection/ItemsViewSection'
import ReplenishmentProductItem from 'components/ReplenishmentProductItem/ReplenishmentProductItem'
import { getPrice } from 'utils/productUtils'

/**
 * Represents a cart item row inside the cart display in the home screen.
 * @param {string} variant_id - The item's varinat_id
 * @param {string} imageUrl - The image url of the item
 * @param {string} title - The title of the item
 * @param {string} size - The size of the item
 * @param {number} price - The price of the item
 * @param {string} currency - The currency of the store
 * @param {number} quantity - The quantity of the item
 * @param {function} increaseQuantity - A callback for clicking the + button to increase the item's quantity
 * @param {function} decreaseQuantity - A callback for clicking the - button to decrease the item's quantity
 * @param {function} removeItem - A callback for clicking the - button to remove the item
 */
export default function CartItem({
    variantId,
    inventoryId,
    imageUrl,
    images,
    title,
    price,
    originalPrice,
    variantOptions,
    productOptions,
    currency,
    quantity,
    variants = [],
    removeItem = () => { },
    onAddToCartButtonClick = () => { },
    isInCartItem = false,
    route = '',
    routeQueries = '',
    imageAspectRatio = null,
    preselected_options = {},
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { linkDetails } = useLinkDetailsContext()
    const {
        shoppingData,
        findProductInContext,
        setShoppingData
    } = useGeneralContext()
    const {
        businessProperties,
        isOptionColor,
        getOptionValue,
        hasHexColor
    } = useBusinessPropertiesContext()

    
    const [itemPrice, setItemPrice] = useState({
        price,
        originalPrice
    })

    const [selectedColor, setSelectedColor] = useState('')
    const [colorOption, setColorOption] = useState(null)
    const [displayImage, setDisplayImage] = useState(imageUrl)
    const [colorSelectDisplayedColorsCount, setColorSelectDisplayedColorsCount] = useState(0)
    const [areReplenishmentProductsShown, setAreReplenishmentProductsShown] = useState(false)
    const [replenishmentProducts, setReplenishmentProducts] = useState([])

    const cartItemDetailsRef = useRef(null)

    const colorCounter = useMemo(() => getColors(), [])

    useEffect(() => {
        if (!variantId) {
            const colorOption = productOptions?.find(option => isOptionColor(option?.name))
            setColorOption(colorOption)
            
            if (colorOption && preselected_options && Object.keys(preselected_options).includes(colorOption?.name) && Object.keys(colorCounter).includes(preselected_options[colorOption?.name])) {
                if (colorCounter[preselected_options[colorOption?.name]] > 0) {
                    setSelectedColor(preselected_options[colorOption?.name])
                }
            }
            
            if (cartItemDetailsRef?.current) {
                setColorSelectDisplayedColorsCount(Math.floor((cartItemDetailsRef?.current?.clientWidth - COLOR_SELECT_MORE_COLORS_BUTTON_PADDING_START_PX) / (COLOR_SELECT_CIRCLE_DIMENSIONS + COLOR_SELECT_CIRCLE_CONTAINER_GAP)))
            }

            if (Object.keys(shoppingData?.productsReplenishments).includes(inventoryId)) {
                setReplenishmentProducts(shoppingData?.productsReplenishments[inventoryId]?.map(productInventoryId => findProductInContext(productInventoryId)))
            }
        }
    }, [])

    useEffect(() => {
        if (colorOption && colorOption?.name && selectedColor) {
            setItemPrice({...getPrice(variants, colorOption ? { [colorOption?.name]: selectedColor } : {})})
        }
    }, [selectedColor])

    useEffect(() => {
        if (!variantId) {
            variants?.forEach(variant => {
                if (variant?.options[colorOption?.name] === selectedColor) {
                    setDisplayImage(images && images.length > 0 ? images[variant?.image_position]?.medium?.src : imageUrl)
                }
            })
        }
    }, [selectedColor])

    function navigateToProductPage() {
        if (inventoryId) {
            if (linkDetails?.linkId != 'shopboard'){
                history.push(`${window.basePath}/${getUsername()}/${linkDetails?.linkId}${route}?${routeQueries ? `${routeQueries}&` : ''}${INVENTORY_ID_KEY}=${inventoryId}${getSelectedOptionsQueryParameter()}`);
            } else {
                history.push(`${window.basePath}/${getUsername()}/${route}?${routeQueries ? `${routeQueries}&` : ''}${INVENTORY_ID_KEY}=${inventoryId}${getSelectedOptionsQueryParameter()}`);
            }
        }
    }

    function getSelectedOptionsQueryParameter() {
        if (variantId && hasOptions() && Object.entries(variantOptions).length > 0) {
            const optionString = Object.entries(variantOptions).map(([optionName, optionValue], index) => `${[optionName]}:${optionValue}` ).join(',')
            return `&${SELECTED_OPTIONS_OPTION_KEY}=${optionString}`
        }

        if (!selectedColor) {
            return ''
        }

        return `&${SELECTED_OPTIONS_OPTION_KEY}=${[colorOption?.name]}:${selectedColor}`
    }

    function hasOptions() {
        return !isObjectEmpty(variantOptions)
    }

    function getColors() {
        const colorOption = productOptions?.find(option => isOptionColor(option?.name))
        if (!colorOption) {
            return []
        }

        const colorCounter = {}
        colorOption?.values?.forEach(value => colorCounter[value] = 0)

        variants?.forEach(variant => {
            colorCounter[variant?.options[colorOption?.name]] += getVariantQuantity(variant?.quantity, variant?.inventory_policy)
        })

        return colorCounter
    }

    function renderSelectedOptions() {
        return <>
            {
                Object.entries(variantOptions).length > 0 && Object.entries(variantOptions).map(([optionName, optionValue], index) => {
                    if (optionName.toLowerCase() !== "title") { // Avoid default title options
                        return (<div key={optionName + optionValue} className='cart-item-option-color'>
                            {(isOptionColor(optionName) && hasHexColor(optionName, optionValue)) && <div className="cart-item-option-color-circle" style={{ backgroundColor: getOptionValue(optionName, optionValue) }}></div>}
                            <div className="cart-item-option-value">{getOptionValue(optionName, optionValue, true)}</div>
                            {(index !== Object.entries(variantOptions).length -1) && <>/</>}
                        </div>)
                    } else {
                        return null
                    }
                })
            }
        </>
    }

    function renderItemCount() {
        return <div className='cart-item-count'>{t('CART_ITEM_COUNT_DESCRIPTION', { count: quantity })}</div>
    }
    
    function renderItemPreorder() {
        if (variantId && businessProperties?.theme?.isPreorderTextShown && !hasItemIdInStock(variants, variantId, true)) {
          return <div className='cart-item-preorder'>{t('CART_ITEM_PREORDER_TAG_TEXT')}</div>
        }
        return null
    }

    function hasColors() {
        return Object.keys(colorCounter)?.length > 1
    }

    function showSimilarProducts() {
        setAreReplenishmentProductsShown(prev => !prev)
    }

    function shouldDisplayColorSelect() {
        return !variantId && hasColors()
    }

    const discountStyles = {
        borderRadius: businessProperties?.theme?.productDiscountTagBorderRadius ?? 0,
        borderColor: businessProperties?.theme?.productDiscountTagBorderColor ?? 'firebrick',
        backgroundColor: businessProperties?.theme?.productDiscountTagBackgroundColor ?? 'white',
        color: businessProperties?.theme?.productDiscountTagFontColor ?? 'firebrick',
    }

    return (
        <div dir={t('direction.dir')} className='cart-item-container'>
            <div className={`cart-item-content ${isInCartItem && 'in-cart-item'}`}>
                <div style={{ aspectRatio: `${imageAspectRatio ?? CART_ITEM_IMAGE_DEFAULT_RATIO}`}} className={`cart-item-image-container ${isInCartItem && 'in-cart-item'}`}>
                    <ImageBox
                        className='cart-item-image'
                        image={displayImage}
                        showImageLoader={true}
                        onImageClickCallback={navigateToProductPage}
                        showGreyLoaderBackground={true}
                        isImageFitCover={true}
                        showImageBorderRadius={true}
                        border={`${CART_ITEM_IMAGE_BORDER_WIDTH_PX}px solid #ececec`}
                        borderRadius={`${businessProperties?.theme?.imageBorderRadius}px`}
                    />
                    {(!variantId && !hasItemsInStock(variants)) && <div className="cart-item-sold-out-strip" style={{
                        borderBottomLeftRadius: `${businessProperties?.theme?.imageBorderRadius - CART_ITEM_IMAGE_BORDER_WIDTH_PX}px`,
                        borderBottomRightRadius: `${businessProperties?.theme?.imageBorderRadius - CART_ITEM_IMAGE_BORDER_WIDTH_PX}px`
                    }}>{t('OUT_OF_STOCK_TEXT')}</div>}
                </div>
                <div ref={cartItemDetailsRef} className="cart-item-details-container" style={{ justifyContent: businessProperties?.theme?.addToCartButtonPlacedBottom ? 'space-between' : 'start' }}>
                    <div className="cart-item-details-upper-section" style={{ height: shouldDisplayColorSelect() && '90px', justifyContent: shouldDisplayColorSelect() && 'space-between' }}>
                        <div className="cart-item-title-price-container">
                            <div className={`cart-item-title ${isInCartItem && 'in-cart-item'} allow-3-lines`} onClick={navigateToProductPage}>{parse(title)}</div>
                            <div className='cart-item-prices-container'>
                                {
                                    hasDiscount(itemPrice?.price, itemPrice?.originalPrice) && <div dir={t('direction.dir')} className={`cart-item-original-price ${isInCartItem && 'in-cart-item'} line-through-text`}>{getCurrencySymbol(currency)}{formatNumber(variantId ? itemPrice?.originalPrice * quantity : itemPrice?.originalPrice)}</div>
                                }
                                <div 
                                    className={`cart-item-price ${isInCartItem && 'in-cart-item'}`} 
                                    onClick={navigateToProductPage}
                                    style={hasDiscount(itemPrice?.price, itemPrice?.originalPrice) ? {color: businessProperties?.theme?.productDiscountFinalPriceFontColor} : {}}
                                >
                                    {getCurrencySymbol(currency)}{formatNumber(variantId ? itemPrice?.price * quantity : itemPrice?.price)}
                                </div>
                                {
                                    hasDiscount(itemPrice?.price, itemPrice?.originalPrice) && <div dir={"ltr"} className={`cart-item-discount-percentage-tag ${isInCartItem && 'in-cart-item'}`} style={discountStyles}>{t('CART_CONTINUE_SHOPPING_SECTION_DISCOUNT_PERCENTAGE', { discountPercentage: calculateComplementaryPercentage(itemPrice?.price, itemPrice?.originalPrice) })}</div>
                                }
                            </div>
                        </div>
                        {hasOptions() && <div className="cart-item-size-row" onClick={navigateToProductPage}>
                            <div dir={t('direction.dir')} className="cart-item-options" style={{ visibility: hasOptions() ? 'visible' : 'hidden' }}>{renderSelectedOptions()}</div>
                        </div>}
                        {variantId && renderItemCount()}
                        {variantId && renderItemPreorder()}
                        {
                            shouldDisplayColorSelect() && <div className='cart-item-color-select-container'>
                                <ColorSelect
                                    optionName={colorOption?.name}
                                    selectedColor={selectedColor}
                                    setSelectedColor={setSelectedColor}
                                    colorCounter={colorCounter}
                                    colorCircleDimensionsPx={COLOR_SELECT_CIRCLE_DIMENSIONS}
                                    onMoreColorsButtonClick={
                                        variantId ?
                                            () => onAddToCartButtonClick(inventoryId, {}) :
                                            () => onAddToCartButtonClick(inventoryId, selectedColor ? { [colorOption?.name]: selectedColor } : {})
                                    }
                                    colorSelectDisplayedColorsCount={colorSelectDisplayedColorsCount}
                                />
                            </div>
                        }
                    </div>
                    <div className="cart-item-details-lower-section" style={{ marginTop: !variantId && '17px' }}>
                        <div className='cart-item-add-to-cart-more-info-container'>
                            {
                                variantId ? <>
                                    <div className={`cart-item-add-one-more-button ${isInCartItem && 'in-cart-item'}`} onClick={() => onAddToCartButtonClick(inventoryId, {})}>{t(businessProperties?.theme?.useFeminineLanguage ? 'CART_ADD_ONE_MORE_BUTTON_F' : 'CART_ADD_ONE_MORE_BUTTON')}</div>
                                    <RiDeleteBin6Line className="cart-item-remove-item-button" onClick={removeItem} />
                                </> : <>
                                    {
                                        hasItemsInStock(variants) ?
                                            <div className={`cart-item-add-to-cart-button ${isInCartItem && 'in-cart-item'}`} onClick={() => onAddToCartButtonClick(inventoryId, selectedColor ? { [colorOption?.name]: selectedColor } : {})}>{t(businessProperties?.theme?.useFeminineLanguage ? 'CART_ADD_TO_CART_BUTTON_F' : 'CART_ADD_TO_CART_BUTTON')}</div> :
                                            (
                                                replenishmentProducts?.length > 0 && <div className='cart-item-shop-similar-button' onClick={showSimilarProducts}>
                                                    <ShowHideSimilarButtonIcon className="cart-item-shop-similar-button-image" />
                                                    <div className="cart-item-shop-similar-button-text">{areReplenishmentProductsShown ? t('CART_HIDE_SIMILAR_BUTTON') : t('CART_SHOW_SIMILAR_BUTTON')}</div>
                                                </div>
                                            )
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                replenishmentProducts?.length > 0 && <CollapsingElement
                    expanded={areReplenishmentProductsShown}
                >
                    <ItemsViewSection
                        data={replenishmentProducts}
                        type={SECTION_TYPES.STRIP}
                        width={REPLENISHMENT_PRODUCT_VIEW_ITEM_WIDTH}
                        viewItemComponent={ReplenishmentProductItem}
                    />
                </CollapsingElement>
            }
        </div>
    )
}