import { INVENTORY_POLICY_OPTIONS } from "constants/general.constants"

/**
 * Returns the given variant's quantity regrding its inventory policy.
 * @param {number} variantOriginalQuantity - The given variant's original quantity
 * @param {string} variantInventoryPolicy - The given variant's inventory policy, can be either INVENTORY_POLICY_OPTIONS.CONTINUE or INVENTORY_POLICY_OPTIONS.DENY
 */
export function getVariantQuantity(variantOriginalQuantity, variantInventoryPolicy) {
    return (variantInventoryPolicy === INVENTORY_POLICY_OPTIONS.CONTINUE ? 1 : Math.max(variantOriginalQuantity, 0))
}

/**
 * Checks whether there are items in stock for the given variants collection.
 * @param {array} variants - The variants array to check for items in stock.
 * @returns true if there are items in stock, false otherwise.
 */
export function hasItemsInStock(variants, ignorInventoryPolicy=false) {
    let hasItemsInStock = false

    if (variants) {
        for (const variant of variants) {
            if (getVariantQuantity(variant?.quantity, (ignorInventoryPolicy ? INVENTORY_POLICY_OPTIONS.DENY : variant?.inventory_policy)) > 0) {
                hasItemsInStock = true
                break
            }
        }
    }

    return hasItemsInStock
}


/**
 * given variants collection and a variantId returns the quantity
 * @param {array} variants - The variants array to check for items in stock.
 * @returns true if there are items in stock, false otherwise.
 */
export function hasItemIdInStock(variants, variantId, ignorInventoryPolicy=false) {
    if (variants) {
        for (const variant of variants) {
            if (variant.variant_id === variantId && getVariantQuantity(variant?.quantity, (ignorInventoryPolicy ? INVENTORY_POLICY_OPTIONS.DENY : variant?.inventory_policy)) > 0) {
                return true
            }
        }
    }

    return false
}