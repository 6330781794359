import { request } from 'services/RequestWrapper';
import { environment } from "conf";
import { COMMIT_LOGS_URL } from 'constants/api.constant';

export function CommitLogsAPI(logs, username, cart_id) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username,
            },
            body: {
                "logs": logs,
                "cart_id": cart_id,
            },
        }
        request(environment.base_url + COMMIT_LOGS_URL, options)
            .then(resolve)
            .catch(reject)
    });
}