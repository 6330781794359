import React from 'react'
import './ItemsViewSection.css'
import { useTranslation } from 'react-i18next'
import { 
    SECTION_TYPES, 
    ITEM_DESIGN_PATTERNS, 
    PRODUCTS_VIEW_ITEM_WIDTH_PX, 
    ITEMS_VIEW_SECTION_MORE_ITEMS_LOADER_HEIGHT_PX
} from 'constants/general.constants'
import ItemsView from 'components/ItemsView/ItemsView'
import { generateRandomInteger } from 'utils/utils'
import Loader from 'components/Loader/Loader'

/**
 * Represetns an item container view wrapper which contains a title and
 * a general item view with items to display
 * @param {string} title - The title for this container view
 * @param {array} data - The data for display in this section. It contains an array of data objects.
 * @param {string} itemWidth - The width of each item in this section
 * @param {string} itemAspectRatio - The aspect ratio of each item/item's image in this section
 * @param {string} gap - The gap between each element in this section, ignores this when SECTION_TYPES.SINGLE
 * @param {string} type - The section type for this section, can accepts either SECTION_TYPES.SINGLE, SECTION_TYPES.GRID or SECTION_TYPES.STRIP
 * @param {component} viewItemComponent - A general item view component for displaying the items 
 * @param {string} bgColor - The background color for this view container
 * @param {boolean} isLoadingItems - Determins whether the items' data are being loaded or not
 * @param {boolean} isLoadingMoreItems - Determins whether the items' data are being loaded as part of pagination or not
 * @param {function} onItemClick - A function to perform when clicking on an item
 * @param {ref} sectionRef - A reference to this section's container
 * @param {boolean} hasPadding - If true, adds horizontal (left & right) padding to this item view container
 * @param {number} columnsCount - Defines the number of columns for this view, only when type = SECTION_TYPES.GRID, otherwise, ignores this prop
 */
export default function ItemsViewSection({
    title,
    data,
    itemWidth = `${PRODUCTS_VIEW_ITEM_WIDTH_PX}px`,
    itemAspectRatio = 1,
    gap = '10px',
    type = SECTION_TYPES.STRIP,
    viewItemComponent: ViewItemComponent,
    bgColor = 'white',
    isLoadingItems = false,
    isLoadingMoreItems = false,
    onItemClick = () => {},
    sectionRef = null,
    hasPadding = true,
    columnsCount = 'auto-fit',
    titleFontSize = '15px',
    isItemSelected = itemId => { },
    verticalPadding = '10px',
    noPaddingStartTitle = false,
}) {
    const { t } = useTranslation()

    return (
        <div ref={sectionRef} dir={t('direction.dir')} className='items-view-section-container' style={{ backgroundColor: bgColor }}>
            {title && <div className="items-view-section-title" style={noPaddingStartTitle ? {padding: 0} : {}}>{title}</div>}
            {
                (type === SECTION_TYPES.SINGLE && data && data?.length > 0) ? <>
                    <ViewItemComponent
                        item={data[0]}
                        onItemClick={onItemClick}
                        width={itemWidth}
                        aspectRatio={itemAspectRatio}
                        designPattern={ITEM_DESIGN_PATTERNS.INSIDE}
                        hasPadding={true}
                        sectionType={SECTION_TYPES.SINGLE}
                        titleFontSize={'25px'}
                    />
                </> : <>
                    {
                        isLoadingItems && <div className="items-view-section-loader-container">
                            <Loader styles={{
                                height: '30px',
                                width: '30px',
                                position: 'absolute',
                                zIndex: 90,
                                top: 0,
                                left: 0,
                                right: 0,
                                margin: '20% auto 0 auto'
                            }} />
                        </div>
                    }
                    <ItemsView
                        gap={gap}
                        displayAsGrid={type === SECTION_TYPES.GRID}
                        width={itemWidth}
                        hasPadding={hasPadding}
                        verticalPadding={verticalPadding}
                        columnsCount={columnsCount}
                        items={<>
                            {data && data?.map(item => <ViewItemComponent
                                key={generateRandomInteger(1000)}
                                item={item}
                                isItemSelected={isItemSelected}
                                onItemClick={onItemClick}
                                width={itemWidth}
                                aspectRatio={itemAspectRatio}
                                designPattern={ITEM_DESIGN_PATTERNS.OUTSIDE}
                                sectionType={type}
                                titleFontSize={titleFontSize}
                            />)}
                        </>}
                    />
                    {
                        isLoadingMoreItems && <div className="items-view-section-more-items-loader-loader-container" style={{ height: `${ITEMS_VIEW_SECTION_MORE_ITEMS_LOADER_HEIGHT_PX}px` }}>
                            <Loader styles={{
                                height: '25px',
                                width: '25px'
                            }} />
                        </div>
                    }
                </>
            }
        </div>
    )
}