import React from 'react'
import './CartSection.css'
import CartItem from 'components/CartItem/CartItem'
import { useTranslation } from 'react-i18next'
import { generateRandomInteger } from 'utils/utils'
import Loader from 'components/Loader/Loader'

/**
 * Represents a main cart section, displaying a title and its content items.
 * @param {string} title - The title of this section
 * @param {array} items - The content items of this section
 * @param {array} isInCartItems - If in cart
 * @param {array} route - the route when sending to product page
 * @param {array} routeQueries - the route queries when sending to product page
 * @param {array} isLoadingItems - is loading items
 * @param {array} loaderOnStart - Should show loader when items length = 0
 */
export default function CartSection({
    title = '',
    items = [],
    isInCartItems = false,
    route = '',
    routeQueries = '',
    isLoadingItems = false,
    loaderOnStart = false,
    itemImageAspectRatio = null,
    selectedOptions = {},
}) {
    const { t } = useTranslation()

    return (
        <div className='cart-section-container'>
            {(items.length > 0 || (isLoadingItems && loaderOnStart)) && <div dir={t('direction.dir')} className='cart-section'>
                {title && <div className="cart-section-title">{title}</div>}
                {
                    isLoadingItems ? <div className="cart-section-loader-container">
                            <Loader styles={{
                                height: '30px',
                                width: '30px',
                                position: 'absolute',
                                zIndex: 90,
                                top: 0,
                                left: 0,
                                right: 0,
                                margin: '20% auto 0 auto'
                            }} />
                        </div>
                    : <div className={`cart-section-items`}>
                        {
                            items.map(item => <CartItem
                                key={generateRandomInteger(1_000_000)}
                                variantId={item.variantId}
                                inventoryId={item.inventoryId}
                                imageUrl={item.imageUrl}
                                images={item.images}
                                title={item.title}
                                price={item.price}
                                originalPrice={item.originalPrice}
                                options={item.options}
                                productOptions={item.productOptions}
                                variantOptions={item.variantOptions}
                                currency={item.currency}
                                quantity={item.quantity}
                                variants={item.variants}
                                removeItem={item.removeItem}
                                onAddToCartButtonClick={item.onAddToCartButtonClick}
                                isInCartItem={isInCartItems}
                                route={route}
                                routeQueries={routeQueries}
                                imageAspectRatio={itemImageAspectRatio}
                                preselected_options={selectedOptions && Object.keys(selectedOptions).includes(item.inventoryId) ? selectedOptions[item.inventoryId] : {}}
                            />)
                        }
                    </div>
                }           
            </div>}
        </div>
    )
}
