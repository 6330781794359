import {
    getUsername
} from 'contexts/shopping';
import LoaderPage from '../LoaderPage/LoaderPage';
import './Splash.css'
import { CLOUD_APPLICATION_RESOURCES_BASE_URL, CLOUD_IMAGES_ROUTE, SPLASH_PAGE_DEFAULT_ANIMATED_LOADER_STRING } from 'constants/general.constants';
import { useEffect, useState } from 'react';
import ImageBox from 'components/ImageBox/ImageBox';
import { removeStarterLogo } from 'utils/elementUtils';

/**
 * Represents the splash screen (first screen of the app).
 */
export default function Splash() {
    const [hasBusinessImage, setHasBusinessImage] = useState(true)

    function buildBusinessImageUrl() {
        return `${CLOUD_APPLICATION_RESOURCES_BASE_URL}${CLOUD_IMAGES_ROUTE}/${getBusinessUsername()}.png`
    }

    function getBusinessUsername() {
        return getUsername() ?? SPLASH_PAGE_DEFAULT_ANIMATED_LOADER_STRING
    }

    function onImageError() {
        setHasBusinessImage(false)
    }

    function onImageSuccess() {
        setHasBusinessImage(true)
        removeStarterLogo()
    }

    return (
        <div className="splash-container">
            {
                hasBusinessImage ? <div className='splash-business-image-container'>
                    <ImageBox
                        image={buildBusinessImageUrl()}
                        className='splash-business-image'
                        onImageLoadSuccess={onImageSuccess}
                        onImageLoadError={onImageError}
                        showImageLoader={false}
                    />
                </div> : <>
                    <LoaderPage animatedString={getBusinessUsername()} isFullScreen={true} />
                </>
            }
        </div>
    )
}