import React from 'react'

export default function CartPageTopBarMenuImage(props) {
    return (
        <svg
            {...props}
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            className="cart-page-top-bar-options-button"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill="none"
                stroke="#000" 
                strokeWidth="2" 
                d="M2,19 L22,19 M2,5 M2,12 L22,12"></path>
        </svg>
    )
}