import { environment } from "conf";
import { 
    SHOP_API_COLLECTIONS_ROUTE, 
    SHOP_API_LINKS_ROUTE, 
    SHOP_API_LINK_ROUTE, 
    SHOP_API_PRODUCTS_SUFFIX, 
    SHOP_API_SECTIONS_ROUTE, 
    SHOP_API_SENSE_ROUTE, 
    SHOP_API_URL, 
} from "constants/api.constant";
import { getCartID } from "contexts/shopping";
import { request_auth } from "services/RequestWrapper";

/*
    Retrieves the related products for a given link.

    Method: [GET]
    URL: /shop/sense/links/<linkId>/products

    Headers:
    =========
    (*) username - The username of the current user

    Route params:
    ==============
        (*) linkId - The id of the given link
*/
export function getRelatedProducts(username, linkId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username,
            },
            body: {
                cart_id: getCartID()
            }
        }
        const url = `${environment.base_url}${SHOP_API_URL}${SHOP_API_SENSE_ROUTE}${SHOP_API_LINKS_ROUTE}/${linkId}${SHOP_API_PRODUCTS_SUFFIX}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Retrieves all the link's sections required for display.

    Method: [GET]
    URL: /shop/sense/links/<linkId>/sections

    Headers:
    =========
        (*) username - The username of the current user

    Route params:
    =============
        (*) linkId - The id of the link
*/
export function getLinkSections(username, linkId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username
            },
            body: {
                cart_id: getCartID()
            }
        }
        const url = `${environment.base_url}${SHOP_API_URL}${SHOP_API_SENSE_ROUTE}${SHOP_API_LINKS_ROUTE}/${linkId}${SHOP_API_SECTIONS_ROUTE}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Retrieves a link's collections.

    Method: [GET]
    URL: /shop/collections/links/<linkId>

    Headers:
    =========
        (*) username - The username of the current user

    Route params:
    =============
        (*) linkId - The id of the link
*/
export function getCollections(username, linkId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            headers: {
                username
            }
        }
        const url = `${environment.base_url}${SHOP_API_URL}${SHOP_API_COLLECTIONS_ROUTE}${SHOP_API_LINKS_ROUTE}/${linkId}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Retrieves a collection's products.

    Method: [POST]
    URL: /shop/collections/link/<linkId>/collections/<collectionId>/products

    Headers:
    =========
        (*) username - The username of the current user

    Route params:
    =============
        (*) collectionId - The id of the collection
*/
export function getCollectionProducts(username, linkId, collectionId, count, skip) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username
            },
            body: {
                count,
                skip,
                cart_id: getCartID()
            }
        }
        const url = `${environment.base_url}${SHOP_API_URL}${SHOP_API_COLLECTIONS_ROUTE}${SHOP_API_LINK_ROUTE}/${linkId}${SHOP_API_COLLECTIONS_ROUTE}/${collectionId}${SHOP_API_PRODUCTS_SUFFIX}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Retrieves all the link's sections required for display.

    Method: [GET]
    URL: /shop/sense/links/<link_id>/products/<product_id>/sections

    Headers:
    =========
        (*) username - The username of the current user

    Route params:
    =============
        (*) linkId - The id of the link
*/
export function getProductPageSections(username, linkId, productId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username
            },
            body: {
                cart_id: getCartID()
            }
        }
        const url = `${environment.base_url}${SHOP_API_URL}${SHOP_API_SENSE_ROUTE}${SHOP_API_LINKS_ROUTE}/${linkId}${SHOP_API_PRODUCTS_SUFFIX}/${productId}${SHOP_API_SECTIONS_ROUTE}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}