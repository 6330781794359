import { INVENTORY_POLICY_OPTIONS } from "constants/general.constants"
import { isObjectEmpty } from "utils/utils"
import { getVariantQuantity } from "utils/variantUtils"

/**
 * A parser class for product entity to provide details 
 * about a scanned variant of a product.
 */
export default class ProductParser {

    constructor(product, cartDetails) {
        this.product = JSON.parse(JSON.stringify(product))
        this.cartDetails = cartDetails
    }

    /**
     * Parses a response product object retreived from the server
     * @returns a product object with the following structure:
     * {
     *      inventory_id: string,
            variant_id: string,
            sku: string,
            color: string,
            size: string,
            description: string
            title: string,
            images: [{
                height,
                width,
                src,
                variant_ids,
            }],
            price: number,
            original_price: number,
            sizes: {
                [size of type string]: {
                    variant_primary: *variant object,
                    variant_secondary: *variant object
                },
                .
                .
                .
            } 
     * }
        
        variant object has the following structure:
        {
            upc: string,
            sku: string,
            price: number,
            original_price: number,
            image_position: number,
            variant_id: string,
            quantity: number
        }
     */
    parseObject() {
        // retreive the properties of the product
        const inventoryId = this.product?.inventory_id
        const description = this.product?.description
        const title = this.product?.title
        const images = this.product?.images
        const variants = this.product?.variants
        const options = this.product?.options
        const handle = this.product?.handle

        if (this.product.variants.length > 0) {
            // updates the stock quantity in all variants according to your cart content
            this.updateVariantsStockQuantity()

            // find the selected variant in the corresponding variants' array
            let selectedVariant = this.product.variants[0]

            // retreive the properties of the variant
            const variantId = selectedVariant?.variant_id
            const quantity = Math.max(selectedVariant?.quantity, 0)

            return {
                inventoryId,
                title,
                description,
                images,
                variant_id: variantId,
                quantity,
                variants,
                options,
                handle
            }
        }

        return {
            inventoryId: null,
            title: '',
            description: '',
            images: [],
            variant_id: null,
            quantity: 0,
            options: {}
        }
    }

    /**
     * Updates the stock quantity in all variants in the response product object according to your cart content.
     * This is necessary for synching the product page UI with the correct stock quantity of this variant.
     */
    updateVariantsStockQuantity() {
        if (this.cartDetails.cartItems?.length > 0) {
            for (let i = 0; i < this.product?.variants.length; i++) {
                this.cartDetails?.cartItems?.map(item => {
                    if (item.variant_id === this.product.variants[i].variant_id) {
                        this.product.variants[i].quantity -= item.quantity
                    }
                })
            }
        }
    }

    /**
    * Takes the next option position (starting from 1) and the prev options choises if exists
    * @params:
    *      position: the option position in the product options
    *      prevOptions: {
    *          prevOptionName: prevOptionValue
    *      }
    * 
    * @returns a dictionary of option values:
    *     {
    *         optionName,
    *         options: {
    *              optionsValue: {
    *                  variantId,
    *                  quantity,
    *              }
    *         }
    *         position,
    *         values,
    *     }
    *     If not the last options, variantId will be null.
    *      
   **/
    getOptionsFromProduct(position, prevOptions = {}) {
        if (!this.product.options || this.product.options.length < position || this.product.variants?.length === 0) {
            if (this.product.variants && this.product.variants.length > 0) {
                return {
                    prevOptions: {},
                    optionName: "",
                    options: {
                        default: {
                            variantId: this.product.variants[0].variant_id,
                            quantity: this.product.variants[0].inventory_policy == INVENTORY_POLICY_OPTIONS.CONTINUE ? 1 : this.product.variants[0].quantity,
                        }
                    },
                    position,
                    values: [],
                }
            }
            return {}
        }
        let options = {}
        let values = []
        let optionName = null
        let setVariant = this.product.options.length === position ? true : false
        for (let productOption of this.product.options) {
            if (position === productOption.position) {
                values = productOption.values
                for (let optionValue of productOption.values) {
                    options[optionValue] = {
                        variantId: null,
                        quantity: 0,
                    }
                }
                optionName = productOption.name
            }
        }
        if (optionName === null) {
            return {
                prevOptions: {},
                optionName: "",
                options: {},
                position,
                values: [],
            }
        }
        for (let productVariant of this.product.variants) {
            if (Object.keys(prevOptions).every(name => prevOptions[name] === productVariant.options[name])) {
                options[productVariant.options[optionName]] = {
                    variantId: setVariant ? productVariant.variant_id : null,
                    quantity: options[productVariant.options[optionName]].quantity + getVariantQuantity(productVariant.quantity, productVariant.inventory_policy)
                }
            }
        }
        if (this.product.options.length > position && values.length === 1 && options[values[0]].quantity > 0) {
            return this.getOptionsFromProduct(position + 1, { ...prevOptions, [optionName]: values[0] })
        }
        return {
            prevOptions,
            optionName,
            options,
            position,
            values,
        }
    }

    /**
     * Builds an object that maps from each value of 'optionName' to the total quantities of this value in all
     * the product variants.
     * @param {string} optionName - The name of the option
     * @param {array} optionValues - The array of values of the option
     * @param {object} prevOptionValuesQuantities - The object that contains the previous quantities' count of the option
     * @param {object} lastChosenOption - The last option that was chosen by the user
     * @param {object} chosenOptions - The object of all the options that were chosen by the user
     * @returns an object of all the option's values and their quantities.
     */
    getOptionCounter(optionName, optionValues, prevOptionValuesQuantities, lastChosenOption, chosenOptions) {
        if (lastChosenOption && optionName === lastChosenOption?.name) {
            return prevOptionValuesQuantities
        }

        const optionCounter = {}
        optionValues?.forEach(value => optionCounter[value] = 0)

        if (!lastChosenOption && isObjectEmpty(chosenOptions)) {
            this.product?.variants?.forEach(variant => {
                optionCounter[variant?.options[optionName]] += getVariantQuantity(variant?.quantity, variant?.inventory_policy)
            })
        } else {
            this.product?.variants?.forEach(variant => {
                let shouldAddVariantQuantity = true
                for (const chosenOptionName of Object.keys(chosenOptions)) {
                    if (chosenOptionName === optionName) {
                        continue
                    }

                    if (variant?.options[chosenOptionName] !== chosenOptions[chosenOptionName]) {
                        shouldAddVariantQuantity = false
                        break
                    }
                }

                if (shouldAddVariantQuantity) {
                    optionCounter[variant?.options[optionName]] += getVariantQuantity(variant?.quantity, variant?.inventory_policy)
                }
            })
        }
        return optionCounter
    }
}