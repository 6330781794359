import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import "./i18n";

ReactDOM.render(
	// TODO : React.StrictMode should be deleted in the prduction version
	// <React.StrictMode>
	<App />,
	// </React.StrictMode>,
	document.getElementById('root')
);