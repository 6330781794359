import { LocalStorageGetItem, LocalStorageRemoveItem, LocalStorageSetItem } from "services/LocalStorage";

class SharedPreferences {
    constructor() {
        this.archive = {};
        this.keys = Object.keys(window.localStorage);
        this.keys.forEach(key => {
            this.archive[key] = window.localStorage.getItem(key);
        });
    };

    get_key(key) {
        if(this.archive.hasOwnProperty(key)){
            let value = this.archive[key];
            LocalStorageSetItem(key, value);
            return value;
        } else {
            let value =  LocalStorageGetItem(key, null);
            if(!value)
                this.archive[key] = value;
            return value;
        }
    }

    set_key(key, value){
        LocalStorageSetItem(key, value);
        this.archive[key] = value;
    }
    
    remove_key(key){
        if(this.archive.hasOwnProperty(key)){
            delete this.archive[key];
        }
        LocalStorageRemoveItem(key);
    }
}

export const LocalSharedPreferences = new SharedPreferences()