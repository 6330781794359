import { ANIMATIED_STRING_LOADER_ANIMATION_DURATION_SECONDS } from 'constants/general.constants';
import React, { useEffect } from 'react'
import './AnimatedStringLoader.css'

/**
 * Represents an animated string loader used by {@link LoaderPage} component.
 * It takes the given string, wraps each letter with <h1> tag and applies fade-in & fade-out animations on each letter
 * in a way that the first letter is turning black, then the second, then the third etc., until all letters are turned black
 * and finally, turns all letters to white again at the same time.
 * @param {string} string The string to animate
 */
export default function AnimatedStringLoader({ string }) {

    const resetAnimation = () => {
        const els = document.querySelectorAll('h1');
        for (const el of els) {
            el.style.animationName = "none";
            setTimeout(() => {
                requestAnimationFrame(() => {
                    el.style.animationName = "fade-in, fade-out";
                })
            }, 50)
        }
    }

    useEffect(() => {
        const intervalId = setInterval(resetAnimation, ((string.length * ANIMATIED_STRING_LOADER_ANIMATION_DURATION_SECONDS) + ANIMATIED_STRING_LOADER_ANIMATION_DURATION_SECONDS) * 1000)
        return () => clearInterval(intervalId)
    }, [string])

    return (
        <div className="animated-string-loader-container">
            {[...string].map((character, index) =>
                <h1 className='animated-char' key={character + index} style={{
                    animation: `fade-in ${ANIMATIED_STRING_LOADER_ANIMATION_DURATION_SECONDS}s forwards, fade-out ${ANIMATIED_STRING_LOADER_ANIMATION_DURATION_SECONDS}s forwards `,
                    animationDelay: `${index * ANIMATIED_STRING_LOADER_ANIMATION_DURATION_SECONDS}s, ${((string.length) * ANIMATIED_STRING_LOADER_ANIMATION_DURATION_SECONDS)}s`
                }}>{character.toUpperCase()}</h1>
            )}
        </div>
    )
}