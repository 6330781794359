import React from 'react'
import './CheckoutButton.css'
import { getUsername, useBusinessPropertiesContext, useCartDetailsContext, useGeneralContext, useLinkDetailsContext } from 'contexts/shopping'
import { CART_HIDE_LOADER_AFTER_CHECKOUT_REQUEST_DONE_DELAY_MILLISECONDS } from 'constants/general.constants'
import { COUNTRY_CODE_LOCAL_STORAGE_KEY } from 'constants/storage.constant'
import { formatNumber } from 'utils/utils'
import { getCurrencySymbol } from 'services/Currency'
import { useTranslation } from 'react-i18next'
import { RequestPaymentAPI } from 'api/payment/payment'
import { LocalStorageGetItem } from 'services/LocalStorage'
import { logger } from 'services/CloudLogger'

export default function CheckoutButton({
    isCartButton,
    lowerSectionGradient,
    isCartPageScrollable,
    isPromocodeWidthLarge,
    buttonRef,
    promocodeRef,
    setIsUpdatingCart = () => { },
    setDidPaymentRequestFail = () => { }
}) {
    const { t } = useTranslation()
    const cartDetails = useCartDetailsContext()
    const { linkDetails } = useLinkDetailsContext()
    const { businessProperties } = useBusinessPropertiesContext()
    const {
        notifyCheckoutRequest,
        forwardQueries,
    } = useGeneralContext()

    const checkoutButtonStyles = {
        backgroundColor: businessProperties?.theme?.checkoutButtonBackgroundColor,
        color: businessProperties?.theme?.checkoutButtonFontColor,
        border: businessProperties?.theme?.checkoutButtonBorderColor ? `1px solid ${businessProperties?.theme?.checkoutButtonBorderColor}` : 'unset',
        borderRadius: businessProperties?.theme?.checkoutButtonBorderRadius
    }

    function proceedToPayment() {
        setIsUpdatingCart(true)
        notifyCheckoutRequest()
        RequestPaymentAPI(getUsername(), linkDetails.linkId, LocalStorageGetItem(COUNTRY_CODE_LOCAL_STORAGE_KEY, ""), forwardQueries)
            .then((url) => {
                window.location.href = url
                setTimeout(() => {
                    setIsUpdatingCart(false)
                }, CART_HIDE_LOADER_AFTER_CHECKOUT_REQUEST_DONE_DELAY_MILLISECONDS)
            })
            .catch(error => {
                // Alert the user that the checkout request failed	
                logger.error(`Error - checkout request with error ${error}`)
                setIsUpdatingCart(false)
                setDidPaymentRequestFail(true)
            })
    }

    function calculateTotalPrice() {
        return cartDetails?.cartItems.reduce((accumulator, item) => {
            return accumulator + (item.price * item.quantity)
        }, 0)
    }

    function hasPromocode() {
        return ![undefined, null, ''].includes(linkDetails?.promocode?.code)
    }

    return (
        <div ref={buttonRef} className={`home-page-pay-button-container ${isCartPageScrollable ? '' : 'bottom-fixed'}`} style={{ position: isCartButton ? 'absolute' : 'fixed', backgroundImage: cartDetails?.cartItems.length > 0 ? lowerSectionGradient : 'unset', paddingTop: hasPromocode() ? '23px' : '5px' }}>
            {
                hasPromocode() && <div ref={promocodeRef} dir={t('direction.dir')} className={`cart-promocode ${cartDetails?.cartItems.length > 0 ? 'on-top' : ''}`} style={{ fontSize: isPromocodeWidthLarge ? '11px' : '13px' }}>
                    <div className="cart-promocode-title">{t('CART_PROMOCODE_TITLE')}</div>
                    <div className='cart-promocode-value-container' style={{ maxWidth: t('direction.dir') === 'rtl' ? '140px' : '70px' }}>
                        (<div dir='ltr' className="cart-promocode-value">{`${linkDetails?.promocode?.code}`}</div>)
                    </div>
                    <div className="cart-promocode-right-section">{t('CART_PROMOCODE_APPLIED_AT_CHECKOUT')}</div>
                </div>
            }
            <div dir={t('direction.dir')} onClick={proceedToPayment} className={`home-page-pay-button ${cartDetails?.cartItems.length === 0 ? 'disabled' : ''}`} style={checkoutButtonStyles}>
                <div className="home-page-pay-button-text">{t('CART_PAY_BUTTON_DESC_TEXT')}</div>
                <div className="home-page-pay-button-dot" style={{ backgroundColor: checkoutButtonStyles.color }}></div>
                <div className="home-page-pay-button-total-items">{t('CART_PAY_BUTTON_TOTAL_ITEMS_TEXT', { count: cartDetails?.totalItems })}</div>
                <div className="home-page-pay-button-dot" style={{ backgroundColor: checkoutButtonStyles.color }}></div>
                <div className="home-page-pay-button-total-price">{`${getCurrencySymbol(cartDetails?.currency)}${formatNumber(calculateTotalPrice())}`}</div>
            </div>
        </div>
    )
}