import React from "react";
import './backButton.css';
import { MdArrowBack } from "react-icons/md";

const DEFAULT_Z_INDEX = 3;

// backButton component
// onBackIcon is a function called upon an onClick event of the back button.
// zIndex - will set the zindex property of the button. default is 3.
export default function BackButton({ onBackIcon, zIndex }) {

    const style = {
        zIndex: zIndex ? zIndex : DEFAULT_Z_INDEX
    };

    return (
        <div className="btn-back" onClick={onBackIcon} style={style}>
            <MdArrowBack className="btn-back-icon" />
        </div>
    )
}