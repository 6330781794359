import React, { useState, useEffect, Suspense } from "react"
import { Route } from "react-router-dom";
import { BrowserRouter as Router, Switch } from "react-router-dom"
import {
    APPLICATION_RECOVERY_TIMEOUT_MILISECONDS
} from "constants/general.constants"
import Splash from "pages/Splash/Splash"
import 'App.css'
import {
    CART_PAGE_ROUTE_URL,
    COLLECTION_PAGE_ROUTE_URL,
    SHOPBOARD_PAGE_ROUTE_URL,
} from "constants/routes.constants"
// import ShoppingProvider from "contexts/shopping"
import CollectionPage from "pages/CollectionPage/CollectionPage";
import { removeStarterLogo } from "utils/elementUtils";
const ShoppingProvider = React.lazy(() => import('contexts/shopping'));
// import NotFound from "pages/NotFound/NotFound"
const NotFound = React.lazy(() => import('pages/NotFound/NotFound'));
// import HistoryProvider from "contexts/History"
const HistoryProvider = React.lazy(() => import('contexts/History'));
// import CartPage from "pages/CartPage/CartPage";
const CartPage = React.lazy(() => import('pages/CartPage/CartPage'));
// const CollectionPage = React.lazy(() => import('pages/CollectionPage/CollectionPage'));


const App = () => {
    const [isVerified, setIsVerified] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const [isRecovery, setIsRecovery] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        setTimeout(() => { setIsRecovery(true) }, APPLICATION_RECOVERY_TIMEOUT_MILISECONDS)
        
        // catch general error and refresh the page
        // window.addEventListener("error", (event) => {
        //     window.location.replace(HOME_PAGE_ROUTE_URL);
        // })

    }, [])

    useEffect(() => {
        if (isRecovery && !isVerified) {
            setIsError(true)
        }
    }, [isRecovery])

    useEffect(() => {
        // Remove the starterLogo if splash didn't do it already
        if (isVerified) {
            removeStarterLogo()
        }
    }, [isVerified])

    return (
        <>
            {(!isVerified || !isReady) && <Splash />}
            <Suspense fallback={<div />}>
                {
                    isError ? <NotFound /> :
                        <Router>
                            <Switch>
                                <ShoppingProvider onVerified={setIsVerified} onReady={setIsReady} onRecovery={setIsRecovery}>
                                    {
                                        isVerified &&
                                        <HistoryProvider>
                                            <Route
                                                exact
                                                path={SHOPBOARD_PAGE_ROUTE_URL}>
                                                <CartPage />
                                            </Route>
                                            <Route
                                                exact
                                                path={CART_PAGE_ROUTE_URL}>
                                                <CartPage />
                                            </Route>
                                            <Route
                                                exact
                                                path={COLLECTION_PAGE_ROUTE_URL}>
                                                <CollectionPage />
                                            </Route>
                                        </HistoryProvider>
                                    }
                                </ShoppingProvider>
                            </Switch>
                        </Router>
                }
            </Suspense>
        </>
    )
}

export default App
