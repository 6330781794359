import React from 'react'
import './ExpandHideButton.css'
import { useTranslation } from 'react-i18next'
import { MdKeyboardArrowDown } from 'react-icons/md'

/**
 * Represents the expand/hide button.
 * @param {boolean} isExpanded - A state value that determins whether the button text is "Expand" or "Hide"
 * @param {function} onClick - A function for setting "isExpanded" state value
 */
export default function ExpandHideButton({
    onShowText = "Hide",
    onHideText = "Show",
    isExpanded = false,
    onClick = () => { },
}) {
    const { t } = useTranslation()

    return (
        <div className="expand-hide-button" onClick={onClick}>
            <div className="expand-hide-button-text">{isExpanded ? onShowText : onHideText}</div>
            <MdKeyboardArrowDown className={`expand-hide-button-image ${isExpanded ? 'expanded' : ''}`} />
        </div>
    )
}