import React, { useEffect, useMemo, useRef, useState } from 'react'
import './CollectionPage.css'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useHistoryContext } from 'contexts/History'
import ItemsViewSection from 'components/ItemsViewSection/ItemsViewSection'
import {
    CART_LOOK_PRODUCTS_DRAWER_BORDER_RADIUS_PX,
    COLLECTION_ITEM_WIDTH_PX,
    COLLECTION_PAGE_PRODUCTS_PAGINATION_COUNT,
    SECTION_TYPES,
    PAYDIN_DRAWER_DIRECTION,
    PAYDIN_DRAWER_TRANSITION_DURATION,
    PRODUCTS_VIEW_ITEM_WIDTH_PX,
    TOP_BAR_HEIGHT_PX,
    PRODUCT_ADDED_STRIP_DISPLAY_DELAY,
    ADD_ITEM_ERROR_ALERT_MODAL_HIDE_DELAY_MILLISECONDS,
    UPDATE_CART_ITEM_DELAY_MILLISECONDS,
    CART_PAGE_SCROLL_STATES,
    CART_PAGE_SCROLL_STOP_DELAY_MILLISECONDS,
    TOPBAR_ANIMATION_THRESHOLD_PX,
    COLLECTION_PAGE_PRODUCT_ITEM_HEIGHT_PX,
    CART_MAX_WIDTH_DESKTOP_PX,
    CART_BUTTON_ELEMENT_HORIZONTAL_PADDING,
    CART_OPEN_WIDTH_FROM_THE_SCREEN,
    CART_APPROVAL_MESSAGE_TRANSLATION_DISTANCE
} from 'constants/general.constants'
import ProductItem from 'components/ProductItem/ProductItem'
import { getCollectionProducts } from 'api/shop/shop'
import {
    getUsername,
    useAddItemContext,
    useBusinessPropertiesContext,
    useCartDetailsContext,
    useGeneralContext,
    useLinkDetailsContext,
    useRemoveItemContext
} from 'contexts/shopping'
import { useTranslation } from 'react-i18next'
import { extractCartItemDetails, extractProductData } from 'utils/dataUtils'
import CollectionItem from 'components/CollectionItem/CollectionItem'
import PaydinDrawer from 'components/PaydinDrawer/PaydinDrawer'
import ProductPage from 'pages/ProductPage/ProductPage'
import {
    COLLECTION_PAGE_COLLECTIONS_ROUTE,
    HOME_PAGE_URL,
    INVENTORY_ID_KEY
} from 'constants/routes.constants'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'
import useQueryParams from 'hooks/useQueryParams'
import CartSection from 'components/CartSection/CartSection'
import { logger } from 'services/CloudLogger'
import AlertModal from 'modal/AlertModal'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialog/PaydinDialog/PaydinDialog'
import LoaderPage from 'pages/LoaderPage/LoaderPage'
import CheckoutButton from 'components/CheckoutButton/CheckoutButton'
import CartPageTopBar from 'components/CartPageTopBar/CartPageTopBar'

export default function CollectionPage() {
    const { t, i18n } = useTranslation()
    const history = useHistoryContext()
    const {
        sectionsData,
        shopboardData,
        shoppingData,
        setShoppingData,
        findSectionCollectionById
    } = useGeneralContext()
    const { businessProperties } = useBusinessPropertiesContext()
    const { linkDetails } = useLinkDetailsContext()
    const cartDetails = useCartDetailsContext()
    const {
        collectionId,
        brandName,
        linkId
    } = useParams()
    const {
        findProductInContext
    } = useGeneralContext()
    const removeItem = useRemoveItemContext()
    const addItem = useAddItemContext()

    const queryParams = useQueryParams(handleQueryParamsChange)

    const [products, setProducts] = useState([])
    const [isLoadingProducts, setIsLoadingProducts] = useState(false)
    const [isLoadingMoreProducts, setIsLoadingMoreProducts] = useState(false)
    const [hasMoreProducts, setHasMoreProducts] = useState(true)
    const [productDrawerState, setProductDrawerState] = useState({
        isOpen: false,
        height: 0,
        maxHeight: 0,
        openInSameRoute: false,
        onOpen: openProductDrawer,
        onClose: closeProductDrawer
    })
    const [isProductPageLoaded, setIsProductPageLoaded] = useState(false)
    const [approvalMessageState, setApprovalMessageState] = useState({
        isOpen: false,
        message: ''
    })
    const [productInventoryId, setProductInventoryId] = useState('')
    const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState({})
    const [isUpdatingCart, setIsUpdatingCart] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [showAlert, setShowAlert] = useState(false)
    const [isRemoveCartItemDialogOpen, setIsRemoveCartItemDialogOpen] = useState(false)
    const [removeCartItemDialogRightButtonClickHandler, setRemoveCartItemDialogRightButtonClickHandler] = useState(() => { return () => { } })
    const [lowerSectionGradient, setLowerSectionGradient] = useState('')
    const [didPaymentRequestFail, setDidPaymentRequestFail] = useState(false)
    const [pageScrollState, setPageScrollState] = useState(CART_PAGE_SCROLL_STATES.TOP)
    const [productDrawerDirection, setProductDrawerDirection] = useState(PAYDIN_DRAWER_DIRECTION.BOTTOM)
    const [isPromocodeWidthLarge, setIsPromocodeWidthLarge] = useState(false)

    const timeoutRef = useRef(null)
    const collectionsStripRef = useRef(null)
    const pageContainerRef = useRef(null)
    const buttonRef = useRef(null)
    const promocodeRef = useRef(null)

    const currentCollection = findSectionCollectionById(collectionId)

    const cartProducts = useMemo(() => {
        return <CartSection
            route={`/collections/${collectionId}`}
            isInCartItems={true}
            items={
                cartDetails?.cartItems?.map(cartItem => extractCartItemDetails(
                    cartItem,
                    cartDetails?.currency,
                    findProductInContext(cartItem?.product_inventory_id),
                    decreaseQuantity,
                    addToCartClicked
                ))
            }
            itemImageAspectRatio={businessProperties?.theme?.mainLinkItemsImageRatio}
        />
    }, [cartDetails, t])

    const collectionProducts = useMemo(() => {
        return <ItemsViewSection
            data={products}
            type={SECTION_TYPES.GRID}
            itemWidth={`${PRODUCTS_VIEW_ITEM_WIDTH_PX}px`}
            viewItemComponent={ProductItem}
            isLoadingItems={isLoadingProducts}
            isLoadingMoreItems={isLoadingMoreProducts}
            onItemClick={navigateToCollectionProductPage}
            columnsCount={products.length > 1 ? 'auto-fit' : 2}
        />
    }, [products, isLoadingProducts, isLoadingMoreProducts])

    const collections = useMemo(() => {
        return <ItemsViewSection
            sectionRef={collectionsStripRef}
            title={currentCollection?.title}
            data={sectionsData?.collections && sectionsData?.collections.length > 0 ? sectionsData?.collections : shopboardData?.lastPostSectionsData?.collections}
            type={SECTION_TYPES.STRIP}
            itemWidth={`${COLLECTION_ITEM_WIDTH_PX}px`}
            viewItemComponent={CollectionItem}
            isItemSelected={itemId => itemId === collectionId}
            onItemClick={switchCollection}
            titleFontSize={'12px'}
        />
    }, [sectionsData, shopboardData, currentCollection])

    useEffect(() => {
        setLowerSectionGradient(`linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) ${hasPromocode() ? '60%' : '30%'}, rgba(255, 255, 255, 0) 100%)`)
    }, [linkDetails])

    useEffect(() => {
        if (Object.keys(shoppingData?.loadedCollections).includes(collectionId)) {
            setProducts(shoppingData?.loadedCollections[collectionId]?.data)
            setHasMoreProducts(shoppingData?.loadedCollections[collectionId]?.hasMoreData)
        } else {
            setIsLoadingProducts(true)
            getCollectionProducts(getUsername(), linkDetails?.linkId, collectionId, COLLECTION_PAGE_PRODUCTS_PAGINATION_COUNT, 0)
                .then(response => {
                    const collectionProducts = response?.map(product => extractProductData(product, cartDetails?.currency))
                    setShoppingData(prev => ({
                        ...prev,
                        loadedCollections: {
                            ...prev.loadedCollections,
                            [collectionId]: {
                                data: collectionProducts,
                                hasMoreData: response.length === COLLECTION_PAGE_PRODUCTS_PAGINATION_COUNT
                            }
                        }
                    }))
                    setProducts(collectionProducts)
                    setHasMoreProducts(response.length === COLLECTION_PAGE_PRODUCTS_PAGINATION_COUNT)
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setIsLoadingProducts(false)
                })
        }
    }, [collectionId])

    useEffect(() => {
        if (!pageContainerRef?.current)
            return

        const resizeObserver = new ResizeObserver(onWindowResize)
        resizeObserver.observe(pageContainerRef.current)

        return () => resizeObserver.disconnect()
    }, [])

    useEffect(() => {
        if (buttonRef?.current && promocodeRef?.current) {
            setIsPromocodeWidthLarge((buttonRef?.current?.clientWidth - (CART_BUTTON_ELEMENT_HORIZONTAL_PADDING * 2)) === promocodeRef?.current?.clientWidth)
        }
    }, [isUpdatingCart, isCartDrawerOpen])

    function onWindowResize() {
        setProductDrawerDirection(window.innerWidth > CART_MAX_WIDTH_DESKTOP_PX ? PAYDIN_DRAWER_DIRECTION.RIGHT : PAYDIN_DRAWER_DIRECTION.BOTTOM)
    }

    // Get the alert timeout on a function 'onAlertDismiss' to run after timeour
    const alertMsg = (timeout, msg, onAlertDismiss) => {
        setAlertMessage(msg)
        setShowAlert(true)
        setTimeout(() => {
            setShowAlert(false)
            onAlertDismiss()
        }, timeout)
    }

    function addToCartClicked(variantInventoryId, selectedOptions) {
        const product = findProductInContext(variantInventoryId)
        if (product?.variants?.length === 1) {
            increaseQuantity(product?.inventoryId, product?.variants[0]?.variant_id, 1, product?.title)
        } else {
            setSelectedOptions(selectedOptions)
            setProductInventoryId(variantInventoryId)
            openProductDrawer(false, true)
        }
    }

    function increaseQuantity(inventory_id, variantId, quantity, title) {
        setIsUpdatingCart(true)
        addItem(inventory_id, variantId, quantity, title, (isAdded, message) => {
            logger.log(`onItemAdded ${isAdded} ${message}`);
            setTimeout(() => {
                setIsUpdatingCart(false)
                if (isAdded) {
                    showProductAddedMessage()
                } else {
                    alertMsg(ADD_ITEM_ERROR_ALERT_MODAL_HIDE_DELAY_MILLISECONDS, i18n.exists(message) ? t(message) : t('PRODUCT_PAGE_ALERT_ADD_ITEM_ERROR_MESSAGE'), () => { }); // On failure to add item, alert the user before proceeding
                }
            }, UPDATE_CART_ITEM_DELAY_MILLISECONDS)
        })
    }

    function deleteItemFromCart(variantId, quantity) {
        setIsUpdatingCart(true)
        removeItem(variantId, quantity, (isRemoved, message) => {
            if (!isRemoved) {
                if (i18n.exists(message)) {
                    alertMsg(800, t(message), () => { }); // On failure to add item, alert the user before proceeding
                } else {
                    alertMsg(800, message, () => { }); // On failure to add item, alert the user before proceeding
                }
                return
            }

            logger.log(`onItemRemoved ${isRemoved}`)
            setTimeout(() => {
                setIsUpdatingCart(false)
            }, UPDATE_CART_ITEM_DELAY_MILLISECONDS)
        })
    }

    function decreaseQuantity(variantId, variantQuantity, quantity) {
        if (variantQuantity - quantity === 0) {
            setIsRemoveCartItemDialogOpen(true)
            setRemoveCartItemDialogRightButtonClickHandler(() => {
                return () => deleteItemFromCart(variantId, quantity)
            })
        } else {
            deleteItemFromCart(variantId, quantity)
        }
    }

    function handleQueryParamsChange() {
        const inventoryId = queryParams.get(INVENTORY_ID_KEY)
        setProductInventoryId(inventoryId)
        if (inventoryId && history.hasNoBackHandlers()) {
            history.replace(`${window.basePath}/${getUsername()}/${linkDetails?.linkId}${COLLECTION_PAGE_COLLECTIONS_ROUTE}/${collectionId}`)
            return
        }

        if (inventoryId) {
            openProductDrawer()
        } else {
            if (shoppingData?.isProductAdded) {
                showProductAddedMessage()
            }
        }
    }

    function findProduct(inventoryId) {
        let foundProduct = products.find(product => product?.inventoryId === inventoryId)

        if (!foundProduct) {
            foundProduct = findProductInContext(inventoryId)
        }

        return foundProduct
    }

    function openProductDrawer(openInNewRoute = true, openedQuickAdd = false) {
        setProductDrawerState(prev => ({
            ...prev,
            isOpen: true,
            openInNewRoute,
            openInSameRoute: openedQuickAdd,
            ...(!openInNewRoute ? { height: 'auto' } : { height: `calc(100% - ${window.innerWidth > CART_MAX_WIDTH_DESKTOP_PX ? 0 : (TOP_BAR_HEIGHT_PX / 2)}px)` })
        }))
    }

    function closeProductDrawer() {
        setProductDrawerState(prev => ({
            ...prev,
            isOpen: false
        }))
        setTimeout(() => {
            resetProductDrawerProperties()
        }, PAYDIN_DRAWER_TRANSITION_DURATION)
    }

    function resetProductDrawerProperties() {
        setIsProductPageLoaded(false)
    }

    function navigateToCollectionProductPage(inventoryId) {
        if (inventoryId) {
            history.push(`${window.basePath}/${getUsername()}/${linkDetails?.linkId}${COLLECTION_PAGE_COLLECTIONS_ROUTE}/${collectionId}?${INVENTORY_ID_KEY}=${inventoryId}`)
        }
    }

    function switchCollection(collectionId) {
        if (window.location.pathname !== `${window.basePath}/${getUsername()}/${linkDetails?.linkId}${COLLECTION_PAGE_COLLECTIONS_ROUTE}/${collectionId}`) {
            history.push(`${window.basePath}/${getUsername()}/${linkDetails?.linkId}${COLLECTION_PAGE_COLLECTIONS_ROUTE}/${collectionId}`)
        }
    }

    function closeDialog() {
        setIsRemoveCartItemDialogOpen(false)
    }

    function handleScroll(e) {
        if (timeoutRef?.current) {
            clearTimeout(timeoutRef?.current)
        }

        const scrollTop = e.target.scrollTop
        if (scrollTop < TOPBAR_ANIMATION_THRESHOLD_PX) { // at the top of the page
            setPageScrollState(CART_PAGE_SCROLL_STATES.TOP)
        } else {
            setPageScrollState(CART_PAGE_SCROLL_STATES.SCROLLING)
            timeoutRef.current = setTimeout(() => {
                setPageScrollState(CART_PAGE_SCROLL_STATES.MIDDLE)
            }, CART_PAGE_SCROLL_STOP_DELAY_MILLISECONDS)
        }

        const scrollPosition = e.target.scrollTop + pageContainerRef?.current?.clientHeight
        const thresholdScrollHeight = pageContainerRef?.current?.scrollHeight - (COLLECTION_PAGE_PRODUCT_ITEM_HEIGHT_PX * 1.5) - 20

        if (!isLoadingMoreProducts && hasMoreProducts && scrollPosition >= thresholdScrollHeight) {
            setIsLoadingMoreProducts(true)
            getCollectionProducts(getUsername(), linkDetails?.linkId, collectionId, COLLECTION_PAGE_PRODUCTS_PAGINATION_COUNT, products?.length)
                .then(response => {
                    const moreCollectionProducts = [
                        ...products,
                        ...(response?.map(product => extractProductData(product, cartDetails?.currency)))
                    ]
                    setShoppingData(prev => ({
                        ...prev,
                        loadedCollections: {
                            ...prev.loadedCollections,
                            [collectionId]: {
                                data: moreCollectionProducts,
                                hasMoreData: response.length === COLLECTION_PAGE_PRODUCTS_PAGINATION_COUNT
                            }
                        }
                    }))
                    setProducts(moreCollectionProducts)
                    setHasMoreProducts(response.length === COLLECTION_PAGE_PRODUCTS_PAGINATION_COUNT)
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setIsLoadingMoreProducts(false)
                })
        }
    }

    function showProductAddedMessage() {
        setTimeout(() => {
            showApprovalMessage(t('PRODUCT_PAGE_PRODUCT_ADDED_APPROVAL_MESSAGE'))
            setShoppingData(prev => ({
                ...prev,
                isProductAdded: false
            }))
        }, PRODUCT_ADDED_STRIP_DISPLAY_DELAY)
    }

    function showApprovalMessage(message) {
        setApprovalMessageState({
            message,
            isOpen: true
        })
    }

    function openCartDrawer() {
        setIsCartDrawerOpen(true)
    }

    function closeCartDrawer() {
        setIsCartDrawerOpen(false)
    }

    function hasPromocode() {
        return ![undefined, null, ''].includes(linkDetails?.promocode?.code)
    }

    function renderCheckoutButton() {
        return <CheckoutButton
            buttonRef={buttonRef}
            promocodeRef={promocodeRef}
            isCartPageScrollable={true}
            isCartButton={true}
            lowerSectionGradient={lowerSectionGradient}
            isPromocodeWidthLarge={isPromocodeWidthLarge}
            setIsUpdatingCart={setIsUpdatingCart}
            setDidPaymentRequestFail={setDidPaymentRequestFail}
        />
    }

    function closePaymentErrorDialog() {
        setDidPaymentRequestFail(false)
    }

    return (
        <>
            {isUpdatingCart && <LoaderPage styles={{ backgroundColor: '#ffffffaa' }} isFullScreen={true} />}
            <div ref={pageContainerRef} className='collection-page-container' onScroll={handleScroll}>
                <CartPageTopBar
                    pageScrollState={pageScrollState}
                    setIsCartDrawerOpen={setIsCartDrawerOpen}
                    isMainPageTopBar={false}
                    onBackButtonClick={() => history.goBack()}
                    onCenterClick={() => history.push(`${window.basePath}/${brandName}/${linkId}`)}
                />

                {collections}
                {products && products.length > 0 && <div className="separator-line"></div>}
                {collectionProducts}

                <PaydinDrawer
                    direction={PAYDIN_DRAWER_DIRECTION.RIGHT}
                    isOpen={isCartDrawerOpen}
                    onOpen={openCartDrawer}
                    onClose={closeCartDrawer}
                    hasPadding={false}
                    width={`${Math.round(CART_OPEN_WIDTH_FROM_THE_SCREEN * 100)}%`}
                    maxWidth={`${CART_MAX_WIDTH_DESKTOP_PX * 0.7}px`}
                    isContentLoaded={true}
                >
                    <div className="cart-cart-drawer-container">
                        <div dir={t('direction.dir')} className="cart-cart-drawer-topbar">
                            <div className="cart-cart-drawer-topbar-title">{t('CART_CART_DRAWER_TOPBAR_TITLE')}</div>
                            <div className="cart-cart-drawer-topbar-close-button" onClick={() => history.goBack()}>{t('CART_CART_DRAWER_TOPBAR_CLOSE_BUTTON_TEXT')}</div>
                        </div>
                        {
                            cartDetails?.cartItems?.length > 0 ? <>
                                <div className={`cart-cart-drawer-content ${!hasPromocode() ? 'no-promocode' : ''}`}>
                                    {cartProducts}
                                </div>
                                <div className={`cart-cart-drawer-checkout-section ${!hasPromocode() ? 'no-promocode' : ''}`}>
                                    <div dir={t('direction.dir')} className="cart-cart-drawer-checkout-section-text">{t('CART_CART_DRAWER_CHECKOUT_SECTION_TEXT')}</div>
                                    {renderCheckoutButton()}
                                </div>
                            </> : <div className='cart-cart-drawer-no-items-container'>
                                <div className="cart-cart-drawer-no-items-text">{t('CART_CART_DRAWER_NO_ITEMS_TEXT')}</div>
                            </div>
                        }
                    </div>
                </PaydinDrawer>
                <PaydinDrawer
                    direction={productDrawerDirection}
                    isOpen={productDrawerState.isOpen}
                    onOpen={productDrawerState.onOpen}
                    onClose={productDrawerState.onClose}
                    topRightBorderRadiusPx={CART_LOOK_PRODUCTS_DRAWER_BORDER_RADIUS_PX}
                    topLeftBorderRadiusPx={CART_LOOK_PRODUCTS_DRAWER_BORDER_RADIUS_PX}
                    hasPadding={false}
                    maxWidth={window.innerWidth > CART_MAX_WIDTH_DESKTOP_PX ? `${CART_MAX_WIDTH_DESKTOP_PX * 0.7}px` : '100%'}
                    height={productDrawerState.height}
                    maxHeight={productDrawerState.maxHeight}
                    openedInNewRoute={productDrawerState.openInNewRoute}
                    isContentLoaded={isProductPageLoaded}
                >
                    <ProductPage
                        productInventoryId={productInventoryId}
                        showOnlyOptionsPicker={false}
                        predefinedSelectedOptions={selectedOptions}
                        isContainingDrawerOpen={productDrawerState.isOpen}
                        isContentLoaded={isProductPageLoaded}
                        setIsContentLoaded={setIsProductPageLoaded}
                        onProductAdded={() => {
                            setShoppingData(prev => ({
                                ...prev,
                                isProductAdded: true
                            }))
                        }}
                        findProductByInventoryId={findProduct}
                        showProductAddedMessage={showProductAddedMessage}
                        productDrawerDirection={productDrawerDirection}
                        isCartDrawerOpen={isCartDrawerOpen}    
                    />
                </PaydinDrawer>
                {
                    isRemoveCartItemDialogOpen && <PaydinDialog
                        title={t('CART_REMOVE_ITEM_FROM_CART_DIALOG_TITLE')}
                        isDialogOpen={isRemoveCartItemDialogOpen}
                        handleDialogClose={closeDialog}
                        leftButtonText={t('CART_REMOVE_ITEM_FROM_CART_DIALOG_LEFT_BUTTON_TEXT')}
                        rightButtonText={t('CART_REMOVE_ITEM_FROM_CART_DIALOG_RIGHT_BUTTON_TEXT')}
                        leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                        rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                        onRightButtonClick={removeCartItemDialogRightButtonClickHandler}
                        borderRadius={0}
                        isLeftButtonWithLoader={false}
                    />
                }
                {
                    didPaymentRequestFail && <PaydinDialog
                        title={t('CART_PAGE_REQUEST_PAYMENT_FAIL_DIALOG_TITLE')}
                        isDialogOpen={didPaymentRequestFail}
                        handleDialogClose={closePaymentErrorDialog}
                        leftButtonText={t('CART_PAGE_REQUEST_PAYMENT_FAIL_DIALOG_LEFT_BUTTON_TEXT')}
                        leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                        isLeftButtonWithLoader={false}
                    />
                }
                {showAlert && <AlertModal msg={alertMessage}></AlertModal>}
                <ApprovalMessage
                    text={approvalMessageState.message}
                    isMessageShown={approvalMessageState.isOpen}
                    messageHeight={CART_APPROVAL_MESSAGE_TRANSLATION_DISTANCE}
                    closeMessage={() => setApprovalMessageState(prev => ({ ...prev, isOpen: false }))}
                />
            </div>
        </>
    )
}