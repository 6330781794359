
export function getPrice(variants, chosenOptions) {
    if (variants && variants?.length > 0) {
        const chosenOptionsVariants = variants?.filter(variant => {
            for (const chosenOptionName of Object.keys(chosenOptions)) {
                if (variant?.options[chosenOptionName] !== chosenOptions[chosenOptionName]) {
                    return false
                }
            }

            return true
        })
        const minPriceVariant = chosenOptionsVariants?.reduce((previousVariant, currentVariant) => {
            return currentVariant?.price < previousVariant?.price ? currentVariant : previousVariant
        }, chosenOptionsVariants[0])


        return {
            price: minPriceVariant?.price,
            originalPrice: minPriceVariant?.original_price
        }
    } else {
        return {
            price: 0.0,
            originalPrice: 0.0
        }
    }
}