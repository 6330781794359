import { request_auth } from 'services/RequestWrapper';
import { getValueFromSet } from 'services/SystemService';
import { environment } from "conf";
import { 
    START_SHOPPING_URL,
    ADD_PRODUCT_CART_URL_SUFFIX,
    REMOVE_PRODUCT_URL_SUFFIX
} from 'constants/api.constant';
import { getCartID } from 'contexts/shopping';

export function AddItemAPI(username, linkId, variantId, quantity=1) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username,
            },
            body: {
                cart_id: getCartID(),
                variant_id: variantId,
                quantity
            }
        }
        request_auth(environment.base_url + `${START_SHOPPING_URL}/${linkId}${ADD_PRODUCT_CART_URL_SUFFIX}`, options)
            .then(response => {
                if (response.status) {
                    let cart_data = {
                        cartId: getValueFromSet("cart_id", response.data, ""),
                        cartItems: getValueFromSet("cart_items", response.data, ""),
                        totalAmount: getValueFromSet("total_amount", response.data, ""),
                        totalItems: getValueFromSet("total_items", response.data, ""),
                        currency: getValueFromSet("currency", response.data, "")
                    };
                    resolve(cart_data);
                }
                else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error)
            });
    })
}

export function RemoveItemAPI(username, linkId, variantId, quantity=1) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username,
            },
            body: {
                cart_id: getCartID(),
                variant_id: variantId,
                quantity
            }
        }
        request_auth(environment.base_url + `${START_SHOPPING_URL}/${linkId}${REMOVE_PRODUCT_URL_SUFFIX}`, options)
            .then(response => {
                if (response.status) {
                    let cart_data = {
                        cartId: getValueFromSet("cart_id", response.data, ""),
                        cartItems: getValueFromSet("cart_items", response.data, ""),
                        totalAmount: getValueFromSet("total_amount", response.data, ""),
                        totalItems: getValueFromSet("total_items", response.data, ""),
                        currency: getValueFromSet("currency", response.data, "")
                    };
                    resolve(cart_data)
                }
                else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            });
    })
}