import { CHROME_BROWSER_NAME, COLOR_OPTION_NAMES, SAFARI_BROWSER_NAME, SIZE_OPTION_NAMES, SHADE_OPTION_NAMES } from "constants/general.constants"
import { getPathParamsList } from "services/SystemService"
import colors from 'assets/colors.json'
import shades from 'assets/colors-shades.json'

const formatter = new Intl.NumberFormat('en-US')

// This function gets a dictionary and return an array of its keys sorted by its values from smallest to highest
export function getSortedObjectKeys(dict) {
    var items = Object.keys(dict).map(
        (key) => { return [key, dict[key]] });

    // Step - 2
    // Sort the array based on the second element (i.e. the value)
    items.sort(
        (first, second) => { return first[1] - second[1] }
    );

    // Step - 3
    // Obtain the list of keys in sorted order of the values.
    var keys = items.map(
        (e) => { return e[0] });

    return keys
}

// Return discount percentage if discount is a multiple of 10, else null.
export function getDiscountPercentage(price, originalPrice) {
    if (!originalPrice || originalPrice <= price) { return null }

    let discount = Math.round((1 - price / originalPrice) * 100);
    if (discount > 5) {
        return discount;
    } else {
        return null;
    }
}

export function vibrateForMs(time_ms) {
    try {
        if (!window && !window.navigator && !window.navigator.vibrate) { return; }
        window.navigator.vibrate(time_ms)
    } catch (e) {
        // Do nothing in case vibrate fails
    }
}

export function fnBrowserDetect() {

    let userAgent = navigator.userAgent;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        return CHROME_BROWSER_NAME;
    } else if (userAgent.match(/safari/i)) {
        return SAFARI_BROWSER_NAME;
    } else {
        return "Other Browser";
    }
}

export function isIos() {
    return navigator.userAgent.match(/iPhone/i)
}

export function isAndroid() {
    return navigator.userAgent.match(/Android/i)
}

/**
 * Calculates the distance between 'element1' and 'element2' over the x-axis.
 * It is used for calculating the max width required for the item name element.
 * 
 * While calculating, it considers the direction of the content element which contains 'element1' and 'element2'
 * and calculates the distance in the same direction as 'direction' parameter,
 * e.g., when the direction is "ltr", 'element1' is on the left side and 'element2' is on the right side,
 * when the direction is "rtl", 'element1' is on the right side and 'element2' is on the left side.
 * So, the calculation is performed always from left to right, therefore, the 'direction' is considered.
 * 
 * In addition, it is used for displaying the elipses at the end in case the text overflows.
 * @param {DOMElement} element1 - The first element from the left
 * @param {DOMElement} element2 - The second element from the left
 * @param {string} direction - The direction of the content element
 * @returns The distance between 'element1' and 'element2' over the x-axis.
 */
export function calculateHorizontalDistanceBetweenElements(element1, element2, direction) {
    let left1, left2, width1;

    if (direction === 'ltr') {
        left1 = element1.getBoundingClientRect()?.left
        width1 = element1.getBoundingClientRect()?.width
        left2 = element2.getBoundingClientRect()?.left
    } else {
        left1 = element2.getBoundingClientRect()?.left
        width1 = element2.getBoundingClientRect()?.width
        left2 = element1.getBoundingClientRect()?.left
    }
    const distance = (left1 + width1) - left2 + 30 // 30 is for reducing the 30px gap in the item description frame and adding safety margins
    return Math.abs(distance)
}

/**
 * Calculates the distance between 'element1' and 'element2' over the y-axis.
 * It is used for calculating the max width required for the item name element.
 * The distance always calculated from top to bottom.
 * In addition, it is used for displaying the elipses at the end in case the text overflows.
 * @param {DOMElement} element1 - The first element from the top
 * @param {DOMElement} element2 - The second element from the top
 * @returns The distance between 'element1' and 'element2' over the y-axis.
 */
export function calculateVerticalDistanceBetweenElements(element1, element2) {
    const { top: top1, height: height1 } = element1.getBoundingClientRect();
    const { top: top2 } = element2.getBoundingClientRect();

    const distance = (top1 + height1) - top2 + 30 // 30 is for reducing the 30px gap in the item description frame and adding safety margins
    return Math.abs(distance)
}

/**
 * Formats a string to separate it with a delimiter.
 * It splits the given string to segments of length <limit> and appends
 * the delimiter between each segment.
 * @param {string} string - The original string
 * @param {number} limit - The segment length after which the 'delimiter' is appended.
 * @param {string} delimiter - The delimiter to append between each segment of <limit> chatacters
 * @returns the formatted string.
 */
export function formatStringWithDelimiter(string, limit, delimiter = '-') {
    const delimiterCount = Math.floor(string.length / limit)
    let pointerIndex = 0
    for (let i = 0; i < delimiterCount; i++) {
        string = string.substring(0, pointerIndex + limit) + delimiter + string.substring(pointerIndex + limit, string.length)
        pointerIndex += limit + 1
    }

    return string
}

/**
 * Formats a number to be displayed with 'decimalCount' decimal places.
 * @param {number} number - The number to format
 * @param {number} decimalCount - The number of decimal places for the formatted number
 * @returns The formatted number
 */
export function formatNumber(number, decimalCount = 2) {
    if (typeof number !== 'number' || !number)
        return 0
    let n = number.toFixed(decimalCount)
    if (n % 1 === 0)
        return formatter.format(number.toFixed(0))
    return formatter.format(n)
    
}

/**
 * Determins whether the given object is empty or not
 * @param {*} object - The given object to test
 * @returns true if the object is empty, otherwise, returns false
 */
export function isObjectEmpty(object) {
    return [null, undefined].includes(object) || Object.keys(object).length === 0
}

/**
 * Calculates the complementary integer percentage from the ratio num1 / num2.
 * For example:
 * num1 = 5, num2 = 20
 *      ||
 *      \/
 * (num1 / num2) = 1/5 = 0.2
 *      ||
 *      \/
 * complementary percentage = 100% - (0.2*100)% = 100% - 20% = 80%
 * @param {number} num1 - The number to calculate the complementary integer percentage of its ratio from num2
 * @param {number} num2 - The number from which to calculate the complementary integer percentage with num1
 * @returns The complementary integer percentage of the ratio num1/num2.
 */
export function calculateComplementaryPercentage(num1, num2) {
    if ([null, undefined].includes(num1) || [null, undefined].includes(num2))
        return 0
    return Math.floor((1 - (num1 / num2)) * 100)
}

export function hasDiscount(price, originalPrice) {
    return ![undefined, null, 0].includes(originalPrice) &&
        ![undefined, null, 0].includes(price) &&
        price < originalPrice &&
        calculateComplementaryPercentage(price, originalPrice) > 0
}

/**
 * Formats an expiry string of a credit card.
 * Expects a string with a length of 4, like, "0326".
 * @param {string} expiry - The card expiry string to format
 * @returns the formatted expiry string
 */
export function formatCreditCardExpiryString(expiry) {
    if (expiry?.length !== 4)
        return ''
    return expiry.substring(0, 2) + '/' + expiry.substring(2)
}

/**
 * Tests a product's option name to check if the given option name is of color option.
 * @param {string} optionName - The given option name
 * @returns true if the option name is of type color
 */
export function isColorOption(optionName) {
    return COLOR_OPTION_NAMES.concat(SHADE_OPTION_NAMES).some(colorOptionName => optionName?.toLowerCase()?.includes(colorOptionName))
}

/**
 * Tests a product's option name to check if the given option name is of size option.
 * @param {string} optionName - The given option name
 * @returns true if the option name is of type size
 */
export function isSizeOption(optionName) {
    return SIZE_OPTION_NAMES.some(colorOptionName => optionName?.toLowerCase()?.includes(colorOptionName))
}

/**
 * Returns the correct colors json
 * @param {string} optionName - The given option name
 * @returns true if the option name is of type color
 */
export function getColorsJson(optionName) {
    if (COLOR_OPTION_NAMES.some(colorOptionName => optionName?.toLowerCase()?.includes(colorOptionName))) {
        return colors
    } else if (SHADE_OPTION_NAMES.some(colorOptionName => optionName?.toLowerCase()?.includes(colorOptionName))) {
        return shades
    }
    return {}
}

/**
 * Returns the route param according to the given 'index'.
 * It breaks the route URL into its routes inside an array and then determins the correct
 * index of the required route param.
 * @param {number} index - The given index
 */
export function getRouteParam(index) {
    const pathParams = getPathParamsList()

    const routeParam = pathParams.length > 1 ? pathParams[index] : null
    return routeParam
}

export function calculateDateDifference(startDateEpochTime, targetDateEpochTime) {
    const deltaTime = startDateEpochTime - targetDateEpochTime
    const deltaHours = Math.floor(deltaTime / (1000 * 60 * 60))
    const deltaDays = Math.floor(deltaHours / 24)
    const deltaWeeks = Math.floor(deltaDays / 7)

    return {
        deltaHours,
        deltaDays,
        deltaWeeks
    }
}

/**
 * Calculates a distance between 2 points in a 3-dimensional space.
 * @param {object} a - An object represents the first point
 * @param {object} b - An object represents the second point
 * @returns the distance between 'a' and 'b'
 */
export function calculateDistance3D(a, b) {
    return Math.sqrt((Math.pow(b.r - a.r, 2) + Math.pow(b.g - a.g, 2) + Math.pow(b.b - a.b, 2)))
}

/**
 * Converts a hexadecimal color to RGB color.
 * @param {string} hex - The hexadecimal color to convert
 * @returns the converted RGB color
 */
export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

/**
 * Generates a random number in the range [0, maxNumber] (excluding 'maxNumber')
 * @param {number} maxNumber - The upper bound of the range to pick a random number from
 */
export function generateRandomInteger(maxNumber) {
    return Math.random() * maxNumber
}

export function parseAsIntegerElseString(str) {
    if (!isNaN(str) && !str.includes('.')) {
        return parseInt(str)
    }
    return str
}

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export function hashCode(str) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}