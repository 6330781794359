import { request_auth } from 'services/RequestWrapper';
import { environment } from "conf";
import { REQUEST_PAYMENT_TIMEOUT_MILLISECONDS } from 'constants/general.constants';
import { 
    PAYMENT_REQUEST_URL 
} from 'constants/api.constant';
import { getCartID } from 'contexts/shopping';


export function RequestPaymentAPI(username, link_id, countryCode, forward_queries) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            headers: {
                username,
            },
            body: {
                cart_id: getCartID(),
                country_code: countryCode,
                forward_queries: forward_queries
            },
        }
        request_auth(environment.base_url + PAYMENT_REQUEST_URL + "/" + link_id, options, false, REQUEST_PAYMENT_TIMEOUT_MILLISECONDS)
            .then(response => {
                if (response.status) {
                    resolve(response.data)                    
                } else {
                    reject(response.error)
                }
            }).catch(error => {
                reject(error);
            });
    });
}