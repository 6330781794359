import React, { useEffect, useRef, useState } from 'react'
import './ProductItem.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { calculateComplementaryPercentage, formatNumber, hasDiscount } from 'utils/utils'
import { getUsername, useBusinessPropertiesContext, useGeneralContext, useLinkDetailsContext } from 'contexts/shopping'
import { getCurrencySymbol } from 'services/Currency'
import { useTranslation } from 'react-i18next'
import { INVENTORY_ID_KEY } from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import { ITEM_DEFAULT_IMAGE_ASPECT_RATIO, ITEM_DESIGN_PATTERNS, ITEM_DETAILS_POSITION_GAP_PX, ITEM_TYPES, PRODUCTS_VIEW_ITEM_WIDTH_PX, SECTION_TYPES } from 'constants/general.constants'
import { hasItemsInStock } from 'utils/variantUtils'

/**
 * Represents a product view item.
 * @param {object} item - An object containing the product data
 * @param {function} onItemClick - A function to perform when clicking on this item
 */
export default function ProductItem({
    item,
    onItemClick = null,
    width = `${PRODUCTS_VIEW_ITEM_WIDTH_PX}px`,
    aspectRatio = ITEM_DEFAULT_IMAGE_ASPECT_RATIO,
    designPattern = ITEM_DESIGN_PATTERNS.OUTSIDE,
    hasPadding = false,
    sectionType = SECTION_TYPES.STRIP
}) {
    const { t } = useTranslation()
    const { businessProperties } = useBusinessPropertiesContext()
    const { linkDetails } = useLinkDetailsContext()
    const { setShoppingData } = useGeneralContext()
    const history = useHistoryContext()

    const [imageFrameDimensions, setImageFrameDimensions] = useState({
        width: 0,
        height: 0
    })
    // We set all product items image to have the main link items ratio if exist, else, takes the 
    const [imageAspectRatio, setImageAspectRatio] = useState(businessProperties?.theme?.mainLinkItemsImageRatio ?? aspectRatio)

    const productImageFrameRef = useRef(null)

    useEffect(() => {
        if (productImageFrameRef?.current) {
            setImageFrameDimensions({
                width: productImageFrameRef?.current?.clientWidth,
                height: productImageFrameRef?.current?.clientHeight
            })
        }
    }, [])

    const discountStyles = {
        ...(t('direction.dir') === 'rtl' ? { right: '5px' } : { left: '5px' }),
        color: businessProperties?.theme?.discountTagFontColor ?? '#f2f2f2',
        backgroundColor: businessProperties?.theme?.discountTagBackgroungColor ?? '#313131',
    }

    function onProductClick() {
        if (onItemClick !== null) {
            onItemClick(item?.inventoryId)
            return
        }

        navigateToProductPage()
    }

    function navigateToProductPage() {
        if (item?.inventoryId) {
            history.push(`${window.basePath}/${getUsername()}/${linkDetails?.linkId}?${INVENTORY_ID_KEY}=${item?.inventoryId}`)
        }
    }

    function isImageDimensionsLargerThan(threshold) {
        return imageFrameDimensions.width > threshold || imageFrameDimensions.height > threshold
    }

    return (
        <div className={`product-item-container ${designPattern === ITEM_DESIGN_PATTERNS.INSIDE ? 'inside' : 'outside'} ${hasPadding ? 'padded' : ''}`} style={{ width: `${sectionType === SECTION_TYPES.STRIP ? width : `max(${width}, 100%)`}` }} onClick={onProductClick}>
            <div ref={productImageFrameRef} className="product-item-image-frame" style={{ aspectRatio: `${imageAspectRatio}` }}>
                {!hasItemsInStock(item?.variants) && <div className="product-item-out-of-stock-strip">{t('OUT_OF_STOCK_TEXT')}</div>}
                <ImageBox
                    image={(isImageDimensionsLargerThan(150) && item?.images && item?.images?.length > 0) ? item?.images[0]?.medium?.src : item?.images[0]?.small?.src}
                    className='product-item-image-container'
                    isImageFitCover={true}
                    showImageBorderRadius={true}
                    border='1px solid #ececec'
                    showImageMask={designPattern === ITEM_DESIGN_PATTERNS.INSIDE}
                    borderRadius={`${businessProperties?.theme?.imageBorderRadius}px`}
                    aspectRatio={imageAspectRatio}
                />
                {
                    designPattern === ITEM_DESIGN_PATTERNS.INSIDE ? <div className="product-item-details-inside" style={{ width: `calc(85% - ${ITEM_DETAILS_POSITION_GAP_PX}px)`, ...(t('direction.dir') === 'rtl' ? { right: `${ITEM_DETAILS_POSITION_GAP_PX}px` } : { left: `${ITEM_DETAILS_POSITION_GAP_PX}px` }) }}>
                        <div className="item-title-inside-design allow-2-lines">{item?.title}</div>
                        <div className="product-item-price-container-inside">
                            {hasDiscount(item?.price, item?.originalPrice) && <div className="product-item-original-price-inside line-through-text">{getCurrencySymbol(item?.currency)}{formatNumber(item?.originalPrice)}</div>}
                            <div className="product-item-price-inside">{getCurrencySymbol(item?.currency)}{formatNumber(item?.price)}</div>
                        </div>
                        <div className="item-button-inside-design">{t('PRODUCT_ITEM_SHOP_NOW_BUTTON_TEXT_INSIDE')}</div>
                    </div> : (
                        (businessProperties?.theme?.isDiscountStripShown && hasDiscount(item?.price, item?.originalPrice)) && <div dir={"ltr"} className="product-item-discount-percentage" style={discountStyles}>{t('CART_CONTINUE_SHOPPING_SECTION_DISCOUNT_PERCENTAGE', { discountPercentage: calculateComplementaryPercentage(item?.price, item?.originalPrice) })}</div>
                    )
                }
            </div>
            {
                designPattern === ITEM_DESIGN_PATTERNS.OUTSIDE && <div className='product-item-details-outside'>
                    <div className="product-item-title allow-2-lines">{item?.title}</div>
                    <div className="product-item-price-container">
                        <div className="product-item-price">{getCurrencySymbol(item?.currency)}{formatNumber(item?.price)}</div>
                        {hasDiscount(item?.price, item?.originalPrice) && <div className="product-item-original-price line-through-text">{getCurrencySymbol(item?.currency)}{formatNumber(item?.originalPrice)}</div>}
                    </div>
                </div>
            }
        </div>
    )
}