import React, { useEffect, useRef, useState } from 'react'
import './LoaderButton.css'
import Loader from 'components/Loader/Loader'
import { getElementHeight } from 'services/SystemService'

/**
 * Represents a button with a loader.
 * Its loader is shown after clicking on it.
 * @param {string} className - A class name for this button's frame
 * @param {string} buttonText - The text of this button
 * @param {boolean} isLoading - Determins whether the loader should be shown or not
 * @param {boolean} renderAsButton - If true, renders the button container as a 'button' element, otherwise, renders it as a 'div' element
 * @param {boolean} isDisabled - Determins whether the button is disabled or not
 * @param {string} loaderCircleColor - The color of the button's loader circle
 * @param {string} loaderArchColor - The color of the button's loader arch
 * @param {function} onClick - A function to invoke when clicking the button
 */
export default function LoaderButton({
    className = '',
    buttonText = '',
    isLoading = false,
    renderAsButton = false,
    isDisabled = false,
    loaderCircleColor = 'white',
    loaderArchColor = 'transparent',
    onClick = () => { },
    styles = {}
}) {
    const [loaderDimensPx, setLoaderDimensPx] = useState(0)
    const [buttonHeight, setButtonHeight] = useState(0)
    const buttonRef = useRef(null)

    useEffect(() => {
        setButtonHeight(buttonRef.current.clientHeight)
        setLoaderDimensPx(getElementHeight(buttonRef.current))
    }, [])

    function onSubmitButtonClick(e) {
        e.preventDefault()
        onClick()
    }

    function renderButtonContent() {
        return <>
            {
                isLoading && <div style={{ height: buttonHeight }} className='loader-button-loader-container'>
                    <Loader styles={{ width: `${loaderDimensPx}px`, height: `${loaderDimensPx}px`, margin: 'auto', borderColor: loaderCircleColor, borderTopColor: loaderArchColor, animationDuration: '500ms' }} />
                </div>
            }
            <div style={{ visibility: isLoading ? 'hidden' : 'visible' }} className="loader-button-text">{buttonText}</div>
        </>
    }

    return (
        <>
            {
                renderAsButton ?
                    <button type='submit' disabled={isDisabled} ref={buttonRef} style={styles} className={`loader-button-container ${className} ${isDisabled ? 'disabled' : ''} ${isLoading ? 'not-clickable' : ''}`} onClick={onSubmitButtonClick}>
                        {renderButtonContent()}
                    </button> : <div ref={buttonRef} style={styles} className={`loader-button-container ${className} ${isDisabled ? 'disabled' : ''} ${isLoading ? 'not-clickable' : ''}`} onClick={onClick}>
                        {renderButtonContent()}
                    </div>
            }
        </>
    )
}