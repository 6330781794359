import React from 'react'

export default function ShoppingBagImage1(props) {
    return (
        <svg {...props} fill="none" height="24" viewBox="0 0 24 24" width="24">
            <g fill="rgb(0,0,0)">
                <path d="m5 7.75c-.13807 0-.25.11193-.25.25v13c0 .1381.11193.25.25.25h14c.1381 0 .25-.1119.25-.25v-13c0-.13807-.1119-.25-.25-.25zm-1.75.25c0-.9665.7835-1.75 1.75-1.75h14c.9665 0 1.75.7835 1.75 1.75v13c0 .9665-.7835 1.75-1.75 1.75h-14c-.9665 0-1.75-.7835-1.75-1.75z" />
                <path d="m12 2.75c-1.5188 0-2.75 1.23122-2.75 2.75v3.5c0 .41421-.33579.75-.75.75s-.75-.33579-.75-.75v-3.5c0-2.34721 1.90279-4.25 4.25-4.25 2.3472 0 4.25 1.90279 4.25 4.25v3.5c0 .41421-.3358.75-.75.75s-.75-.33579-.75-.75v-3.5c0-1.51878-1.2312-2.75-2.75-2.75z" />
            </g>
        </svg>
    )
}
