import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * A custom hook that simplifies usage of query parameters.
 * @param {function} onQueryParamsChange - A function to perform whenever the query parameters are changed
 * @returns the current page's query parameters
 */
export default function useQueryParams(onQueryParamsChange = () => { }) {
    const location = useLocation()
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location])

    useEffect(() => {
        onQueryParamsChange()
    }, [location.search])

    return queryParams
}
