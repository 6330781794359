import React, { useEffect, useRef, useState } from 'react'
import './CollectionItem.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { useBusinessPropertiesContext } from 'contexts/shopping'
import {
    ITEM_DETAILS_POSITION_GAP_PX,
    COLLECTION_ITEM_IMAGE_ASPECT_RATIO,
    COLLECTION_ITEM_WIDTH_PX,
    ITEM_DESIGN_PATTERNS,
    SECTION_TYPES,
    ITEM_DEFAULT_IMAGE_ASPECT_RATIO
} from 'constants/general.constants'
import { useTranslation } from 'react-i18next'

/**
 * Represents a collection item.
 * @param {object} item - An object containing the collection data
 * @param {function} onItemClick - A function to perform when clicking on this item
 */
export default function CollectionItem({
    item,
    onItemClick = () => { },
    width = `${COLLECTION_ITEM_WIDTH_PX}px`,
    aspectRatio = COLLECTION_ITEM_IMAGE_ASPECT_RATIO,
    designPattern = ITEM_DESIGN_PATTERNS.OUTSIDE,
    hasPadding = false,
    sectionType = SECTION_TYPES.STRIP,
    titleFontSize = '15px',
    isItemSelected = collectionId => { }
}) {
    const { t } = useTranslation()
    const { businessProperties } = useBusinessPropertiesContext()

    // We set all collection items image to have the main link items ratio if exist, else, takes the 
    const [imageAspectRatio, setImageAspectRatio] = useState(businessProperties?.theme?.mainLinkItemsImageRatio ?? aspectRatio)

    const titleRef = useRef(null)

    useEffect(() => {
        if (titleRef?.current) {
            titleRef.current.innerHTML = item?.title
        }
    }, [item])

    return (
        <div className={`collection-item-container ${isItemSelected(item?.collectionId) ? 'selected' : ''} ${designPattern === ITEM_DESIGN_PATTERNS.INSIDE ? 'inside' : 'outside'} ${hasPadding ? 'padded' : ''}`} onClick={() => onItemClick(item?.collectionId)} style={{ width: `${sectionType === SECTION_TYPES.STRIP ? width : `max(${width}, 100%)`}` , aspectRatio: `${aspectRatio}`, borderRadius: `${businessProperties?.theme?.imageBorderRadius}px` }}>
            <div className="collection-item-image-frame" style={{ aspectRatio: `${imageAspectRatio}`}}>
                <ImageBox
                    image={ITEM_DESIGN_PATTERNS.OUTSIDE ? item?.imageUrl?.medium?.src : item?.imageUrl?.original?.src}
                    className='collection-item-image-container'
                    isImageFitCover={true}
                    showImageBorderRadius={true}
                    border='1px solid #ececec'
                    showImageMask={true}
                    borderRadius={`${businessProperties?.theme?.imageBorderRadius}px`}
                    aspectRatio={imageAspectRatio}
                />
                <div className="collection-item-details" style={{ width: `calc(85% - ${ITEM_DETAILS_POSITION_GAP_PX}px)`, ...(t('direction.dir') === 'rtl' ? { right: `${ITEM_DETAILS_POSITION_GAP_PX}px` } : { left: `${ITEM_DETAILS_POSITION_GAP_PX}px` })}}>
                    {item?.title && <div ref={titleRef} className={`${designPattern === ITEM_DESIGN_PATTERNS.INSIDE ? 'item-title-inside-design' : 'collecton-item-details-title'} allow-2-lines`} style={{fontSize: titleFontSize}}></div>}
                    {designPattern === ITEM_DESIGN_PATTERNS.INSIDE && <div className='item-button-inside-design'>{t('COLLECTION_ITEM_VIEW_COLLECTION_BUTTON_TEXT_INSIDE')}</div>}
                </div>
            </div>
        </div>
    )
}